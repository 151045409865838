import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import { isMobile, isTablet } from 'react-device-detect';
import { Box, Button, Dialog, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { axiosInstance } from 'src/axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { CustomDialogContent, CustomDialogFooter, CustomDialogHeader } from 'src/components/CustomDialog';

export default function ManageUpdatePassword(props: any) {
  const { onClose } = props;
  const toastConfig = useContext(CustomToastContext);
  const [loading, setLoading] = useState(false);
  const [visibity, setVisibity] = useState<any>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [fullScreen, setFullScreen] = useState(isMobile || isTablet);

  const toggleVisibility = (key: string | number) => {
    const value = !visibity[key];
    setVisibity({ ...visibity, [key]: value });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const handleSubmit = (values: any) => {
    setLoading(true);
    axiosInstance()
      .put(`/auth/me/password`, { oldPassword: values.oldPassword, newPassword: values.newPassword })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          type: 'success',
          message: data.message
        });
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setLoading(false);
      });
  };

  const PasswordEndAdornment = ({ fieldName }: any) => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => toggleVisibility(fieldName)}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {/* <VisibilityOff /> */}
        {visibity[fieldName] ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const validateForm = (values: any) => {
    const errors: any = {};

    if (!values.oldPassword) {
      errors.oldPassword = 'Required field';
    }
    if (!values.newPassword) {
      errors.newPassword = 'Required field';
    } else if (!/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(values.newPassword)) {
      errors.newPassword = 'Minimum eight characters, at least one uppercase, one lowercase, one number and one special character';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required field';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'New Password and Confirm Password should be same';
    }

    return errors;
  };
  return (
    <Dialog maxWidth="sm" fullScreen={fullScreen || isMobile || isTablet} aria-labelledby="customized-dialog-title" onClose={onClose} open>
      <CustomDialogHeader
        title={'Update Password'}
        onClose={onClose}
        isMinimized={!fullScreen}
        onMinimizeMaximize={() => {
          setFullScreen((prevState) => !prevState);
        }}
        showManimizeMaximize={true}
      />
      <Formik initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }} validate={validateForm} onSubmit={handleSubmit}>
        {({ values, setFieldValue, setFieldError, setFieldTouched, submitForm, errors, setErrors }) => (
          <>
            <CustomDialogContent>
              <Form noValidate autoComplete="off" autoCorrect="off">
                <div>
                  <Box marginY={2}>
                    <Grid spacing={3} container>
                      <>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            id="oldPassword"
                            name="oldPassword"
                            label="Old Password"
                            type={visibity.oldPassword ? 'text' : 'password'}
                            value={values['oldPassword']}
                            required
                            onChange={(e) => {
                              setFieldValue('oldPassword', e.target.value);
                            }}
                            InputProps={{
                              endAdornment: <PasswordEndAdornment fieldName="oldPassword" />
                            }}
                            error={errors['oldPassword'] ? true : false}
                            helperText={errors['oldPassword'] && errors['oldPassword'].toString()}
                          />
                          {/* <Field
                          component={TextFieldFormik}
                          fullWidth
                          margin="dense"
                          type={visibity['oldPassword'] ? 'string' : 'password'}
                          label="Old Password"
                          name="oldPassword"
                          variant="outlined"
                          required={true}
                          value={values['oldPassword']}
                          onChange={(e) => setFieldValue('oldPassword', e.target.value)}
                          InputProps={{
                            endAdornment: <PasswordEndAdornment fieldName="oldPassword" />
                          }}
                        /> */}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            id="newPassword"
                            label="New Password"
                            name="newPassword"
                            type={visibity.newPassword ? 'text' : 'password'}
                            value={values['newPassword']}
                            required
                            onChange={(e) => {
                              setFieldValue('newPassword', e.target.value);
                            }}
                            InputProps={{
                              endAdornment: <PasswordEndAdornment fieldName="newPassword" />
                            }}
                            error={errors['newPassword'] ? true : false}
                            helperText={errors['newPassword'] && errors['newPassword'].toString()}
                          />
                          {/* <Grid item sm={10}>
                            <Field
                              component={TextFieldFormik}
                              fullWidth
                              margin="dense"
                              type={visibity['newPassword'] ? 'string' : 'password'}
                              label="New Password"
                              name="newPassword"
                              variant="outlined"
                              required={true}
                              value={values['newPassword']}
                              onChange={(e) => setFieldValue('newPassword', e.target.value)}
                              InputProps={{
                                endAdornment: <PasswordEndAdornment fieldName="newPassword" />
                              }}
                            />*/}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            id="confirmPassword"
                            label="Confirm Password"
                            name="confirmPassword"
                            type={visibity['confirmPassword'] ? 'string' : 'password'}
                            value={values['confirmPassword']}
                            required
                            onChange={(e) => {
                              setFieldValue('confirmPassword', e.target.value);
                            }}
                            InputProps={{
                              endAdornment: <PasswordEndAdornment fieldName="confirmPassword" />
                            }}
                            error={errors['confirmPassword'] ? true : false}
                            helperText={errors['confirmPassword'] && errors['confirmPassword'].toString()}
                          />
                        </Grid>
                        {/* <Grid item sm={10}>
                            <Field
                              component={TextFieldFormik}
                              fullWidth
                              margin="dense"
                              type={visibity['confirmPassword'] ? 'string' : 'password'}
                              label="Confirm Password"
                              name="confirmPassword"
                              variant="outlined"
                              required={true}
                              value={values['confirmPassword']}
                              onChange={(e) => {
                                setFieldValue('confirmPassword', e.target.value);
                              }}
                              InputProps={{
                                endAdornment: <PasswordEndAdornment fieldName="confirmPassword" />
                              }}
                            />
                          </Grid> */}
                      </>
                    </Grid>
                  </Box>
                </div>
              </Form>
            </CustomDialogContent>
            <CustomDialogFooter>
              <Grid item xs={12} sm={12} display="flex" justifyContent={'end'}>
                <Button type="button" variant="outlined" color="primary" size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Box marginLeft={2} />
                <Button type="button" variant="contained" color="primary" size="small" onClick={submitForm}>
                  Save
                </Button>
              </Grid>
            </CustomDialogFooter>
            {/* </CustomDialogContent> */}
            {/* <CustomDialogFooter> */}
            {/* <Button type="button" variant="outlined" color="primary" size="small" onClick={onClose}>
              Cancel
            </Button> */}

            {/* <CustomButton
                loading={loading}
                variant="contained"
                color="primary"
                disabled={loading ? true : (isUpdateEmail && values.email === userData.email) || false}
                onClick={() => {
                  if (isUpdatePassword) {
                    let errors = validateForm(values);
                    if (Object.keys(errors).length === 0) {
                      handleSubmit(values);
                    }
                  } else {
                    handleSubmit(values);
                  }
                }}
              >
                Update
              </CustomButton>
            </CustomDialogFooter> */}
          </>
        )}
      </Formik>
    </Dialog>
  );
}
