import React, { ReactNode } from 'react';
import { withStyles } from '@mui/styles';
import MuiDialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';

interface Props {
  children?: ReactNode;
}

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1.5, 2)
  }
}))(MuiDialogActions);

function CustomDialogFooter({ children }: Props) {
  return (
    <React.Fragment>
      <DialogActions>{children}</DialogActions>
    </React.Fragment>
  );
}

CustomDialogFooter.propTypes = {
  children: PropTypes.any
};

export default CustomDialogFooter;
