import React from 'react';
import styles from './index.module.scss';
import { threeCol, classesData } from './data';
import useMediaQuery from '@mui/material/useMediaQuery';
import { YellowButton } from 'src/components/Buttons';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/helpers/routes';
// import { CoursesDetails } from 'src/components/CoursesDetails';

const Home = () => {
  const mobileDisplay = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  return (
    <div>
      <div
        className={styles.bgImage}
        style={{
          backgroundSize: 'contain',
          backgroundImage: `url(
            ${process.env.PUBLIC_URL + './assets/homeHeroImage.png'} 
          )`
        }}
      ></div>
      {/* ------------------------------------Courses Section------------------------------------ */}
      <div className={'container2'}>
        {/* <CoursesDetails mobileDisplay={mobileDisplay} /> */}
        <div className={styles.classes}>
          <div className={styles.sectionHeading}>
            <h2>{classesData.heading}</h2>
            <div className={styles.underLine}></div>
          </div>
          <div className={styles.imageContainer}>
            {classesData.images.map((item) => (
              <div className={styles.singleImage} key={item.id}>
                <img src={item.img} alt="" loading="lazy" />
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <YellowButton endIcon={<FiArrowRight />} onClick={() => navigate(routes.courses.path)}>
              Book your classes
            </YellowButton>
          </div>
        </div>
        {/*  ------------------------------------ Bottom Section ------------------------------------ */}
        <div className={styles.threeColumn}>
          <div className={styles.columns}>
            {threeCol.map((item: any, key: any) => (
              <div key={key} className={styles.singleCol}>
                <div className={styles.imageContainer}>
                  <img src={item.img} alt="" loading="lazy" />
                </div>
                <div className={styles.text}>
                  <h6 className="poppins">{item.heading}</h6>
                  <p className="poppins">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
