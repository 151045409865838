import React from 'react';
import {
  heroData,
  homecardData,
  homecolumnsData,
  howWeWorkData,
  testimonialData,
  donationData,
  stayTunedData
} from 'src/components/Site/Home/sitedata';
import ThreeColumnCard from 'src/components/Site/ThreeColumnCard';
import TwoColumnSections from 'src/components/Site/TwoColumnSections';
import ThreeColWithHeader from 'src/components/Site/ThreeColWithHeader';
import Testimonial from 'src/components/Site/Testimonial';
import Donation from 'src/components/Site/Donation';
import StayTuned from 'src/components/Site/StayTuned';
import { AnimationContainer } from 'src/components/Animation';

import SiteHero from 'src/components/Site/SiteHero';

const Home = () => {
  return (
    <AnimationContainer direction="fade">
      {/* <Banner bannerInfo={homeBannerData} /> */}
      <SiteHero sectionData={heroData} />
      <ThreeColumnCard cardData={homecardData} />
      <TwoColumnSections columnsData={homecolumnsData} />
      <ThreeColWithHeader sectionData={howWeWorkData} />
      <Testimonial sectionData={testimonialData} />
      <Donation sectionData={donationData} />
      <StayTuned sectionData={stayTunedData} />
    </AnimationContainer>
  );
};

export default Home;
