import React from 'react';
import { useIntersectionObserver } from 'src/components/hooks';
import styles from './index.module.scss';

interface animationContainer extends React.HTMLAttributes<HTMLDivElement> {
  observerOptions?: {
    root: HTMLElement;
    rootMargin: string;
    threshold: number;
    freezeOnceVisible: boolean;
  };
  direction?: 'right-to-left' | 'left-to-right' | 'top-to-bottom' | 'bottom-to-top' | 'fade';
  duration?: string;
  fade?: boolean;
  loaded?: boolean;
  timing?: 'ease-in-out' | 'ease' | 'linear' | 'ease-in';
  opacity?: boolean;
  className?: string;
  style?: any;
}

export const AnimationContainer = (props: animationContainer) => {
  const animationRef = React.useRef<HTMLDivElement>(null);
  const {
    observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
      freezeOnceVisible: true
    },
    direction = 'right-to-left',
    duration = '900ms',
    timing = 'ease-in-out',
    opacity = true,
    className = '',
    style = {}
  } = props;
  const entries = useIntersectionObserver(animationRef, observerOptions);
  const isInterSecting = entries?.isIntersecting;
  const animationClass =
    (direction === 'right-to-left' && styles.rightToLeft) ||
    (direction === 'left-to-right' && styles.leftToRight) ||
    (direction === 'top-to-bottom' && styles.topToBottom) ||
    (direction === 'bottom-to-top' && styles.bottomToTop) ||
    (direction === 'fade' && styles.fade);

  const customProp = { '--duration': duration, '--timing': timing, '--opacity': opacity ? 0 : 1, opacity: opacity ? 0 : 1 } as React.CSSProperties;
  return (
    <div {...props} ref={animationRef} style={{ ...customProp, ...style }} className={`${isInterSecting && animationClass} ${className}`}>
      {props.children}
    </div>
  );
};
