import React from 'react';
import WhatWeOffer from 'src/components/Site/WhatWeOffer';
import { siteNames, siteName } from 'src/config';
import PageNotFound from 'src/pages/PageNotFound';

const AboutUs = () => {
  if (siteName?.includes(siteNames.site1)) {
    return <PageNotFound />;
  } else {
    return <WhatWeOffer />;
  }
};

export default AboutUs;
