import { CoursesDetails } from 'src/components/CoursesDetails';
import { AnimationContainer } from 'src/components/Animation';

const WhatWeOffer = () => {
  return (
    <AnimationContainer direction="fade">
      {/* <Banner bannerInfo={WhatWeOfferBannerData} /> */}
      <CoursesDetails />
    </AnimationContainer>
  );
};

export default WhatWeOffer;
