import React from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import styles from './index.module.scss';
import image404 from 'src/assets/svg/page404.svg';
import gurprasad404 from 'src/assets/svg/gurprasad404.svg';
import { buttonStyle, buttonType, WhiteButton } from 'src/components/Buttons';
import { siteName, siteNames } from 'src/config';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'src/helpers/routes';

const HomeButton = () => {
  if (siteName?.includes(siteNames.site1)) {
    return buttonStyle(buttonType.yellowButton);
  } else if (siteName?.includes(siteNames.site2)) {
    return buttonStyle(buttonType.primary);
  } else {
    return buttonStyle(buttonType.primary);
  }
};

const PageNotFound = () => {
  const Button = HomeButton();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container2">
      <div className={styles.sectionPadding}>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <h1>Page Not Found</h1>
            <p>Sorry, the page you are looking for doesn't exist.Here are some helpful links:</p>
            <div className={styles.buttonContainer}>
              <WhiteButton
                onClick={() => handleBack()}
                style={{ border: '1px solid #D0D5DD', color: '#344054' }}
                startIcon={<KeyboardBackspaceIcon />}
              >
                Go back
              </WhiteButton>
              <Button onClick={() => navigate(routes.home.path)}>Take me home</Button>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={siteName?.includes(siteNames.site1) ? image404 : gurprasad404} alt="" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
