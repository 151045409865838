import React from 'react';
import comingSoonImage from 'src/components/Site/assets/images/coming_soon.png';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { usePathname } from 'src/components/hooks';

const ComingSoon = () => {
  const path = usePathname();

  return (
    <div className="container">
      <div className={styles.breadCrumb}>
        <Link to="/">Home</Link>
        <p> / {path.split('/').slice(-1).join('').split('-').join(' ')}</p>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <h1 className="fs-big fw-5 mb-1">Coming Soon.</h1>
          <p className="fs-6 fw-5">In the meantime, please enjoy the rest of our website :)</p>
        </div>
        <div className={styles.right}>
          <div className={styles.imageContainer}>
            <img src={comingSoonImage} alt="coming Soon image" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
