import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import DiscountIcon from '@mui/icons-material/Discount';
import { currencyConverter } from 'src/helpers/currency';

const Offers = ({ offersData, currency }: { offersData: any; currency: string }) => {
  return (
    <Box pt={2}>
      <Grid container spacing={{ xs: 1, sm: 2 }} sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
        {offersData?.map((item: any, key: any) => {
          return (
            <Grid item key={key}>
              <Box
                border={1}
                display="flex"
                alignItems="center"
                borderColor="grey.300"
                key={key}
                py={{ xs: 1, sm: 2 }}
                px={{ xs: 1, sm: 2, md: 3 }}
                borderRadius="6px"
              >
                <DiscountIcon color="primary" />
                <Box component={'span'} ml={1}>
                  {item?.discountType === 'Percentage'
                    ? `Buy ${item?.qty} get ${item?.discount}% Discount`
                    : `${currencyConverter(item?.discount, currency)} off, on each class when you sign up for ${item?.qty} or more.`}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Offers;
