import { useContext, useLayoutEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { siteName, siteNames } from 'src/config';
import { theme } from './constants/AppConfig';
import { Box } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { CustomToastContext } from './context/CustomToastContext';
import { routes } from './helpers/routes';
import Typography from './pages/Typography';
import CustomToaster from './components/Helpers/CustomToast';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import ForgetPassword from './pages/Auth/ForgetPassword';
import Homepage from './pages/Homepage';
import GvResources from './pages/GvResources';
import Volunteer from './pages/Volunteer';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import Donation from './pages/Donation';
import DonationSuccess from './pages/Donation/DonationSuccess';
import PageNotFound from './pages/PageNotFound';
import Courses from './pages/Courses';
import Session from './pages/Session';
import OrderDetails from './pages/Orders/OrderDetails';
import Orders from './pages/Orders';
import Students from './pages/Students';
import PaymentStatus from './pages/PaymentStatus';
import Profile from './pages/Profile';
import ResetPassword from './pages/Auth/ResetPassword';
import PayOnline from './pages/PayOnline';
import WhatWeOffer from './pages/WhatWeOffer';
import ClassDetails from './pages/ClassDetails';
import Programs from './pages/Programs';

import UserwiseDonation from './pages/Donation/UserwiseDonation';
import Teachers from './pages/Teachers';
import MyGv from './components/Site/MyGv';

const isSite2 = siteName?.includes(siteNames.site2);

const fontClass = () => {
  if (siteName?.includes(siteNames.site1)) {
    return 'h-poppins body-inter';
  } else if (siteName?.includes(siteNames.site2)) {
    return 'h-gotham body-gotham gv-site';
  } else {
    return 'h-poppins body-inter';
  }
};

function App() {
  const toast = useContext(CustomToastContext);

  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={`${fontClass()}`} style={{ backgroundColor: isSite2 && 'rgb(240,240,240)' }}>
        <Routes>
          {isSite2 && (
            <>
              <Route path={routes.login.path} element={<Login className="gv-authentication" />} />
              <Route path={routes.register.path} element={<Register className="gv-authentication" />} />
              <Route path={routes.forgetPassword.path} element={<ForgetPassword className="gv-authentication" />} />
            </>
          )}
          <Route element={<PublicRoute />}>
            {siteName?.includes(siteNames.site1) && (
              <>
                <Route path={routes.login.path} element={<Login />} />
                <Route path={routes.register.path} element={<Register />} />
                <Route path={routes.forgetPassword.path} element={<ForgetPassword />} />
              </>
            )}
            <Route path={routes.resetPassword.path} element={<ResetPassword />} />
            <Route path={routes.home.path} element={<Homepage />} />
            <Route path={`${routes.gvResources.path}/:page`} element={<GvResources />} />
            <Route path={routes.volunteer.path} element={<Volunteer />} />
            <Route path={routes.contactUs.path} element={<ContactUs />} />
            <Route path={routes.aboutUs.path} element={<AboutUs />} />
            <Route path={routes.donation.path} element={<Donation />} />
            <Route path={`${routes.classes.path}`} element={<WhatWeOffer />} />
            <Route path={`${routes.classDetails.path}/:id`} element={<ClassDetails />} />
            <Route path={`${routes.teachers.path}`} element={<Teachers />} />
            <Route path={`${routes.donation.path}/details/:id`} element={<DonationSuccess />} />
            <Route path={`${routes.donation.path}/orders`} element={<UserwiseDonation />} />
            <Route path={'/typography'} element={<Typography />} />
            <Route path={`${routes.programs.path}/:page`} element={<Programs />} />
            <Route path={'*'} element={<PageNotFound />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={routes.courses.path} element={<Courses />} />
            <Route path={`${routes.session.path}/:id`} element={<Session />} />
            <Route path={routes.orders.path} element={<Orders />} />
            <Route path={`${routes.orders.path}/:id`} element={<OrderDetails />} />
            <Route path={routes.students.path} element={<Students />} />
            <Route path={routes.profile.path} element={<Profile />} />
            <Route path={routes.payOnline.path} element={<PayOnline />} />
            <Route path={routes.myGv.path} element={<MyGv />} />
            <Route path={'*'} element={<PageNotFound />} />
          </Route>
          <Route path={'payment-status'} element={<PaymentStatus />} />
        </Routes>
      </Box>
      {toast?.toastConfig?.open && (
        <CustomToaster
          type={toast.toastConfig.type}
          message={toast.toastConfig.message}
          anchorOrigin={toast.toastConfig?.anchorOrigin || null}
          open={toast.toastConfig.open}
          close={() => {
            toast.setToastConfig({ open: false, message: null, type: null });
          }}
        />
      )}
    </ThemeProvider>
  );
}

export default App;
