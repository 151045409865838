import React from 'react';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useCourses, CourseDetailsInterface } from 'src/components/hooks';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { Button, Skeleton, Grid, Typography, Box, Radio, RadioGroup, FormControlLabel, FormControl, Zoom, Tooltip } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectCreative, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import moment from 'moment-timezone';
import { buttonType, buttonStyle } from 'src/components/Buttons';
import { Link } from 'react-router-dom';
import { AnimationContainer } from 'src/components/Animation';
import noImage from 'src/assets/images/no-image.png';

import sliderDecor1 from 'src/components/Site/assets/images/slider_decor1.png';
import sliderDecor2 from 'src/components/Site/assets/images/slider_decor2.png';
import { routes } from 'src/helpers/routes';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const ClassDetail = () => {
  const BookButton = buttonStyle(buttonType.primary);
  const { id } = useParams();
  const navigate = useNavigate();
  const courseDetail = useCourses(id);
  const course = courseDetail ? courseDetail[0] : null;
  const [radioValue, setRadioValue] = React.useState(null);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  return (
    <AnimationContainer direction="fade" className={styles.main}>
      <div className="container">
        <Button variant="text" size="small" className={styles.goBackButton} onClick={() => navigate(routes.classes.path)}>
          <span className={styles.buttonIconSpan}>
            <BsArrowLeftCircle />
          </span>
          Return
        </Button>
        <Box className={styles.classCard}>
          <Grid container spacing={{ xs: 2, md: 4 }} className={styles.contentContainer} alignItems="center">
            <Grid item xs={12} sm={12} md={7} lg={6} className={`${styles.left} ${styles.col}`}>
              <div>
                <h3 className={styles.sectionHeading}>{course ? course.heading : <Skeleton variant="text" width={210} />}</h3>
                {course ? (
                  <>
                    <p className={styles.sectionPara}>{course?.text}</p>
                  </>
                ) : (
                  <>
                    <Skeleton variant="text" width={'100%'} />
                    <Skeleton variant="text" width={'85%'} />
                    <Skeleton variant="text" width={'98%'} />
                    <Skeleton variant="text" width={'90%'} />
                  </>
                )}
                {course
                  ? course?.schedule?.length > 0 && (
                      <>
                        <Typography variant="h6" className={styles.courseScheduleHead}>
                          <b>Course Schedules:</b>
                        </Typography>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={radioValue}
                          name="radio-buttons-group"
                          onChange={handleRadioChange}
                        >
                          {course.schedule.map((item, index) => {
                            return (
                              <>
                                <label className={`${styles.card} ${!item.status ? styles.disabled : ''}`} htmlFor={`radio-${index}`}>
                                  <input
                                    name="class-schedule"
                                    id={`radio-${index}`}
                                    className={styles.radio}
                                    type="radio"
                                    onClick={() => {
                                      setRadioValue(index);
                                    }}
                                    disabled={!item.status}
                                    checked={radioValue == index}
                                  />
                                  <span className={styles.radioDetails}>
                                    <Typography variant="h6" className={styles.information}>
                                      {item?.label}
                                      {!item.status && (
                                        <Tooltip title={item.inactiveReason} TransitionComponent={Zoom} placement="right">
                                          <InfoOutlinedIcon
                                            sx={{ fontSize: '19px', cursor: 'pointer', color: '#6e6e6e', marginLeft: '5px', verticalAlign: 'bottom' }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Typography>
                                    <Typography variant="h6" className={styles.teacherName}>
                                      {item.instructor.optionLabel}
                                    </Typography>
                                    <Typography variant="body2" className={styles.times}>
                                      {days
                                        .filter((day) => !item.blockDayNames.includes(day))
                                        .map((day: string) => `${day}s`)
                                        .join(', ')}
                                      , {moment(item.startTime).format('LT')} - {moment(item.endTime).format('LT')}
                                    </Typography>
                                  </span>
                                </label>
                              </>
                            );
                          })}
                        </RadioGroup>
                      </>
                    )
                  : null}
                {course ? (
                  <BookButton onClick={() => navigate(course.buttonLink)}>REGISTER</BookButton>
                ) : (
                  <Skeleton variant="rectangular" width={'100%'} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={6} className={`${styles.right} ${styles.col}`}>
              <div>
                <div className={styles.sliderContainer}>
                  <img className={styles.decor1} src={sliderDecor1} alt="" aria-hidden />
                  <img className={styles.decor2} src={sliderDecor2} alt="" aria-hidden />
                  {course ? (
                    <>
                      <Swiper
                        // spaceBetween={50}
                        // slidesPerView={1}
                        navigation={true}
                        modules={[Pagination, Autoplay, EffectCreative, Navigation]}
                        pagination={{ clickable: true }}
                        grabCursor={true}
                        loop={true}
                        speed={800}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false
                        }}
                        className={styles.swiper}
                        effect={'creative'}
                        creativeEffect={{
                          prev: {
                            shadow: false,
                            translate: [0, 0, -500]
                          },
                          next: {
                            translate: ['100%', 0, 0]
                          }
                        }}
                      >
                        {course.images.length > 0 ? (
                          course.images.map((item, key) => (
                            <>
                              <SwiperSlide key={key}>
                                <div key={key} className={styles.singleSlide}>
                                  <img src={item} alt="" loading="lazy" />
                                </div>
                              </SwiperSlide>
                            </>
                          ))
                        ) : (
                          <>
                            <SwiperSlide>
                              <div className={styles.singleSlide}>
                                <img src={noImage} alt="" aria-hidden />
                              </div>
                            </SwiperSlide>
                          </>
                        )}
                      </Swiper>
                    </>
                  ) : (
                    <Skeleton variant="rectangular" width={'100%'} height={'100%'} className={styles.imageSkeleton} />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </AnimationContainer>
  );
};

export default ClassDetail;
