import { nanoid } from 'nanoid';

import three1 from 'src/components/Site1/assets/threeCol1.png';
import three2 from 'src/components/Site1/assets/threeCol2.png';
import three3 from 'src/components/Site1/assets/threeCol3.png';

import alterNateSectionImage1 from 'src/components/Site1/assets/alternateSection1.png';
import alterNateSectionImage2 from 'src/components/Site1/assets/alternateSection2.png';
import alterNateSectionImage3 from 'src/components/Site1/assets/alternateSection3.png';

export const threeCol = [
  {
    img: three1,
    heading: 'Experienced Teachers!',
    text: `Our teachers have decades of
practical experience, including
Masters Degrees in Gurmat Sangeet`
  },
  {
    img: three2,
    heading: 'Affordable Rates!',
    text: `Private Lessons offered
Group Lessons available as well`
  },
  {
    img: three3,
    heading: 'Flexible Timing!',
    text: `Classes can be scheduled
daily between 10 am - 8 pm,
depending on availability`
  }
];

export const classesData = {
  heading: 'Want to learn Kirtan, Tabla, or String Instruments?',
  images: [
    { id: nanoid(), img: alterNateSectionImage1 },
    { id: nanoid(), img: alterNateSectionImage2 },
    { id: nanoid(), img: alterNateSectionImage3 }
  ]
};
