import React from 'react';
import Banner from 'src/components/Site/Banner';
import { AboutBannerData, teamSectionData, ourValuesData } from './aboutUsData';
// import CounterSection from 'src/components/Site/CounterSection';
import TeamSection from 'src/components/Site/TeamSection';
import OurValues from 'src/components/Site/OurValues';
import { AnimationContainer } from 'src/components/Animation';

const AboutUs = () => {
  return (
    <AnimationContainer direction="fade">
      <Banner bannerInfo={AboutBannerData} />
      {/* <CounterSection counterSectionData={counterSectionData} /> */}
      <TeamSection teamData={teamSectionData} />
      <OurValues data={ourValuesData} />
    </AnimationContainer>
  );
};

export default AboutUs;
