export const siteNames = {
  site1: 'Site1',
  site2: 'Site2'
};
export const siteName = process?.env?.REACT_APP_SITE_NAME || siteNames.site1;
// export const siteName = siteNames.site1;

export const getFaviconEl = () => {
  return document.getElementById('favicon') as HTMLAnchorElement | null;
};

export const donationLink = process?.env?.REACT_APP_DONATION_LINK || 'https://vebholic.com/';

export const backendApi = localStorage.getItem('backendApi') ?? (process?.env?.REACT_APP_API_URL || 'https://api.gurprasadvidyala.com');
