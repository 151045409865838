import { FiFacebook } from 'react-icons/fi';
import { AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai';
import { routes } from 'src/helpers/routes';
import { TbBrandTiktok } from 'react-icons/tb';
import { TiLocationOutline } from 'react-icons/ti';

export const linkType = {
  external: 'external',
  internal: 'internal',
  text: 'text'
};

export const social = [
  {
    link: '#',
    icon: <FiFacebook />,
    type: linkType.external
  },
  {
    link: '#',
    icon: <TbBrandTiktok />,
    type: linkType.external
  },
  {
    link: '#',
    icon: <AiOutlineInstagram />,
    type: linkType.external
  }
];

export const links = {
  institute: {
    heading: 'Institute',
    links: [
      { title: routes.aboutUs.title, link: routes.aboutUs.path, type: linkType.internal },
      { title: routes.teachers.title, link: routes.teachers.path, type: linkType.internal },
      { title: routes.contactUs.title, link: routes.contactUs.path, type: linkType.internal },
      { title: 'Admin Login', link: 'https://admin.sneiofsnc.com', type: linkType.external }
    ]
  },
  contactUs: {
    heading: 'Contact Us',
    links: [
      {
        title: '7500 N. Sam Houston Pkwy W., Houston TX 77064',
        link: '#',
        icon: <TiLocationOutline />,
        type: linkType.text
      },
      {
        title: 'sneikirtanschool@gmail.com',
        link: 'mailto:sneikirtanschool@gmail.com',
        icon: <AiOutlineMail />,
        type: linkType.external
      }
    ]
  }
};
