import { createTheme } from '@mui/material';
import { siteName } from 'src/config';

import darkScrollbar from '@mui/material/darkScrollbar';
import { grey } from '@mui/material/colors';

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Nunito',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar({
            track: grey[300],
            thumb: grey[400],
            active: grey[400]
          }),
          //scrollbarWidth for Firefox
          scrollbarWidth: 'thin'
        }
      }
    }
  },
  palette: {
    primary: {
      light: siteName?.includes('Site1') ? '#ffc400' : '#00498d',
      main: siteName?.includes('Site1') ? '#ffc400' : '#00498d',
      dark: siteName?.includes('Site1') ? '#ffc400' : '#00498d'
    },
    secondary: {
      light: '#047d1c',
      main: '#047d1c',
      dark: '#047d1c'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',

      contrastText: '#fff'
    },
    info: {
      light: '#75e2dd',
      main: '#2196f3',
      dark: '#1976d2'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c'
    }
  }
});
