import React from 'react';
import { ListItem, ListItemText, Avatar, Typography, Box, Tabs, Tab, Popover, IconButton, Badge, useMediaQuery } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import moment from 'moment';

import styles from 'src/components/Notification/notification.module.scss';

import { useNavigate } from 'react-router-dom';
import { axiosInstance } from 'src/axios';
import { useData } from 'src/context/Provider';
import { SET_NOTIFICATION } from 'src/context/ActionTypes';
import { CustomToastContext } from 'src/context/CustomToastContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={className}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `notification-tab-${index}`,
    'aria-controls': `notification-tabpanel-${index}`
  };
}

const colors = ['#c6f2eb', '#fbdfc0', '#c5ecf8'];

const getInitials = (data: any) => {
  let initials = '';
  if (data.updatedBy.user?.firstName) {
    initials = `${data?.updatedBy?.user?.firstName?.charAt(0)}${data?.updatedBy?.user?.lastName?.charAt(0)}`;
  } else {
    initials = `${data?.createdBy?.user?.firstName?.charAt(0)}${data?.createdBy?.user?.lastName?.charAt(0)}`;
  }
  return initials;
};

const Notification = ({ ...others }) => {
  const mobileDisplay = useMediaQuery('(max-width:768px)');
  const toastConfig = React.useContext(CustomToastContext);

  const [fullScreenNotificationAnchorEl, setFullScreenNotificationAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [loadingNotifications, setLoadingNotifications] = React.useState(false);
  const fullScreenNotificationOpen = Boolean(fullScreenNotificationAnchorEl);
  const fullScreenNotificationId = fullScreenNotificationOpen ? 'full-screen-notification' : undefined;

  const {
    state: { notifications },
    dispatch
  }: any = useData();

  // make all notification read
  const handleFullScreenNotificationClose = () => {
    const allRead = notifications?.map((n: any) => ({ ...n, isRead: true }));
    dispatch({ type: SET_NOTIFICATION, payload: allRead });
    localStorage.setItem('notification', JSON.stringify(notifications));
    setFullScreenNotificationAnchorEl(null);
  };

  React.useEffect(() => {
    if (!notifications) handleFullScreenNotificationClick();
  }, []);

  const setAnchorEl = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFullScreenNotificationAnchorEl(e.currentTarget);
  };

  const handleFullScreenNotificationClick = () => {
    setLoadingNotifications(true);

    axiosInstance()
      .get(`/notification?type=student`)
      .then(({ data: { data } }) => {
        const getAlreadyRead = JSON.parse(localStorage.getItem('notification') || '[]');
        const filteredNotification = data?.data?.map((d: any) => {
          const isExist = getAlreadyRead.find((n: any) => n._id === d._id);
          return { ...d, isRead: isExist ? true : false };
        });
        dispatch({ type: SET_NOTIFICATION, payload: filteredNotification || [] });
        setLoadingNotifications(false);
      })
      .catch((error) => {
        setLoadingNotifications(false);
        toastConfig.setToastConfig(error);
      });
  };

  const notificationCount = (nList: any) => {
    return nList?.filter((n: any) => !n.isRead)?.length || 0;
  };

  let navigate = useNavigate();

  return (
    <Box {...others}>
      <IconButton
        id="notificationButton"
        aria-describedby={fullScreenNotificationId}
        aria-label="settings"
        color="inherit"
        title="Notifications"
        onClick={(e) => {
          setAnchorEl(e);
        }}
      >
        <Badge
          variant="standard"
          overlap="circular"
          badgeContent={
            notifications && notificationCount(notifications) ? (
              <span style={{ fontSize: '0.8rem' }}>{notifications ? notificationCount(notifications) : 0}</span>
            ) : null
          }
          color="secondary"
        >
          <NotificationsNoneOutlinedIcon className="setIcon" />
        </Badge>
      </IconButton>
      <Popover
        id={fullScreenNotificationId}
        open={fullScreenNotificationOpen}
        anchorEl={fullScreenNotificationAnchorEl}
        onClose={handleFullScreenNotificationClose}
        anchorOrigin={{
          vertical: mobileDisplay ? 'top' : 'bottom',
          horizontal: mobileDisplay ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: mobileDisplay ? 'bottom' : 'top',
          horizontal: mobileDisplay ? 'right' : 'left'
        }}
      >
        <div className={styles.notificationContainer}>
          <Box className={styles.notificatoinHead} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Typography className={styles.headText}>Notifications</Typography>
          </Box>

          {loadingNotifications ? (
            <div className={styles.singleNotification}>
              <Typography>Loading notifications...</Typography>
            </div>
          ) : notifications && notifications?.length ? (
            notifications?.map((d: any, index: number) => {
              const isLast = notifications?.length - 1 === index;
              const nameInitial = getInitials(d);
              return (
                <>
                  <div
                    className={styles.singleNotification}
                    onClick={() => {
                      if (d.link?.length) {
                        const { hostname, pathname } = new URL(d.link);
                        if (window.location.hostname === hostname) navigate(pathname);
                        else window.open(d.link, '_blank');
                      }
                    }}
                    style={{ cursor: d.link?.length ? 'pointer' : 'default', backgroundColor: !d?.isRead ? '#CBE4F8' : '#fff' }}
                  >
                    <Avatar
                      alt="No Image"
                      variant="rounded"
                      src={d?.icon || ''}
                      className={styles.avatar}
                      sx={{ backgroundColor: colors[index % colors.length], color: '#143b36' }}
                    >
                      <Typography>{nameInitial}</Typography>
                    </Avatar>
                    <div className={styles.textContainer}>
                      <Typography className={styles.titleContainer}>
                        <Typography className={styles.title} component="span">
                          {d.title}
                        </Typography>
                        <Typography className={styles.time} component="span">
                          {moment(d.date).fromNow()}
                        </Typography>
                      </Typography>
                      <Typography className={styles.description}>{d.description}</Typography>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <ListItem>
              <ListItemText
                primary={<Typography sx={{ fontWeight: '400', color: 'gray', width: '200px', textAlign: 'center' }}>No notification</Typography>}
              ></ListItemText>
            </ListItem>
          )}
          {/* </TabPanel> */}
        </div>
      </Popover>
    </Box>
  );
};

export default Notification;
