import { Box, TextField, Typography, InputAdornment, IconButton, CircularProgress, Grid } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import queryString from 'query-string';
import { siteName, siteNames } from 'src/config';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { buttonStyle, buttonType } from 'src/components/Buttons';
import site1Logo from 'src/components/Site1/assets/Logo.png';
import siteLogo from 'src/components/Site/assets/logo/Logo.svg';
import { routes } from '../../helpers/routes';

const logo = () => {
  if (siteName?.includes(siteNames.site1)) {
    return site1Logo;
  } else if (siteName?.includes(siteNames.site2)) {
    return siteLogo;
  } else {
    return siteLogo;
  }
};

const ResetButton = () => {
  if (siteName?.includes(siteNames.site1)) {
    return buttonStyle(buttonType.yellowButton);
  } else if (siteName?.includes(siteNames.site2)) {
    return buttonStyle(buttonType.secondary);
  } else {
    return buttonStyle(buttonType.secondary);
  }
};

interface ResetPasswordData {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const toastConfig = useContext(CustomToastContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfrimPassword] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenChecking, setTokenChecking] = useState(false);
  const { email, token } = queryString.parse(window.location.search);
  const ButtonToDisplay = ResetButton();

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    setTokenChecking(true);
    axiosInstance()
      .get(`/users/check-token?token=${token}`)
      .then(({ data }) => {
        data.data
          ? setIsTokenValid(true)
          : toastConfig.setToastConfig({
              message: 'Invalid token',
              type: 'error',
              open: true
            });
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const handleSubmit = async (values: ResetPasswordData) => {
    axiosInstance()
      .post(`/users/reset-password`, {
        token: token,
        password: values.password
      })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          message: data.message,
          type: 'success'
        });
        navigate('/login', { replace: true });
      })
      .catch((error) => {
        localStorage.removeItem('token');
        toastConfig.setToastConfig(error);
      });
  };

  const validateForm = (values: ResetPasswordData) => {
    const errors: any = {};
    if (!values.password) {
      errors.password = 'Required field';
    } else if (!/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(values.password)) {
      errors.password = 'Minimum eight characters, at least one uppercase, one lowercase, one number and one special character';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required field';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'New Password and Confirm Password should be same';
    }
    return errors;
  };

  return (
    <div className="container2">
      <Box sx={{ ...customStyles.container }}>
        <Box sx={{ ...customStyles.loginContainer }}>
          <img
            src={logo()}
            alt=""
            style={{
              maxWidth: '161px',
              margin: '0px auto 15px',
              maxHeight: '80px',
              objectFit: 'contain'
            }}
          />
          <Typography variant="h2" sx={{ ...customStyles.logoText }}>
            {routes.resetPassword.title}
          </Typography>
          <Box>
            <Formik
              initialValues={{
                password: '',
                confirmPassword: ''
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form>
                  <Box mb={3}>
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          data-testid="password"
                          variant="outlined"
                          type={showPassword ? 'text' : 'password'}
                          disabled={!isTokenValid || !tokenChecking}
                          size="small"
                          placeholder="New password"
                          label="New password"
                          name="password"
                          value={values['password']}
                          error={touched['password'] && Boolean(errors['password'])}
                          onChange={(e) => setFieldValue('password', e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          helperText={touched['password'] && errors['password']}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          data-testid="confimPassword"
                          variant="outlined"
                          type={showConfirmPassword ? 'text' : 'password'}
                          disabled={!isTokenValid || !tokenChecking}
                          size="small"
                          placeholder="Confirm password"
                          label="Confirm password"
                          name="confimPassword"
                          value={values['confirmPassword']}
                          error={touched['confirmPassword'] && Boolean(errors['confirmPassword'])}
                          onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton className="p-0" onClick={() => setShowConfrimPassword(!showConfirmPassword)}>
                                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          helperText={touched['confirmPassword'] && errors['confirmPassword']}
                        />
                      </Grid>
                    </Grid>
                    <Box className="mt-2">
                      <ButtonToDisplay
                        style={{ ...customStyles.buttons }}
                        fullWidth
                        type="submit"
                        disabled={isSubmitting}
                        endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                        onClick={submitForm}
                      >
                        Submit
                      </ButtonToDisplay>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ResetPassword;

const customStyles = {
  container: {
    width: '100%',
    padding: { xs: '40px 0', sm: '60px 0', md: '100px 0' }
  },
  loginContainer: {
    maxWidth: '400px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '20px',
    border: '1px solid rgb(231, 235, 240)'
  },
  logoText: {
    fontWeight: 700,
    fontSize: '20px',
    color: 'var(--new_theme_color)',
    textAlign: 'center'
  },
  lebel: {
    display: 'block',
    fontWeight: 400,
    size: '14px',
    marginLeft: '5px',
    color: '#AEAEAE',
    marginBottom: '5px',
    marginTop: '10px'
  },
  fields: {
    '> div': {
      height: '48px',
      input: {
        fontSize: '16px !important'
      }
    }
  },
  buttons: {
    marginTop: '20px'
  }
};
