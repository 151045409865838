import React, { Fragment } from 'react';
import { siteName } from 'src/config';
import Site1Home from 'src/components/Site1/Home';
import Site2Home from 'src/components/Site/Home';

const index = () => {
  return <>{siteName?.includes('Site1') ? <Site1Home /> : <Site2Home />}</>;
};

export default index;
