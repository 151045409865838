import React from 'react';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { axiosInstance } from 'src/axios';
import { routes } from 'src/helpers/routes';
import MuiPhoneInput from 'material-ui-phone-number';

const Student = ({ studentData, setStudentData, user }: any) => {
  const [students, setStudents] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const toastConfig = React.useContext(CustomToastContext);

  React.useEffect(() => {
    if (user?.role === 'Parent') {
      fetchStudents();
    }
  }, [user]);

  const fetchStudents = async () => {
    await axiosInstance()
      .get(routes.students.api)
      .then(({ data: { data } }) => {
        setStudents(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleAutocomplete = (values: any) => {
    setValue(values);
    if (values) {
      setStudentData([
        {
          firstName: values?.firstName,
          lastName: values?.lastName,
          phone: values?.phone,
          email: values?.email,
          _id: values?._id
        }
      ]);
    } else {
      setStudentData([]);
    }
  };

  return (
    <Box pt={3}>
      <span style={{ display: 'block' }}>Student Information</span>
      {user?.role === 'Parent' && students && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple={false}
              size={'small'}
              value={value}
              id="multiple-limit-tags"
              onChange={(event, newValue) => {
                handleAutocomplete(newValue);
              }}
              getOptionLabel={(option: any) => option?.firstName + ' ' + option?.lastName}
              options={students}
              renderInput={(params) => <TextField {...params} label="Select Student" placeholder="Student" />}
              sx={{ mt: 2, mb: 1 }}
            />
          </Grid>
        </Grid>
      )}
      {/* <Box pt={1}>
        {studentData?.length > 0 && (
          <Box border={'1px solid #dddddd'} borderRadius={4}>
            {studentData?.map((data: any, index: any) => (
              <Grid key={index} container spacing={2} alignItems="center" mt={1} mb={2} pl={2} pr={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    value={data.firstName}
                    onChange={(e: any) => {
                      const temp = [...studentData];
                      temp[index]['firstName'] = e.target.value;
                      setStudentData(temp);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    value={data.lastName}
                    onChange={(e: any) => {
                      const temp = [...studentData];
                      temp[index]['lastName'] = e.target.value;
                      setStudentData(temp);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPhoneInput
                    defaultCountry={'us'}
                    disableAreaCodes
                    countryCodeEditable={true}
                    enableLongNumbers={false}
                    fullWidth
                    variant="outlined"
                    type="phone"
                    required
                    label="Phone"
                    name="phone"
                    dropdownClass="phone_dropdown"
                    size="small"
                    value={data.phone}
                    onChange={(val: any) => {
                      const temp = [...studentData];
                      temp[index]['phone'] = val.toString();
                      setStudentData(temp);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    size="small"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    value={data.email}
                    onChange={(e: any) => {
                      const temp = [...studentData];
                      temp[index]['email'] = e.target.value;
                      setStudentData(temp);
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      </Box> */}
    </Box>
  );
};

export default Student;
