// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import MuiPhoneInput from 'material-ui-phone-number';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { PrimaryButton } from 'src/components/Buttons';
import { routes } from 'src/helpers/routes';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { TextField, Autocomplete, CircularProgress, RadioGroup, FormControlLabel, Radio, Grid, Button } from '@mui/material';
import { AnimationContainer } from 'src/components/Animation';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

import { Country, State, City } from 'country-state-city';

const HearOfUs = ['Family Member', 'Friend', 'Social Media', 'Other'];

interface EmerygencyContact {
  heardOfUs: string;
  emergencyFirstName: string;
  emergencyMiddleName: string;
  emergencyLastName: string;
  emergencyEmail: string;
  emergencyGender: string;
  emergencyPhone: string;
  emergencyAddress: string;
  emergencyCity: string;
  emergencyState: string;
  emergencyCountry: string;
  emergencyZipCode: string;
}

const EmergencyContact = ({ step, setStep, profileData }: any) => {
  const toastConfig = React.useContext(CustomToastContext);
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  // csc
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState({ label: 'Canada', isoCode: 'CA' });
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [hearOfUs, setHearOfUs] = useState(null);

  useEffect(() => {
    const getCountries = async () => {
      try {
        setIsLoading(true);
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: `${name}`
        }));
        setCountries(allCountries);
        setIsLoading(false);
      } catch (error) {
        setCountries([]);
        setIsLoading(false);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry.isoCode);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: name
        }));
        setCities([]);
        setSelectedCity('');
        setStates(allStates);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity('');
      }
    };
    setSelectedState({ label: '' });
    setSelectedCity({ label: '' });
    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          label: name
        }));
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };
    setSelectedCity({ label: '' });
    getCities();
  }, [selectedState]);

  const handleSubmit = (data: any) => {
    const payload = {
      ...data,
      ...profileData,
      emergencyState: data?.emergencyState?.label || '',
      emergencyCity: data?.emergencyCity?.label || '',
      emergencyCountry: data?.emergencyCountry?.label || ''
    };
    setUpdating(true);
    axiosInstance()
      .post(`/auth/profile`, payload)
      .then(({ data: { data } }) => {
        toastConfig.setToastConfig({
          open: true,
          message: `Welcome ${payload.firstName}`,
          type: 'success'
        });
        setUpdating(false);
        setStep(4);
        if (data?.user?.role === 'Parent') {
          navigate(routes.students.path, { replace: true });
        } else {
          navigate(routes.courses.path, { replace: true });
        }
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
        setUpdating(false);
      });
  };

  const validateForm = (values: EmerygencyContact) => {
    const errors: any = {};
    if (profileData.role === 'Parent') {
      return errors;
    }
    if (!values.emergencyFirstName) {
      errors.emergencyFirstName = 'First Name is required';
    }
    if (!values.emergencyLastName) {
      errors.emergencyLastName = 'Last Name is required';
    }
    if (!values.heardOfUs) {
      errors.heardOfUs = 'Please tell us how did you hear about us';
    }
    // email
    if (!values.emergencyEmail) {
      errors.emergencyEmail = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emergencyEmail)) {
      errors.emergencyEmail = 'Invalid email address';
    }
    if (!values.emergencyPhone) {
      errors.emergencyPhone = 'Phone number is required';
    } else {
      let simpleStr = values?.emergencyPhone.replaceAll(' ', '');
      simpleStr = simpleStr.replaceAll('(', '');
      simpleStr = simpleStr.replaceAll(')', '');
      simpleStr = simpleStr.replaceAll('-', '');
      const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

      if (!isValid) {
        errors.emergencyPhone = 'Phone number is not valid';
      }
    }
    return errors;
  };
  return (
    <AnimationContainer direction="fade">
      <Formik
        initialValues={{
          heardOfUs: '',
          emergencyFirstName: '',
          emergencyMiddleName: '',
          emergencyLastName: '',
          emergencyEmail: '',
          emergencyGender: 'Male',
          emergencyPhone: '',
          emergencyAddress: '',
          emergencyCity: '',
          emergencyState: '',
          emergencyCountry: { label: 'Canada', isoCode: 'CA' },
          emergencyZipCode: ''
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid container spacing={2} mt={1}>
              {step === 3 && (
                <>
                  <Grid item xs={12} md={12}>
                    <h6 className={styles.formSubHeader}>Emergency Contact</h6>
                  </Grid>
                  {/* first name */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <TextField
                        fullWidth
                        data-testid="emergencyFirstName"
                        variant="outlined"
                        type="text"
                        required
                        placeholder="First Name"
                        label="First Name"
                        name="emergencyFirstName"
                        size="small"
                        onChange={(e) => setFieldValue('emergencyFirstName', e.target.value)}
                        value={values['emergencyFirstName']}
                        error={touched['emergencyFirstName'] && Boolean(errors['emergencyFirstName'])}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* middle name */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <TextField
                        fullWidth
                        data-testid="emergencyMiddleName"
                        variant="outlined"
                        type="text"
                        placeholder="Middle Name"
                        label="Middle Name"
                        name="emergencyMiddleName"
                        size="small"
                        onChange={(e) => setFieldValue('emergencyMiddleName', e.target.value)}
                        value={values['emergencyMiddleName']}
                        error={touched['emergencyMiddleName'] && Boolean(errors['emergencyMiddleName'])}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* last name */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <TextField
                        fullWidth
                        data-testid="emergencyLastName"
                        variant="outlined"
                        type="text"
                        required
                        placeholder="Middle Name"
                        label="Last Name"
                        name="emergencyLastName"
                        size="small"
                        onChange={(e) => setFieldValue('emergencyLastName', e.target.value)}
                        value={values['emergencyLastName']}
                        error={touched['emergencyLastName'] && Boolean(errors['emergencyLastName'])}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* email */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <TextField
                        fullWidth
                        data-testid="emergencyEmail"
                        variant="outlined"
                        type="text"
                        required
                        placeholder="Emergency Email"
                        label="Emergency Email"
                        name="emergencyEmail"
                        size="small"
                        onChange={(e) => setFieldValue('emergencyEmail', e.target.value)}
                        value={values['emergencyEmail']}
                        error={touched['emergencyEmail'] && Boolean(errors['emergencyEmail'])}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* gender */}
                  <Grid item xs={12}>
                    <AnimationContainer direction="fade">
                      <RadioGroup
                        row
                        aria-label="emergencyGender"
                        name="row-radio-buttons-group"
                        value={values.emergencyGender}
                        onChange={(e) => setFieldValue('emergencyGender', e.target.value)}
                      >
                        <FormControlLabel value="Male" control={<Radio />} label="Male" />
                        <FormControlLabel value="Female" control={<Radio />} label="Female" />
                      </RadioGroup>
                    </AnimationContainer>
                  </Grid>
                  {/* phone */}
                  <Grid item xs={12}>
                    <AnimationContainer direction="fade">
                      <MuiPhoneInput
                        defaultCountry={'ca'}
                        disableAreaCodes
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        excludeCountries={['us']}
                        fullWidth
                        variant="outlined"
                        type="phone"
                        required={true}
                        dropdownClass="phone_dropdown"
                        label="Emergency Contact"
                        name="emergencyPhone"
                        size="small"
                        onChange={(val) => setFieldValue('emergencyPhone', val.toString())}
                        value={values['emergencyPhone']}
                        error={touched['emergencyPhone'] && Boolean(errors['emergencyPhone'])}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* Country */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <Autocomplete
                        fullWidth
                        data-testid="emergencyCountry"
                        size="small"
                        options={countries}
                        value={selectedCountry}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            setSelectedCountry({
                              label: newValue,
                              isoCode: ''
                            });
                            setFieldValue('emergencyCountry', newValue);
                          } else if (newValue && newValue.inputValue) {
                            setSelectedCountry({
                              label: newValue.inputValue,
                              isoCode: ''
                            });
                            setFieldValue('emergencyCountry', newValue.inputValue);
                          } else {
                            setSelectedCountry(newValue);
                            setFieldValue('emergencyCountry', newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            error={touched['emergencyCountry'] && Boolean(errors['emergencyCountry'])}
                            name="emergencyCountry"
                            required
                            type="text"
                            placeholder="Country"
                            label="Country"
                            {...params}
                          />
                        )}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* state */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <Autocomplete
                        fullWidth
                        data-testid="emergencyState"
                        size="small"
                        options={states}
                        value={selectedState}
                        onChange={(event: any, newValue: any | null) => {
                          if (typeof newValue === 'string') {
                            setSelectedState({
                              label: newValue
                            });
                            setFieldValue('emergencyState', newValue);
                          } else if (newValue && newValue.inputValue) {
                            setSelectedState({
                              label: newValue.inputValue
                            });
                            setFieldValue('emergencyState', newValue.inputValue);
                          } else {
                            setSelectedState(newValue);
                            setFieldValue('emergencyState', newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            value={selectedState?.label}
                            error={touched['emergencyState'] && Boolean(errors['emergencyState'])}
                            name="emergencyState"
                            required
                            type="text"
                            placeholder="Province"
                            label="Province"
                            {...params}
                          />
                        )}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* city */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <Autocomplete
                        fullWidth
                        data-testid="emergencyCity"
                        size="small"
                        options={cities}
                        value={selectedCity}
                        onChange={(event: any, newValue: any | null) => {
                          if (typeof newValue === 'string') {
                            setSelectedCity({
                              label: newValue
                            });
                            setFieldValue('emergencyCity', newValue);
                          } else if (newValue && newValue.inputValue) {
                            setSelectedCity({
                              label: newValue.inputValue
                            });
                            setFieldValue('emergencyCity', newValue.inputValue);
                          } else {
                            setSelectedCity(newValue);
                            setFieldValue('emergencyCity', newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            error={touched['emergencyCity'] && Boolean(errors['emergencyCity'])}
                            name="emergencyCity"
                            value={selectedCity?.label}
                            required
                            type="text"
                            placeholder="City"
                            label="City"
                            {...params}
                          />
                        )}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* ZIP */}
                  <Grid item xs={12} md={6}>
                    <AnimationContainer direction="fade">
                      <TextField
                        required
                        fullWidth
                        data-testid="emergencyZipCode"
                        variant="outlined"
                        type="text"
                        placeholder="Postal Code"
                        label="Postal Code"
                        name="emergencyZipCode"
                        size="small"
                        onChange={(e) => setFieldValue('emergencyZipCode', e.target.value)}
                        value={values['emergencyZipCode']}
                        error={touched['emergencyZipCode'] && Boolean(errors['emergencyZipCode'])}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* address */}
                  <Grid item xs={12} sm={12}>
                    <AnimationContainer direction="fade">
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        required
                        data-testid="emergencyAddress"
                        variant="outlined"
                        type="text"
                        placeholder="Address"
                        label="Address"
                        name="emergencyAddress"
                        size="small"
                        onChange={(e) => setFieldValue('emergencyAddress', e.target.value)}
                        value={values['emergencyAddress']}
                        error={touched['emergencyAddress'] && Boolean(errors['emergencyAddress'])}
                        helperText={touched['emergencyAddress'] && errors['emergencyAddress']}
                      />
                    </AnimationContainer>
                  </Grid>
                  {/* submit */}
                  <Grid item xs={12}>
                    <PrimaryButton
                      disabled={updating}
                      fullWidth
                      variant="rounded"
                      onClick={() => setStep(4)}
                      endIcon={updating && <CircularProgress size={20} color="inherit" />}
                    >
                      Continue
                    </PrimaryButton>
                  </Grid>
                </>
              )}
              {step === 4 && (
                <>
                  <Grid item xs={12}>
                    <Button
                      className={styles.prevButton}
                      onClick={() => setStep((prev: number) => (prev !== 1 ? prev - 1 : prev))}
                      startIcon={<FiArrowLeftCircle />}
                    >
                      Previous Step
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <AnimationContainer direction="fade">
                      <Autocomplete
                        fullWidth
                        data-testid="heardOfUs"
                        size="small"
                        options={HearOfUs}
                        value={hearOfUs}
                        onChange={(event: any, newValue: string | null) => {
                          setHearOfUs(newValue);
                          if (newValue !== 'Other') {
                            setFieldValue('heardOfUs', newValue);
                          } else {
                            setFieldValue('heardOfUs', '');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            error={touched['heardOfUs'] && Boolean(errors['heardOfUs'])}
                            name="heardOfUs"
                            required
                            type="text"
                            placeholder="How did you hear of us"
                            label="How did you hear of us"
                            {...params}
                          />
                        )}
                      />
                    </AnimationContainer>
                  </Grid>
                  {hearOfUs === 'Other' && (
                    <Grid item xs={12} sm={12}>
                      <AnimationContainer direction="fade">
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          data-testid="heardOfUs"
                          variant="outlined"
                          type="text"
                          placeholder="How did you hear about us?"
                          label="How did you hear about us?"
                          name="heardOfUs"
                          size="small"
                          onChange={(e) => setFieldValue('heardOfUs', e.target.value)}
                          value={values['heardOfUs']}
                          error={touched['heardOfUs'] && Boolean(errors['heardOfUs'])}
                          required
                        />
                      </AnimationContainer>
                    </Grid>
                  )}
                  {/* hearof */}

                  <Grid item xs={12}>
                    <PrimaryButton
                      disabled={updating}
                      fullWidth
                      variant="rounded"
                      onClick={submitForm}
                      endIcon={updating && <CircularProgress size={20} color="inherit" />}
                    >
                      Finish
                    </PrimaryButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </AnimationContainer>
  );
};

export default EmergencyContact;
