import { Box, Button, CircularProgress, IconButton, InputAdornment, Link as MuiLink, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { routes } from 'src/helpers/routes';
import { useData } from 'src/context/Provider';
import { SET_BRAND, SET_USER } from 'src/context/ActionTypes';
import site1Logo from 'src/components/Site1/assets/Logo.png';
import siteLogo from 'src/components/Site/assets/logo/Logo.jpg';
import { buttonStyle, buttonType } from 'src/components/Buttons';
import { siteName, siteNames } from 'src/config';
import { AnimationContainer } from 'src/components/Animation';
import styles from './styles.module.scss';
import { FiArrowLeftCircle } from 'react-icons/fi';

interface LoginData {
  email: string;
  password: string;
}

const logo = () => {
  if (siteName?.includes(siteNames.site1)) {
    return site1Logo;
  } else if (siteName?.includes(siteNames.site2)) {
    return siteLogo;
  } else {
    return siteLogo;
  }
};

const SubmitButton = () => {
  if (siteName?.includes(siteNames.site1)) {
    return buttonStyle(buttonType.yellowButton);
  } else if (siteName?.includes(siteNames.site2)) {
    return buttonStyle(buttonType.primary);
  } else {
    return buttonStyle(buttonType.secondary);
  }
};

const Login = ({ className = '' }: { className?: string }) => {
  const navigate = useNavigate();
  const ButtonToDisplay = SubmitButton();
  const location: any = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const toastConfig = useContext(CustomToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const { dispatch }: any = useData();

  const handleSubmit = async (values: LoginData) => {
    setIsLoading(true);
    axiosInstance()
      .post('/auth/login', {
        email: values.email,
        password: values.password
      })
      .then(({ data: { data } }) => {
        localStorage.setItem('token', data?.token);
        dispatch({ type: SET_USER, payload: data?.user });
        dispatch({ type: SET_BRAND, payload: data?.brand });
        if (location.state?.from?.pathname) {
          navigate(location.state?.from?.pathname, { replace: true });
        } else {
          navigate(routes.courses.path, { replace: true });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setIsLoading(false);
      });
  };

  const validateForm = (values: LoginData) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  return (
    <AnimationContainer direction="fade" className={`${siteName?.includes(siteNames.site2) ? styles.fullscreen_login : 'container2 '}`}>
      {siteName?.includes(siteNames.site2) && (
        <div className={`container `}>
          <Button
            className={styles.backButton}
            sx={{ color: 'white', textTransform: 'capitalize' }}
            onClick={() => navigate('/')}
            startIcon={<FiArrowLeftCircle />}
          >
            Return
          </Button>
        </div>
      )}
      <Box
        className={`${styles.container} ${className}`}
        style={{ minHeight: siteName?.includes(siteNames.site2) ? 'calc(100vh - 123px)' : '705px' }}
      >
        <Box className={`${siteName?.includes(siteNames.site2) ? styles.roundedLoginContainer : styles.flexB4}`}>
          <Box className={` ${siteName?.includes(siteNames.site2) ? styles.loginInner : styles.loginContainer}`}>
            <img
              src={logo()}
              alt=""
              style={{
                maxWidth: '161px',
                margin: '0px auto 15px',
                maxHeight: '80px',
                objectFit: 'contain'
              }}
            />
            {siteName?.includes(siteNames.site2) ? (
              <Typography className={styles.logoTextGv} mb={4}>
                Sign in to myGV
              </Typography>
            ) : (
              <Typography className={styles.logoText} mb={1}>
                Login
              </Typography>
            )}
            <Box>
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validate={validateForm}
                onSubmit={handleSubmit}
              >
                {({ submitForm, values, errors, touched, setFieldValue }) => (
                  <Form>
                    <Box>
                      {siteName?.includes(siteNames.site1) ? (
                        <>
                          <Box mb={2}>
                            <Typography className={styles.lebel}>Email</Typography>
                            <TextField
                              fullWidth
                              data-testid="email"
                              variant="outlined"
                              type="email"
                              placeholder="example@gmail.com"
                              name="email"
                              size="small"
                              onChange={(e) => setFieldValue('email', e.target.value)}
                              value={values['email']}
                              error={touched['email'] && Boolean(errors['email'])}
                              className={styles.fields}
                            />
                          </Box>

                          <Box>
                            <Typography className={styles.lebel}>Password</Typography>
                            <TextField
                              fullWidth
                              data-testid="password"
                              variant="outlined"
                              type={showPassword ? 'text' : 'password'}
                              size="small"
                              placeholder="Enter password"
                              name="password"
                              value={values['password']}
                              error={touched['password'] && Boolean(errors['password'])}
                              onChange={(e) => setFieldValue('password', e.target.value)}
                              className={styles.fields}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box mb={4}>
                            <TextField
                              fullWidth
                              data-testid="email"
                              variant="outlined"
                              type="email"
                              placeholder="example@gmail.com"
                              name="email"
                              size="small"
                              label="Email"
                              onChange={(e) => setFieldValue('email', e.target.value)}
                              value={values['email']}
                              error={touched['email'] && Boolean(errors['email'])}
                            />
                          </Box>

                          <Box>
                            <TextField
                              fullWidth
                              data-testid="password"
                              variant="outlined"
                              label="Password"
                              type={showPassword ? 'text' : 'password'}
                              size="small"
                              placeholder="Enter password"
                              name="password"
                              value={values['password']}
                              error={touched['password'] && Boolean(errors['password'])}
                              onChange={(e) => setFieldValue('password', e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Box>
                        </>
                      )}

                      <Box mt={1} mb={4}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap'
                          }}
                        >
                          {siteName?.includes(siteNames.site1) ? (
                            <MuiLink className={styles.forgotPassword} component={Link} to={routes.register.path}>
                              Don't have an account?
                            </MuiLink>
                          ) : (
                            <Box className={styles.rememberMe}>
                              <input
                                type="checkbox"
                                name="rememberMe"
                                id="rememberMe"
                                checked={rememberMe}
                                onClick={() => setRememberMe((prev) => !prev)}
                              />
                              <label htmlFor="rememberMe"></label>
                              <label htmlFor="rememberMe">Remember Me</label>
                            </Box>
                          )}
                          <MuiLink className={styles.forgotPassword} component={Link} to="/forget-password">
                            Forgot Password?
                          </MuiLink>
                        </Box>
                      </Box>
                      <Box className={styles.buttons}>
                        <ButtonToDisplay
                          fullWidth
                          type="submit"
                          disabled={isLoading}
                          endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
                          onClick={submitForm}
                          variant="rounded"
                        >
                          Login
                        </ButtonToDisplay>
                        {siteName?.includes(siteNames.site2) && (
                          <Typography className={`${styles.forgotPassword} text-center`} style={{ marginTop: 5 }}>
                            Don't have an account?
                            <MuiLink component={Link} to={routes.register.path}>
                              {' '}
                              Sign up
                            </MuiLink>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
      {siteName?.includes(siteNames.site2) && (
        <>
          <footer>
            <div className="container">
              <p className="fw-3 lh-1">Copyright @ 2022 Mai Bhago Charity. All Rights Reserved</p>
              <div>
                <Link to="#" className="fw-3 lh-1">
                  Terms of Use
                </Link>{' '}
                |{' '}
                <Link to="#" className="fw-3 lh-1">
                  Privacy Policy
                </Link>{' '}
                |{' '}
                <Link to="#" className="fw-3 lh-1">
                  Land Acknowledgment
                </Link>
              </div>
            </div>
          </footer>
        </>
      )}
    </AnimationContainer>
  );
};

export default Login;
