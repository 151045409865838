import React, { Fragment, useEffect } from 'react';
import { axiosInstance } from 'src/axios';
import { Box } from '@mui/system';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { Autocomplete, Button, Container, Divider, Grid, IconButton, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { StaticDatePicker } from '@mui/x-date-pickers';
import Student from './Student';
import { useData } from 'src/context/Provider';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import { siteName, siteNames } from 'src/config';
import queryString from 'query-string';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Offers from './Offers';
import { COURSE_TYPES, sessionLocalStorageKey } from 'src/helpers/helper';
import { max, min } from 'lodash';
import { currencyConverter } from 'src/helpers/currency';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Courses = () => {
  const toastConfig = React.useContext(CustomToastContext);
  const [coursesData, setCoursesData] = React.useState([]);
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [dateToSave, setDateToSave] = React.useState(null);
  const [studentData, setStudentData] = React.useState(null);
  const [blockDates, setBlockDates] = React.useState(null);
  const [bookDates, setBookDates] = React.useState([]);
  const [offersData, setOffersData] = React.useState([]);
  const [paymentData, setPaymentData] = React.useState({ amount: 0, discount: 0, netAmount: 0 });

  const [calanderMinMax, setCalanderMinMax] = React.useState({ min: new Date(), max: new Date() });
  const [scheduleList, setScheduleList] = React.useState([]);
  const [alreadyWaitlist, setAlreadyWaitlist] = React.useState(false);

  const { courseName }: any = queryString.parse(window.location.search);

  const {
    state: { user, brand }
  }: any = useData();

  useEffect(() => {
    let sessionData: any = localStorage.getItem(sessionLocalStorageKey);

    if (!sessionData && bookDates.length === 0) return;
    sessionData = JSON.parse(sessionData);

    setSelectedCourse(sessionData?.selectedCourse);
    setBookDates(sessionData?.bookDates);
    setStudentData(sessionData?.studentData);
  }, [blockDates]);

  useEffect(() => {
    if (!selectedDate) return;
    setDateToSave(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (user && user?.role !== 'Parent') {
      setStudentData([
        {
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
          email: user?.email,
          _id: user?._id
        }
      ]);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!selectedCourse?.schedule?.length && studentData?.length && selectedCourse?._id) {
      fetchWaitlist();
    }
  }, [studentData, selectedCourse]);

  const fetchWaitlist = () => {
    axiosInstance()
      .get(`/course-master/${selectedCourse?._id}/waitlist/${studentData[0]?._id}`)
      .then(({ data: { data } }) => {
        setAlreadyWaitlist(!!data?._id);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const fetchData = () => {
    axiosInstance()
      .get('/courses')
      .then(({ data: { data } }) => {
        setCoursesData(data);
        if (courseName && data?.length) {
          const res = data?.find((e: any) => e?.courseName?.toLowerCase()?.includes(courseName?.toLowerCase()));
          if (res) {
            handleSelectCourse(res);
          } else {
            handleSelectCourse(data[0]);
          }
        } else if (data?.length) {
          handleSelectCourse(data[0]);
        }
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleCreateSession = () => {
    const data: any = {};
    data.courseId = selectedCourse?._id;
    data.bookDates = bookDates?.map((e: any) => {
      return { scheduleId: e._id, date: e.startDate, time: e.startTime };
    });
    data.students = studentData;

    const dataToPersist: any = {
      selectedCourse,
      bookDates,
      studentData,
      date: dateToSave
    };

    localStorage.setItem(sessionLocalStorageKey, JSON.stringify(dataToPersist));

    axiosInstance()
      .post('/sessions', data)
      .then(({ data }) => {
        navigate(`/session/${data?.data?.sessionId}`);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleWaitlist = () => {
    setAlreadyWaitlist(true);
    const data = { studentId: studentData[0]?._id, status: true };
    axiosInstance()
      .post(`/course-master/${selectedCourse._id}/waitlist`, data)
      .then()
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleSelectCourse = (item: any) => {
    // if (item?.schedule?.length) {
    //   item.schedule = item?.schedule?.filter((schedule: any) => schedule.status);
    // }
    setBlockDates(null);
    setSelectedCourse(item);
    setSelectedDate(null);
    setSelectedTimeSlot(null);
    setBookDates([]);
    if (selectedCourse) {
      localStorage.removeItem(sessionLocalStorageKey);
    }

    let minDate: any = min(item?.schedule?.map((e: any) => moment(e.startDate)));
    minDate = max([minDate, new Date().setDate(new Date().getDate() + 1)]);
    let maxDate: any = max(item?.schedule?.map((e: any) => moment(e.endDate)));
    setCalanderMinMax({ min: new Date(minDate), max: new Date(maxDate) });

    axiosInstance()
      .get(`/sessions/blocked/${item._id}`)
      .then(({ data: { data } }) => {
        setBlockDates(data?.blockDates);
        setOffersData(data?.offers);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const sortByDateTime = (arr: any) => {
    const sorterDate = (a: any, b: any) => {
      const aDateTime = `${moment(a.startDate).format('MMM DD YYYY')} ${moment(a.startTime).format('LTS')}`;
      const bDateTime = `${moment(b.startDate).format('MMM DD YYYY')} ${moment(b.startTime).format('LTS')}`;
      return new Date(aDateTime).getTime() - new Date(bDateTime).getTime();
    };
    arr.sort(sorterDate);
    return arr;
  };

  const sortByTime = (arr: any) => {
    const sorterDate = (a: any, b: any) => {
      const aDateTime = `${moment().format('MMM DD YYYY')} ${moment(a.startTime).format('LTS')}`;
      const bDateTime = `${moment().format('MMM DD YYYY')} ${moment(b.startTime).format('LTS')}`;
      return new Date(aDateTime).getTime() - new Date(bDateTime).getTime();
    };
    arr.sort(sorterDate);
    return arr;
  };

  const handleAddBookDates = () => {
    if (
      bookDates?.some(
        (e) =>
          moment(e?.startDate).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') &&
          e?._id === selectedTimeSlot?._id &&
          e?.instructor?.optionValue === selectedTimeSlot?.instructor?.optionValue
      )
    ) {
      toastConfig.setToastConfig({
        open: true,
        message: 'Already added timeslot',
        type: 'error'
      });
    } else {
      const data = { ...selectedTimeSlot };
      data.startDate = selectedDate;
      data.endDate = selectedDate;
      setBookDates(sortByDateTime([...bookDates, data]));
      setSelectedDate(null);
      setSelectedTimeSlot(null);
    }
  };

  const handleRemoveBookDate = (index: any) => {
    setBookDates(bookDates.filter((e: any, i: any) => i != index));
  };

  useEffect(() => {
    var amount = 0;
    var discount = 0;
    var netAmount = 0;
    bookDates?.forEach((e) => {
      amount += e.rate;
    });
    amount = amount * (studentData?.length || 0);
    const totalQty = bookDates?.length * (studentData?.length || 0);

    const applicableOffer: any = offersData?.find((e: any) => totalQty >= e.qty);

    if (applicableOffer) {
      if (applicableOffer?.discountType === 'Flat') {
        // discount = applicableOffer?.discount;
        discount = (bookDates?.length || 0) * applicableOffer?.discount;
        netAmount = amount - discount;
      } else {
        discount = parseFloat(((amount * applicableOffer?.discount) / 100)?.toFixed(2));
        netAmount = amount - discount;
      }
    }
    setPaymentData({ amount: amount, discount: discount, netAmount: netAmount });
  }, [bookDates, studentData]);

  const isDisableDate = (date: any) => {
    if (selectedCourse?.schedule?.length) {
      var isBlock = true;
      selectedCourse?.schedule?.forEach((schedule: any) => {
        if (!schedule?.blockDayNames?.includes(moment(date).format('dddd'))) {
          isBlock = false;
        }
      });
      if (isBlock) {
        return isBlock;
      }
    }
    const sheduleBooked = blockDates?.filter((e: any) => moment(e?.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'));
    if (sheduleBooked?.length === 0) {
      return false;
    }
    if (sheduleBooked?.length === selectedCourse?.schedule?.length) {
      return true;
    }
    return false;
  };

  const handleSelectDate = (date: any) => {
    setSelectedDate(date);
    setSelectedTimeSlot(null);
    const schedule = selectedCourse?.schedule?.filter((o: any) => {
      return (
        // moment(date, 'YYYY-MM-DD').isBetween(moment(o?.startDate, 'YYYY-MM-DD'), moment(o?.endDate, 'YYYY-MM-DD'), undefined, '[]') &&
        // !o?.blockDayNames?.includes(moment(date).format('dddd'))

        // ------------------------------------Probable fix------------------------------------
        moment(date, 'YYYY-MM-DD').isBetween(o?.startDate, `${o?.endDate.split('T')[0]}T00:00:00-0800`, undefined, '[]') &&
        !o?.blockDayNames?.includes(moment(date).format('dddd')) // removed moment formating and removed timezone from endDate
      );
    });
    const sortedSchedule = sortByTime(schedule);
    setScheduleList(sortedSchedule);
  };

  const handleSelectBatch = (item: any) => {
    setSelectedDate(item?.startDate);
    setSelectedTimeSlot(item);
    setBookDates([item]);
  };

  const selectBgColor = siteName?.includes(siteNames.site1) ? '#ffc400' : '#0136c2';
  const selectColor = siteName?.includes(siteNames.site1) ? 'white' : 'white';

  const getAutoCompleteLabels = (item: any) => {
    const condition = blockDates?.some(
      (e: any) =>
        moment(e?.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') &&
        item?._id === e?.scheduleId &&
        item?.instructor?.optionValue === e?.instructor
    );
    if (!condition) {
      return moment(item.startTime).format('h:mm A') + '-' + moment(item.endTime).format('h:mm A') + ' ' + item.instructor?.optionLabel;
    }
  };

  const renderOptions = (props: any, item: any) => {
    const condition = blockDates?.some(
      (e: any) =>
        moment(e?.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD') &&
        item?._id === e?.scheduleId &&
        item?.instructor?.optionValue === e?.instructor
    );
    if (!condition) {
      return (
        <Box
          {...props}
          component="li"
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '20px',
            width: '100%'
          }}
        >
          <Typography color={'inherit'} flexGrow={1}>
            {moment(item.startTime).format('h:mm A')} - {moment(item.endTime).format('h:mm A')}{' '}
          </Typography>
          <Typography variant="body1" component={'span'} textAlign="right" flexGrow={1}>
            {item.instructor?.optionLabel}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Container>
      <Box pt={{ xs: 2, sm: 3, md: 4 }} pb={3}>
        <h6>Select Your Class</h6>
        <Grid container pt={3} spacing={2} sx={{ justifyContent: { xs: 'center', md: 'unset' } }}>
          {coursesData?.map((item: any, key: any) => {
            return (
              <Grid item key={key}>
                <Box
                  onClick={() => {
                    handleSelectCourse(item);
                  }}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: item._id === selectedCourse?._id ? selectBgColor : 'white',
                    color: item._id === selectedCourse?._id && selectColor
                  }}
                  border={1}
                  borderColor="grey.300"
                  key={key}
                  p={1}
                >
                  {item.courseName}
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Student studentData={studentData} user={user} setStudentData={setStudentData} />
        {blockDates ? (
          <Fragment>
            {offersData?.length > 0 && <Offers offersData={offersData} currency={brand.currency} />}
            <Box pt={4}>
              {selectedCourse?.courseInfo?.length > 0 && (
                <Typography mb={2}>
                  <InfoIcon sx={{ verticalAlign: 'middle' }} /> <span>{selectedCourse?.courseInfo}</span>
                </Typography>
              )}
              <Grid container spacing={{ xs: 2, sm: 3, md: 3 }}>
                <Grid item xs={12} md={6}>
                  <span>{selectedCourse?.type === COURSE_TYPES.batch ? (selectedCourse?.schedule?.length ? 'Schedule' : null) : 'Select Date'}</span>
                  {selectedCourse?.type === COURSE_TYPES.batch ? (
                    selectedCourse?.schedule?.length ? (
                      selectedCourse?.schedule?.map((item: any, key: any) => {
                        return (
                          <Box
                            onClick={() => {
                              if (item.status) handleSelectBatch(item);
                            }}
                            sx={{
                              cursor: item.status && 'pointer',
                              backgroundColor: item === selectedTimeSlot ? selectBgColor : 'white',
                              color: !item.status ? '#b0b0b0' : item === selectedTimeSlot && selectColor,
                              userSelect: !item.status && 'none'
                            }}
                            border={1}
                            borderColor="grey.300"
                            key={key}
                            mt={2}
                            p={2}
                          >
                            {item?.label !== '' ? (
                              <span>
                                {item?.label}
                                {!item.status && (
                                  <Tooltip title={item.inactiveReason} TransitionComponent={Zoom} placement="right">
                                    <InfoOutlinedIcon
                                      sx={{ fontSize: '19px', cursor: 'pointer', color: '#6e6e6e', marginLeft: '5px', verticalAlign: 'middle' }}
                                    />
                                  </Tooltip>
                                )}
                                <Typography
                                  component={'span'}
                                  sx={{
                                    color: !item.status ? '#b0b0b0' : item === selectedTimeSlot ? selectColor : 'gray',
                                    fontSize: '12px',
                                    display: 'block'
                                  }}
                                >
                                  {item.instructor?.optionLabel}
                                </Typography>
                              </span>
                            ) : (
                              <span>
                                <span>{`${moment(item?.startDate).format('Do MMM YY')} - ${moment(item?.endDate).format('Do MMM YY')}`}</span>
                                <span>{`   ${moment(item.startTime).format('h:mm A')} - ${moment(item.endTime).format('h:mm A')}`} </span>
                              </span>
                            )}
                          </Box>
                        );
                      })
                    ) : (
                      <Box>
                        <Typography>
                          {alreadyWaitlist
                            ? 'You have already waitlisted for this class. You will receive an email as soon as the new schedule is created.'
                            : 'No schedule is available for this class. Still you can waitlist for this class.'}
                        </Typography>
                      </Box>
                    )
                  ) : null}
                  {selectedCourse?.type === COURSE_TYPES.individual || selectedCourse?.type === COURSE_TYPES.group ? (
                    <Box
                      sx={{
                        maxWidth: 'max-content',
                        margin: { xs: '0 auto', sm: 'unset' },
                        boxShadow: '21px 31px 52px rgba(0, 0, 0, 0.05)',
                        borderRadius: '36px',
                        overflow: 'hidden'
                      }}
                    >
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={selectedDate}
                        onChange={(newValue) => {
                          handleSelectDate(newValue);
                        }}
                        shouldDisableDate={isDisableDate}
                        minDate={calanderMinMax.min}
                        maxDate={calanderMinMax.max}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  {selectedDate && (
                    <Fragment>
                      {[COURSE_TYPES.individual, COURSE_TYPES.group].includes(selectedCourse?.type) &&
                        (scheduleList?.length > 0 ? (
                          <>
                            <span>Select Time Slot</span>
                            <Autocomplete
                              value={selectedTimeSlot}
                              onChange={(event: any, newValue: any | null) => {
                                setSelectedTimeSlot(newValue);
                              }}
                              id="combo-box-demo"
                              options={scheduleList}
                              sx={{ width: '100%', mt: 1 }}
                              renderInput={(params) => <TextField {...params} placeholder="Select Time Slot" />}
                              getOptionLabel={getAutoCompleteLabels}
                              renderOption={renderOptions}
                            />
                          </>
                        ) : (
                          <>
                            <span>Select Time Slot</span>
                            <p style={{ marginTop: '30px' }}>No Time Slot Available</p>
                          </>
                        ))}
                      {selectedTimeSlot && (
                        <Fragment>
                          <Box display="flex" alignItems={'center'} flexWrap="wrap" justifyContent={'space-between'}>
                            <Box pt={2}>
                              <span>Instructor</span>
                              <br></br>
                              <span style={{ fontWeight: 700 }}>{selectedTimeSlot?.instructor?.optionLabel}</span>
                            </Box>
                            <Box pt={2} textAlign="right">
                              <span>Rate</span>
                              <br></br>
                              <span style={{ fontWeight: 700 }}>{currencyConverter(selectedTimeSlot?.rate, brand?.currency)}</span>
                            </Box>
                          </Box>
                          {selectedTimeSlot?.information && selectedTimeSlot?.information !== '' && (
                            <Box pt={2}>
                              <span>Information</span>
                              <br></br>
                              <span>{selectedTimeSlot?.information}</span>
                            </Box>
                          )}
                        </Fragment>
                      )}
                      {selectedCourse &&
                      selectedDate &&
                      selectedTimeSlot &&
                      [COURSE_TYPES.individual, COURSE_TYPES.group].includes(selectedCourse?.type) ? (
                        <Box pt={2}>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: '#3A3A3A',
                              color: 'white',
                              '&:hover': { backgroundColor: 'hsl(0deg 0% 33%)' }
                            }}
                            size="small"
                            onClick={handleAddBookDates}
                          >
                            Add This Slot
                          </Button>
                        </Box>
                      ) : null}
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Box>
            {selectedCourse?.schedule?.length ? (
              <>
                <Box pt={{ xs: 2, sm: 3, md: 4 }} pb={2}>
                  <h6>Summary</h6>
                  <Box sx={{ minHeight: '20px' }}>
                    {bookDates?.map((item: any, key: any) => {
                      return (
                        <>
                          <Grid
                            container
                            key={key}
                            pt={1}
                            spacing={1}
                            alignItems="center"
                            position="relative"
                            mb={'15px'}
                            sx={{ paddingLeft: '30px', position: 'relative', paddingRight: { xs: '47px', sm: '' } }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                left: '8px',
                                top: '50%',
                                transform: 'translateY(-4px)'
                              }}
                            >
                              <Typography aria-label="Summery Count">{key + 1}.</Typography>
                            </Box>
                            <Grid item xs={12} sm={5}>
                              {selectedCourse?.type === COURSE_TYPES.batch && item?.information?.length
                                ? `${item?.information}, Starts from ${moment(item?.startDate).format('Do MMMM')}`
                                : `${moment(item?.startDate).format('ddd, Do MMM')}  ${moment(item.startTime).format('h:mm A')}`}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              {`${studentData?.length || 0} x ${currencyConverter(item?.rate, brand?.currency)}`}
                            </Grid>
                            <Grid item xs={6} sm={2} pl={{ xs: '24px !important', sm: '35px !important' }}>
                              {`${currencyConverter((studentData?.length || 0) * item?.rate, brand?.currency)}`}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={2}
                              sx={{
                                position: { xs: 'absolute', sm: 'unset' },
                                right: { xs: '5px', sm: 'unset' },
                                top: { xs: '50%', sm: 'unset' },
                                transform: { xs: 'translateY(-50%)', sm: 'unset' },
                                textAlign: 'right'
                              }}
                            >
                              <IconButton onClick={() => handleRemoveBookDate(key)} color="error" component="label">
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </Box>

                  <Divider />
                  <Grid container pt={1} spacing={2}>
                    <Grid item xs={6} sm={8}>
                      Total
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      {`${currencyConverter(paymentData?.amount, brand?.currency)}`}
                    </Grid>
                  </Grid>
                  {paymentData?.discount > 0 && (
                    <Fragment>
                      <Grid container pt={1} spacing={2}>
                        <Grid item xs={6} sm={8}>
                          Discount
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          {`${currencyConverter(paymentData?.discount, brand?.currency)}`}
                        </Grid>
                      </Grid>
                      <Grid container pt={1} spacing={2}>
                        <Grid item xs={6} sm={8}>
                          Amount
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          {`${currencyConverter(paymentData?.netAmount || 0, brand?.currency)}`}
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </Box>
                <Box pt={2} display="flex" justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleCreateSession}
                    disabled={!(bookDates?.length > 0 && studentData?.length > 0)}
                  >
                    Book Slot(s)
                  </Button>
                </Box>
              </>
            ) : (
              <Box pt={2} display="flex" justifyContent="center" alignItems="center">
                <Button variant="contained" size="large" onClick={handleWaitlist} disabled={!studentData?.length || alreadyWaitlist}>
                  Waitlist
                </Button>
              </Box>
            )}
          </Fragment>
        ) : (
          <Box height={500} bgcolor="white">
            <CommonSkeleton lenArray={[...Array(10).keys()]} />
          </Box>
        )}
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Container>
  );
};

export default Courses;
