export const routes: RoutesInterface = {
  home: {
    title: 'Home',
    path: '/',
    data: {},
    keywords: 'home',
    type: 'public'
  },
  login: {
    title: 'Login',
    path: '/login',
    data: {},
    keywords: 'login signin sign in',
    type: 'public'
  },
  register: {
    title: 'Register',
    path: '/register',
    data: {},
    keywords: 'register signup sign up',
    type: 'public'
  },
  resetPassword: {
    title: 'Reset Password',
    path: 'reset-password',
    keywords: null
  },
  forgetPassword: {
    title: 'Forget Password',
    path: '/forget-password',
    data: {},
    keywords: 'forget password forgot password',
    type: 'public'
  },
  gvResources: {
    title: 'GV Resources',
    path: '/gv-resources',
    data: {},
    keywords: 'gvresources gv resources resource resources',
    type: 'public'
  },
  volunteer: {
    title: 'Volunteer',
    path: '/volunteer',
    data: {},
    keywords: 'volunteer',
    type: 'public'
  },
  contactUs: {
    title: 'Contact Us',
    path: '/contact-us',
    data: {},
    keywords: 'contact contact-us contact us',
    type: 'public'
  },
  aboutUs: {
    title: 'About Us',
    path: '/about-us',
    data: {},
    keywords: 'about about us aboutus',
    type: 'public'
  },
  donation: {
    title: 'Donation',
    path: '/donation',
    api: '/donation',
    data: {},
    keywords: null,
    type: 'public'
  },
  courses: {
    title: 'Courses',
    path: '/courses',
    data: {},
    keywords: 'classes courses class',
    type: 'private'
  },
  session: {
    title: 'Session',
    path: '/session',
    api: '/sessions',
    data: {},
    keywords: null,
    type: 'public'
  },
  orders: {
    title: 'Orders',
    path: '/orders',
    data: {},
    keywords: 'booking booked enrolled enrol order orders',
    type: 'private'
  },
  students: {
    title: 'Students',
    path: '/students',
    api: '/students',
    data: {},
    keywords: 'students add student addstudent',
    type: 'private'
  },
  profile: {
    title: 'Profile',
    path: '/profile',
    data: {},
    keywords: 'profile my me user',
    type: 'private'
  },
  payOnline: {
    title: 'Pay',
    path: '/payonline',
    data: {},
    keywords: null,
    type: 'private'
  },
  classes: {
    title: 'Classes',
    path: '/classes',
    data: {},
    keywords: 'class all classes classes',
    type: 'public'
  },
  teachers: {
    title: 'Teachers',
    path: '/teachers',
    data: {},
    keywords: null,
    type: 'public'
  },
  myGv: {
    title: 'MY=GV',
    path: '/my-gv',
    data: {},
    keywords: 'my-gv gv my',
    type: 'private'
  },
  classDetails: {
    title: 'Class Details',
    path: '/classes',
    data: {},
    type: 'public'
  },
  programs: {
    title: 'Programs',
    path: '/programs',
    data: {}
  },

  // Sub pages of Programs
  gurmatCamps: {
    title: 'Gurmat Camps',
    path: '/programs/Gurmat-Camps',
    data: {},
    keywords: 'camps gurmat program programs',
    type: 'public'
  },
  sportsPrograms: {
    title: 'Sports Programs',
    path: '/programs/Sports-Programs',
    data: {},
    keywords: 'sport sports program programs',
    type: 'public'
  },
  wellnessPrograms: {
    title: 'Wellness Programs',
    path: '/programs/Wellness-Programs',
    data: {},
    keywords: 'well wellness program programs',
    type: 'public'
  },
  youthDarbars: {
    title: 'Youth Darbars',
    path: '/programs/Youth-Darbars',
    data: {},
    keywords: 'youth darbars youthdarbars programs',
    type: 'public'
  },
  fieldTrips: {
    title: 'Field Trips',
    path: '/programs/Field-Trips',
    data: {},
    keywords: 'field trips fieldtrip fieldtrips programs',
    type: 'public'
  },
  sangatPrograms: {
    title: 'Sangat Programs',
    path: '/programs/Sangat-Programs',
    data: {},
    keywords: 'sangat program programs sangat programs',
    type: 'public'
  },

  // Sub pages of gvResources
  publicCalendar: {
    title: 'Public Calendar',
    path: `gv-resources/public-calendar`,
    data: {},
    keywords: 'public calendar public calendar',
    type: 'public'
  },
  pictures: {
    title: 'Pictures',
    path: `gv-resources/pictures`,
    data: {},
    keywords: 'pictur pictures photos photo',
    type: 'public'
  },
  sikhiResources: {
    title: 'Sikhi Resources',
    path: `gv-resources/sikhi-resources`,
    data: {},
    keywords: 'sikhi resources sikhi resources',
    type: 'public'
  },
  akaaliStore: {
    title: 'Akaali Store',
    path: `gv-resources/akaali-store`,
    data: {},
    keywords: 'akaali store akaali store sangat programs',
    type: 'public'
  },
  googleClassroom: {
    title: 'Sangat Programs',
    path: `gv-resources/public-calendar`,
    data: {},
    keywords: 'sangat program programs',
    type: 'public'
  }
};

export interface RoutesInterface {
  [key: string]: links;
}

export interface links {
  title?: string;
  path?: string;
  data?: Data;
  keywords?: string[] | string | null;
  api?: string;
  type?: 'public' | 'private';
}
export interface Data {}
