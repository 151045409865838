import React from 'react';
import styles from './index.module.scss';
import { buttonType, buttonStyle } from 'src/components/Buttons';
import { useNavigate } from 'react-router-dom';

interface siteHeroInterface {
  sectionData: dataInterface;
}
interface dataInterface {
  heroImage: String;
  heading: String;
  subHeading: String;
  ctaText: String;
  ctaLink: String;
}
const SiteHero = (props: siteHeroInterface) => {
  const navigate = useNavigate();
  const Button = buttonStyle(buttonType.primary);
  const { sectionData } = props;
  const bgImage = { '--bg-image': `url(${sectionData.heroImage})` } as React.CSSProperties;

  return (
    <div className={`${styles.main}`} style={{ ...bgImage }}>
      <div className={` container ${styles.container}`}>
        <div>
          <h1 className="fw-4 lh-sm mb-2" style={{ maxWidth: 850 }}>
            {sectionData.heading}
          </h1>
          <p className="mb-4 fw-4 fs-6" style={{ marginLeft: 7 }}>
            {sectionData.subHeading}
          </p>
          <Button onClick={() => navigate(`${sectionData.ctaLink}`)}>{sectionData.ctaText}</Button>
        </div>
      </div>
    </div>
  );
};

export default SiteHero;
