import { Button, CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/helpers/routes';
import moment from 'moment-timezone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { loadStripe } from '@stripe/stripe-js';
import { PAYMENT_TYPES, sessionLocalStorageKey } from 'src/helpers/helper';
import { currencyConverter } from 'src/helpers/currency';
import { useData } from 'src/context/Provider';

var stripePromise: any = null;

const Session = () => {
  const {
    state: { brand }
  }: any = useData();

  const toastConfig = React.useContext(CustomToastContext);
  const { id } = useParams();
  const [sessionData, setSessionData] = React.useState(null);
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = React.useState(null);
  const [policy, setPolicy] = React.useState(null);
  const [charge, setCharge] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    return () => {
      axiosInstance()
        .put(`${routes.session.api}/remove/${id}`)
        .then(({ data: data }) => {})
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    };
  }, []);

  useEffect(() => {
    if (policy?.paymentGatewayCharge && paymentType === PAYMENT_TYPES.online) {
      setCharge(parseFloat(((sessionData?.amount * policy?.paymentGatewayCharge) / 100).toFixed(2)));
    } else {
      setCharge(0);
    }
  }, [paymentType]);

  const fetchData = () => {
    axiosInstance()
      .get(`/sessions/${id}`)
      .then(({ data: { data } }) => {
        setSessionData(data?.data);
        setPolicy(data?.policy);
        if (data?.policy?.isOnline && data?.policy?.isOffline) {
          setPaymentType(PAYMENT_TYPES.online);
        } else if (data?.policy?.isOnline) {
          setPaymentType(PAYMENT_TYPES.online);
        } else if (data?.policy?.isOffline) {
          setPaymentType(PAYMENT_TYPES.offline);
        }
        if (data?.policy?.isOnline) {
          stripePromise = loadStripe(data?.policy?.apiKey);
        } else {
          stripePromise = 'demo';
        }
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handlePlaceOrder = () => {
    setLoading(true);
    localStorage.removeItem(sessionLocalStorageKey);
    if (paymentType === PAYMENT_TYPES.online) {
      axiosInstance()
        .post('/payment/initiate-payment', { sessionId: id })
        .then(async ({ data: { data } }) => {
          const stripe = await stripePromise;
          await stripe
            .redirectToCheckout({ sessionId: data })
            .then(() => {
              toastConfig.setToastConfig({
                open: true,
                type: 'success',
                message: 'Order placed successfully'
              });
              setLoading(false);
            })
            .catch((error: any) => {});
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const data: any = {};
      data.sessionId = id;
      data.paymentType = paymentType;
      axiosInstance()
        .post('/orders', data)
        .then(({ data: data }) => {
          navigate(`${routes.orders.path}/${data?.data?.orderId}`, {
            replace: true
          });
          setLoading(false);
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
          setLoading(false);
        });
    }
  };

  return (
    <Box className="container">
      {sessionData && stripePromise && (
        <Box pt={2}>
          <h6>{sessionData?.course?.courseName}</h6>
          <Box pt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <span>Dates</span>
                <Box pt={1}>
                  {sessionData?.bookDates?.map((item: any, key: any) => {
                    return (
                      <Box border={1} borderColor="grey.300" key={key} mb={2} p={2}>
                        <span>{`${moment(item.date).format('dddd, Do MMM')} ${moment(item.time).format('h:mm A')}`}</span>
                        <br></br>
                        <span>Instructor : {item?.instructor?.optionLabel}</span>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <span>Students</span>
                <Box pt={1}>
                  {sessionData?.students?.map((item: any, key: any) => {
                    return (
                      <Box border={1} borderColor="grey.300" key={key} mb={2} p={2}>
                        <span>{item?.firstName + ' ' + item.lastName}</span>
                        <br />
                        <span>{item?.phone}</span>
                        <br />
                        <span>{item?.email}</span>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box pt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Payment Mode</FormLabel>
                  {policy?.isOnline || policy?.isOffline ? (
                    <RadioGroup
                      value={paymentType}
                      onChange={(e: any) => setPaymentType(e.target.value)}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      {policy?.isOnline && <FormControlLabel value={PAYMENT_TYPES.online} control={<Radio />} label={PAYMENT_TYPES.online} />}
                      {policy?.isOffline && <FormControlLabel value={PAYMENT_TYPES.inPerson} control={<Radio />} label={PAYMENT_TYPES.inPerson} />}
                      {policy?.isOffline && <FormControlLabel value={PAYMENT_TYPES.eTransfar} control={<Radio />} label={PAYMENT_TYPES.eTransfar} />}
                    </RadioGroup>
                  ) : (
                    <span>No Payment mode found!</span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box border={1} borderColor="grey.300" p={2}>
                  {sessionData?.discount ? (
                    <>
                      <span>Total : {currencyConverter(sessionData?.total, brand?.currency || 'USD')}</span>
                      <br />
                      <br />
                      <span>Discount : {currencyConverter(sessionData?.discount, brand?.currency || 'USD')}</span>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <span>Amount : {currencyConverter(sessionData?.amount, brand?.currency || 'USD')}</span>
                  <br />
                  <br />
                  {charge ? (
                    <>
                      <span>Processing Fee : {currencyConverter(charge, brand?.currency || 'USD')}</span>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <span>Amount Payble : {currencyConverter(sessionData?.amount + charge, brand?.currency || 'USD')}</span>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box pt={3} display="flex" justifyContent="center" alignItems="center">
            <Button
              disabled={!paymentType || loading}
              variant="contained"
              size="large"
              onClick={handlePlaceOrder}
              endIcon={loading && <CircularProgress size={20} color="inherit" />}
            >
              Place Order
            </Button>
          </Box>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Box>
      )}
    </Box>
  );
};

export default Session;
