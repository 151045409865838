import React, { ReactNode } from 'react';
import { withStyles } from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';

interface Props {
  children?: ReactNode
}

function CustomDialogContent({ children }: Props) {
  return (
    <React.Fragment>
      <DialogContent sx={{ padding: 3 }}>
        <Box sx={{ paddingTop: 3 }}>
          {children}
        </Box>
      </DialogContent>
    </React.Fragment>
  );
}

CustomDialogContent.propTypes = {
  children: PropTypes.any
};

export default CustomDialogContent;
