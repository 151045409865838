import contactInfoHero from 'src/components/Site/assets/images/ContactInfoHero.png';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsTelephone } from 'react-icons/bs';
import { nanoid } from 'nanoid';

export const mapAndFormData = {
  formHeading: 'Contact us',
  text: 'Our friendly team would love to hear from you.',
  submitButtonText: 'Send Message',
  iframeSrc: `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2505.5455887566004!2d-113.9802785!3d51.0983932!3
  m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5371654b26a20413%3A0xaa47069fe9ebef0f!2sGurprasad%20Vidyala!5e0!3m2!1sen!2sin!4v1671612196949!5m2!1sen!2sin`
};

export const contactDetailsData = {
  headline: 'Contact us',
  heading: 'We’d love to hear from you',
  text: 'Chat to our friendly team.',
  image: contactInfoHero,
  cards: [
    {
      id: nanoid(),
      icon: IoChatbubblesOutline,
      heading: 'Chat to our Team',
      description: 'Speak to our friendly team.',
      linkText: 'info@guruprasadvidyala.com',
      link: 'mailto:info@guruprasadvidyala.com'
    },
    {
      id: nanoid(),
      icon: HiOutlineLocationMarker,
      heading: 'Visit us',
      description: 'Visit our office HQ.',
      linkText: 'Unit #4166, 3406 52 Ave NE Calgary, AB T3G 4M9',
      link: 'https://goo.gl/maps/g1CKPNG5p7pC35Ug8'
    },
    {
      id: nanoid(),
      icon: BsTelephone,
      heading: 'Call us',
      description: 'Mon-Fri from 8am to 5pm.',
      linkText: '+1 (403) 421-2000',
      link: 'tel:+14034212000'
    }
  ]
};
