import { createContext, useContext, useEffect, useReducer } from "react";
import { axiosInstance } from "src/axios";
import { SET_USER, SET_BRAND } from "./ActionTypes";
import reducer, { initialState } from "./Reducer";

const StateContext = createContext(null);

export const Provider = ({ children }: any) => {

  const token = localStorage.getItem("token");
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (token && navigator.onLine) {
      axiosInstance().get("auth/me").then(({ data: { data } }) => {
        dispatch({ type: SET_USER, payload: data?.user });
        dispatch({ type: SET_BRAND, payload: data?.brand });
      })
        .catch((err) => {
          localStorage.removeItem("token");
        });
    }
  }, [token]);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useData = () => useContext(StateContext);
