import React from 'react';
import { siteName, siteNames } from 'src/config';
import styles from './index.module.scss';
import site1Logo from 'src/components/Site1/assets/Logo.png';
import siteLogo from 'src/components/Site/assets/logo/Logo.svg';
import logo2 from 'src/components/Site1/assets/Logo2.png';
import { YellowButton, SecondaryButton } from '../Buttons';
import { Formik, Form } from 'formik';
import { Box, TextField, CircularProgress } from '@mui/material';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { axiosInstance } from 'src/axios';
import MuiPhoneInput from 'material-ui-phone-number';

const buttonType = () => {
  if (siteName?.includes(siteNames.site1)) {
    return YellowButton;
  } else if (siteName?.includes(siteNames.site2)) {
    return SecondaryButton;
  } else {
    return SecondaryButton;
  }
};

const Button = buttonType();

const logo = () => {
  if (siteName?.includes(siteNames.site1)) {
    return site1Logo;
  } else if (siteName?.includes(siteNames.site2)) {
    return siteLogo;
  } else {
    return siteLogo;
  }
};

interface enquiryData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  inquiry: string;
}

const ContactForm = () => {
  const toastConfig = React.useContext(CustomToastContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleSubmit = async (values: enquiryData, resetForm: any) => {
    setIsLoading(true);
    axiosInstance()
      .post('/contactus', {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        inquiry: values.phone
      })
      .then(({ data: { data } }) => {
        toastConfig.setToastConfig({
          open: true,
          message: 'Submitted Successfully',
          type: 'info'
        });
        resetForm({ values: '' });
        setIsLoading(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setIsLoading(false);
      });
  };
  const validateForm = (values: enquiryData) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else {
      let simpleStr = values?.phone.replaceAll(' ', '');
      simpleStr = simpleStr.replaceAll('(', '');
      simpleStr = simpleStr.replaceAll(')', '');
      simpleStr = simpleStr.replaceAll('-', '');
      const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

      if (!isValid) {
        errors.phone = 'Phone number is not valid';
      }
    }
    if (!values.inquiry) {
      errors.inquiry = 'Inquiry is required';
    }
    return errors;
  };

  return (
    <div className="container2">
      <div className={styles.grid}>
        <div className={styles.leftContent}>
          <div className={styles.logo}>
            <img src={logo()} alt="" loading="lazy" style={{ maxWidth: 'max-content', margin: 'auto' }} />
          </div>
          <h2>SEND US MESSAGE</h2>
          <h6 className={styles.text}>We are planning 2022-2023 classes to be held in person on Sundays starting 28th of August 2022</h6>
          {siteName?.includes(siteNames.site1) && (
            <div className={styles.logo2}>
              <img src={logo2} alt="" loading="lazy" />
            </div>
          )}
          <h6 className={styles.highlightedText}>RESERVE YOUR TIME NOW BEFORE SPACES FILL UP!</h6>
        </div>
        <div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              inquiry: ''
            }}
            validate={validateForm}
            onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
          >
            {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
              <Form className={styles.formContainer}>
                <div className={styles.row}>
                  <TextField
                    fullWidth
                    data-testid="firstName"
                    variant="outlined"
                    type="text"
                    required
                    placeholder="First Name"
                    label="First Name"
                    name="firstName"
                    size="small"
                    onChange={(e) => setFieldValue('firstName', e.target.value)}
                    value={values['firstName']}
                    error={touched['firstName'] && Boolean(errors['firstName'])}
                    helperText={touched['firstName'] && errors['firstName']}
                  />
                  <TextField
                    fullWidth
                    data-testid="lastName"
                    variant="outlined"
                    type="text"
                    required
                    placeholder="Last Name"
                    label="Last Name"
                    name="lastName"
                    size="small"
                    onChange={(e) => setFieldValue('lastName', e.target.value)}
                    value={values['lastName']}
                    error={touched['lastName'] && Boolean(errors['lastName'])}
                    helperText={touched['lastName'] && errors['lastName']}
                  />
                </div>
                <div className={styles.row}>
                  <TextField
                    fullWidth
                    data-testid="email"
                    variant="outlined"
                    type="email"
                    required
                    label="Email"
                    placeholder="Email"
                    name="email"
                    size="small"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    value={values['email']}
                    error={touched['email'] && Boolean(errors['email'])}
                    helperText={touched['email'] && errors['email']}
                  />
                  <MuiPhoneInput
                    defaultCountry={'us'}
                    disableAreaCodes
                    countryCodeEditable={true}
                    enableLongNumbers={false}
                    fullWidth
                    variant="outlined"
                    type="phone"
                    required
                    label="Phone"
                    name="phone"
                    size="small"
                    onChange={(val) => setFieldValue('phone', val.toString())}
                    value={values['phone']}
                    error={touched['phone'] && Boolean(errors['phone'])}
                    helperText={touched['phone'] && errors['phone']}
                  />
                </div>

                <div className={styles.row}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    data-testid="inquiry"
                    variant="outlined"
                    type="inquiry"
                    required
                    label="Inquiry"
                    placeholder="Inquiry..."
                    name="inquiry"
                    size="small"
                    onChange={(e) => setFieldValue('inquiry', e.target.value)}
                    value={values['inquiry']}
                    error={touched['inquiry'] && Boolean(errors['inquiry'])}
                    helperText={touched['inquiry'] && errors['inquiry']}
                  />
                </div>
                <Box mt={2}>
                  <Button fullWidth type="submit" disabled={isLoading} endIcon={isLoading ? <CircularProgress size={20} color={'inherit'} /> : <></>}>
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
