import React, { useState, useEffect } from 'react';
import { Button, Tabs, Tab, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import styles from './searchInput.module.scss';
import { HeaderLinkChildInterface } from 'src/components/Site/interface';
import { routes } from 'src/helpers/routes';
import { useData } from 'src/context/Provider';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseIcon from '@mui/icons-material/Close';

const SearchInput = ({ classData, isTopHeaderPresent = false }: { classData: HeaderLinkChildInterface[]; isTopHeaderPresent?: boolean }) => {
  const {
    state: { user }
  }: any = useData();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [className, setClassName] = React.useState('');

  const [pages, setPages] = useState([]);

  const [tabvalue, setTabValue] = React.useState(0);
  const [classResult, setClassResult] = useState([]);
  const [pageResult, setPageResult] = useState([]);

  const [isSpaceAvailable, setIsSpaceAvailable] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const pages = [];
    for (let route in routes) {
      const page = { name: route, ...routes[route] };
      pages.push(page);
    }
    setPages(pages);
  }, []);

  React.useEffect(() => {
    const element = containerRef.current;

    const handleClick = (event: any) => {
      const targetEl = event.target;
      if (element.contains(targetEl)) {
        setIsSpaceAvailable(true);
        inputRef.current?.focus();
        setClassName('flyout_search');
      } else {
        setIsSpaceAvailable(false);
        setClassName('');
        setInputValue('');
        setClassResult([]);
        setPageResult([]);
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const searchFunction = (e: any) => {
    setInputValue(e.target.value);
    const searchVal = e.target.value.toLowerCase().trim();

    if (searchVal === '') {
      setClassResult([]);
      setPageResult([]);
      return;
    }
    const pageFound = pages.filter((page) => {
      if (user) {
        return page.keywords?.includes(searchVal);
      }
      return page.keywords?.includes(searchVal) && page.type === 'public';
    });

    const classFound = classData.filter((classes) => classes.linkText.toLowerCase().includes(searchVal));
    setPageResult(pageFound);
    setClassResult(classFound);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className={`header_search input_container`}>
      <div ref={containerRef} className={`${className} search_container ${isTopHeaderPresent ? 'fixed-topheader-present' : ''}`}>
        <input
          ref={inputRef}
          type={'text'}
          value={inputValue}
          placeholder="Search anything"
          className={`${className} ${(pageResult.length > 0 || classResult.length) && 'result-available'}`}
          onChange={searchFunction}
        />
        <Button className={`${className} search-button`}>
          {inputValue === '' ? <SearchIcon /> : <CloseIcon onClick={() => setInputValue('')} />}
        </Button>
        {isSpaceAvailable && (pageResult.length > 0 || classResult.length) > 0 && (
          <div className={styles.searchResultContainer}>
            <div className={styles.searchResultContent}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginInline: 3 }}>
                <Tabs value={tabvalue} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    label={
                      <span>
                        <span>All</span>
                        <span className={styles.length}>{pageResult.length + classResult.length}</span>
                      </span>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <span>
                        <span>Classes</span>
                        <span className={styles.length}>{classResult.length}</span>
                      </span>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <span>
                        <span>Pages</span>
                        <span className={styles.length}>{pageResult.length}</span>
                      </span>
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <div className={styles.searchResultDataContainer}>
                <TabPanel value={tabvalue} index={0}>
                  <RenderClassResult classResultData={classResult} />
                  <RenderPageResult pageResultData={pageResult} />
                </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                  <RenderClassResult classResultData={classResult} />
                  {classResult.length == 0 && <Typography sx={{ textAlign: 'center', p: 3, pt: 4 }}>No result found</Typography>}
                </TabPanel>
                <TabPanel value={tabvalue} index={2}>
                  <RenderPageResult pageResultData={pageResult} />
                  {pageResult.length == 0 && <Typography sx={{ textAlign: 'center', p: 3, pt: 4 }}>No result found</Typography>}
                </TabPanel>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const RenderClassResult = ({ classResultData }: { classResultData: HeaderLinkChildInterface[] }) => {
  return (
    <>
      {classResultData.map((item) => (
        <Link to={`${item.link}`} key={item._id} className={`${styles.result} ${styles.classResult}`}>
          <div className={styles.icon}>
            <img src={item.dynamicIcon || item.linkIcon} alt={`${item.linkText} icon`} aria-hidden />
          </div>
          <div className={styles.text}>
            <Typography variant="h6">{item.linkText}</Typography>
            <Typography>{item.linkTitle}</Typography>
          </div>
        </Link>
      ))}
    </>
  );
};

const RenderPageResult = ({ pageResultData }: { pageResultData: any }) => {
  return (
    <>
      {pageResultData.map((item: any, key: any) => (
        <Link to={item.path} key={key} className={`${styles.result} ${styles.classResult}`}>
          <div className={styles.icon}>
            <DescriptionOutlinedIcon />
          </div>
          <div className={styles.text}>
            <Typography variant="h6">{item.title}</Typography>
          </div>
        </Link>
      ))}
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3, pt: '3px' }}>{children}</Box>}
    </div>
  );
};
export default SearchInput;
