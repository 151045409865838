import React from 'react';
import styles from './index.module.scss';

interface inputPropsType {
  teamData: typeData;
}

interface typeData {
  headline?: string;
  heading?: string;
  text?: string;
  cards?: Card[];
}

interface Card {
  id: string;
  title?: string;
  description?: string;
  icon?: any;
}

const TeamSection = ({ teamData }: inputPropsType) => {
  return (
    <section className="container2">
      <div className={styles.main}>
        <div className={styles.sectionHeading}>
          <h6 className={`${styles.headline} inter`}>{teamData.headline}</h6>
          <h3 className={`${styles.heading} inter`}>{teamData.heading}</h3>
          <p className={`${styles.headText} inter`}>{teamData.text}</p>
        </div>
        <div className={styles.cardGrid}>
          {teamData.cards.map((card, key) => {
            const Icon = card.icon;
            return (
              <div key={card.id} className={styles.singleCard}>
                <div className={styles.iconContainer}>
                  <Icon />
                </div>
                <h6 className={`${styles.cardTitle} inter`}>{card.title}</h6>
                <p className={`${styles.cardDesc} inter`}>{card.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
