import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Link as MuiLink,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  Tooltip,
  Autocomplete
} from '@mui/material';
import { siteName, siteNames } from 'src/config';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MuiPhoneInput from 'material-ui-phone-number';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { PrimaryButton, YellowButton } from 'src/components/Buttons';
import { routes } from 'src/helpers/routes';
import { useData } from '../../context/Provider';
import { SET_USER, SET_BRAND } from 'src/context/ActionTypes';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';
import { AnimationContainer } from 'src/components/Animation';
import styles from './styles.module.scss';
import { FiArrowLeftCircle } from 'react-icons/fi';
import moment from 'moment';
import AddStudent from 'src/pages/Students';
import EmergencyContact from './EmergencyContact';
import { Country, State, City } from 'country-state-city';
import Recaptcha from 'react-recaptcha';

import site1Logo from 'src/components/Site1/assets/Logo.png';
import siteLogo from 'src/components/Site/assets/logo/Logo.jpg';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Zoom from '@mui/material/Zoom';

const logo = () => {
  if (siteName?.includes(siteNames.site1)) {
    return site1Logo;
  } else if (siteName?.includes(siteNames.site2)) {
    return siteLogo;
  } else {
    return siteLogo;
  }
};

interface RegisterData {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  phone: string;
  gender: string;
  role: string;
  address: string;
  city: string | any;
  zipCode: string;
  state: string | any;
  country: string | any;
  middleName: string;
  healthCareNumber: string;
  allergies: string;
  dateOfBirth: string;
}

const Register = ({ className = '' }: { className?: string }) => {
  const [role, setRole] = useState<'Student' | 'Parent'>('Student');
  const { dispatch }: any = useData();
  const navigate = useNavigate();
  const toastConfig = useContext(CustomToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [profileData, setProfileData] = useState(null);
  const [studentLength, setStudentLength] = useState(0);

  const handleSubmit = async (values: RegisterData) => {
    const payLoad = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      gender: values.gender,
      role: values.role,
      password: values.password,
      address: values.address,
      city: values.city?.label || '',
      zipCode: values.zipCode,
      state: values.state?.label || '',
      country: values.country?.label || '',
      middleName: values.middleName,
      healthCareNumber: +values.healthCareNumber,
      allergies: values.allergies.split(/[, \n]+/).filter(Boolean),
      dateOfBirth: values.dateOfBirth
    };
    setProfileData(payLoad);
    setIsLoading(true);
    axiosInstance()
      .post('/auth/register', payLoad)
      .then(({ data: { data } }) => {
        toastConfig.setToastConfig({
          open: true,
          message: 'Registered Successfully',
          type: 'success'
        });
        setIsLoading(false);
        localStorage.setItem('token', data?.token);
        dispatch({ type: SET_USER, payload: data?.user });
        dispatch({ type: SET_BRAND, payload: data?.brand });
        if (siteName?.includes(siteNames.site1)) {
          if (data?.user?.role === 'Parent') {
            navigate(routes.students.path, { replace: true });
          } else {
            navigate(routes.courses.path, { replace: true });
          }
        }
        setStep(3);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setIsLoading(false);
      });
  };

  const validateForm = (values: RegisterData) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (siteName?.includes(siteNames.site2) && values.email !== values.confirmEmail) {
      errors.confirmEmail = 'Email must match';
    }

    if (!values.phone && (siteName?.includes(siteNames.site1) || role === 'Parent')) {
      errors.phone = 'Phone number is required';
    } else if (siteName?.includes(siteNames.site1) || role === 'Parent') {
      let simpleStr = values?.phone.replaceAll(' ', '');
      simpleStr = simpleStr.replaceAll('(', '');
      simpleStr = simpleStr.replaceAll(')', '');
      simpleStr = simpleStr.replaceAll('-', '');
      const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

      if (!isValid) {
        errors.phone = 'Phone number is not valid';
      }
    }
    if (!values.role) {
      errors.role = 'Role is required';
    }
    if (values.password && !/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(values.password)) {
      errors.password = `Minimum eight characters, at least one uppercase, one lowercase, one number and one special character`;
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password must match';
    }
    if (!values.healthCareNumber && siteName?.includes(siteNames.site2)) {
      if (values.role === 'Student') errors.healthCareNumber = 'Health Care Number is required';
    } else if (siteName?.includes(siteNames.site2) && values.role === 'Student') {
      let str = values?.healthCareNumber;
      str = str.replaceAll(' ', '');
      str = str.replaceAll('-', '');
      str = str.replaceAll('_', '');
      const valid = /^\d+$/.test(str);
      const numberLength = str.length;
      if (numberLength !== 9) errors.healthCareNumber = 'Only 9 characters allowed';
      if (!valid) errors.healthCareNumber = 'Health Care Number can only contain numbers';
    }

    if (!values.dateOfBirth && siteName?.includes(siteNames.site2)) {
      errors.dateOfBirth = 'Date of birth is required';
    } else if (values.role === 'Student' && siteName?.includes(siteNames.site2)) {
      const dob = moment(values.dateOfBirth);
      const today = moment(new Date());
      const diff = today.diff(dob, 'years');
      if (diff < 10) errors.dateOfBirth = 'Have to be 10+ to have student account.';
    } else if (values.role === 'Parent' && siteName?.includes(siteNames.site2)) {
      const dob = moment(values.dateOfBirth);
      const today = moment(new Date());
      const diff = today.diff(dob, 'years');
      if (diff < 18) errors.dateOfBirth = 'Have to be 18+ to register as a parent.';
    }

    if (siteName?.includes(siteNames.site2)) {
      // if (!values.country) {
      //   errors.country = 'Country is required';
      // }
      if (!values.state) {
        errors.state = 'Province is required';
      }
      if (!values.city) {
        errors.city = 'City is required';
      }
      if (!values.zipCode) {
        errors.zipCode = 'Postal Code is required';
      }
      if (!values.address) {
        errors.address = 'Address is required';
      }
    }

    if (siteName?.includes(siteNames.site2)) {
      if (!values.country) {
        errors.country = 'Country is required';
      }
      if (!values.state) {
        errors.state = 'Province is required';
      }
      if (!values.city) {
        errors.city = 'City is required';
      }
      if (!values.zipCode) {
        errors.zipCode = 'Postal Code is required';
      }
      if (!values.address) {
        errors.address = 'Address is required';
      }
    }
    return errors;
  };
  const fetchStudent = async () => {
    await axiosInstance()
      .get(routes.students.api)
      .then(({ data: { data } }) => {
        setStudentLength(data.length);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  React.useLayoutEffect(() => {
    if (step === 3) fetchStudent();
  });

  return (
    <AnimationContainer direction="fade" className={`${siteName?.includes(siteNames.site2) ? styles.fullscreen_login : 'container2 '}`}>
      {siteName?.includes(siteNames.site2) && (
        <div className={`container `} style={step > 2 ? { display: 'none' } : {}}>
          <Button
            className={styles.backButton}
            sx={{ color: 'white', textTransform: 'capitalize' }}
            onClick={() => navigate('/')}
            startIcon={<FiArrowLeftCircle />}
            disabled={step > 2}
          >
            Return
          </Button>
        </div>
      )}
      <Box
        className={`${styles.container} ${className}`}
        style={{ minHeight: siteName?.includes(siteNames.site2) ? 'calc(100vh - 123px)' : '705px' }}
      >
        <Box className={`${siteName?.includes(siteNames.site2) ? styles.login2 : styles.loginContainer}`}>
          <img
            src={logo()}
            alt=""
            style={{
              maxWidth: '161px',
              margin: '0px auto 15px',
              maxHeight: '80px',
              objectFit: 'contain'
            }}
          />

          {siteName?.includes(siteNames.site1) && (
            <Typography className={styles.logoText} mb={1}>
              Register
            </Typography>
          )}
          <Box>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                phone: '',
                gender: 'Male',
                role: 'Student',
                address: '',
                city: '',
                zipCode: '',
                state: '',
                country: { label: 'Canada', isoCode: 'CA' },
                middleName: '',
                healthCareNumber: '',
                allergies: '',
                dateOfBirth: ''
              }}
              validate={validateForm}
              onSubmit={handleSubmit}
            >
              {({ submitForm, values, errors, touched, setFieldValue, isSubmitting, validateField }) => (
                <Form>
                  <Box pt={3}>
                    <Grid container spacing={2}>
                      {siteName?.includes(siteNames.site1) ? (
                        <RenderSite1Fields
                          isLoading={isLoading}
                          submitForm={submitForm}
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          isSubmitting={isSubmitting}
                          role={role}
                          setRole={setRole}
                        />
                      ) : (
                        <RenderSite2Field
                          isLoading={isLoading}
                          submitForm={submitForm}
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          isSubmitting={isSubmitting}
                          step={step}
                          setStep={setStep}
                          role={role}
                          setRole={setRole}
                          profileData={profileData}
                          validateField={validateField}
                        />
                      )}
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
            {(step === 3 || step === 4) && (
              <>
                {role === 'Parent' && step === 3 ? (
                  <>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <AddStudent />
                      </Grid>
                      <AnimationContainer direction="fade" style={{ width: '100%' }}>
                        <Grid item xs={12} sm={12}>
                          <StepButton skip={studentLength === 0} setStep={setStep} />
                        </Grid>
                      </AnimationContainer>
                    </Grid>
                  </>
                ) : (
                  <EmergencyContact step={step} setStep={setStep} profileData={profileData} />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {siteName?.includes(siteNames.site2) && (
        <>
          <footer>
            <div className="container">
              <p className="fw-3 lh-1">Copyright @ 2022 Mai Bhago Charity. All Rights Reserved</p>
              <div>
                <Link to="#" className="fw-3 lh-1">
                  Terms of Use
                </Link>{' '}
                |{' '}
                <Link to="#" className="fw-3 lh-1">
                  Privacy Policy
                </Link>{' '}
                |{' '}
                <Link to="#" className="fw-3 lh-1">
                  Land Acknowledgment
                </Link>
              </div>
            </div>
          </footer>
        </>
      )}
    </AnimationContainer>
  );
};

export default Register;

const StepButton = ({ setStep, skip = false, disabled = false }: { setStep: any; skip?: boolean; disabled?: boolean }) => {
  return (
    <Grid item xs={12}>
      <PrimaryButton
        disabled={disabled}
        fullWidth
        variant="rounded"
        onClick={() => setStep((prev: number) => (prev !== 4 ? prev + 1 : prev))}
        type="submit"
      >
        {skip ? 'Skip' : 'Continue'}
      </PrimaryButton>
    </Grid>
  );
};

// RENDER FORM FOR SITE 1
const RenderSite1Fields = (props: any) => {
  const { submitForm, values, errors, touched, setFieldValue, isLoading, role, setRole } = props;

  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          data-testid="firstName"
          variant="outlined"
          type="text"
          required
          placeholder="First Name"
          label="First Name"
          name="firstName"
          size="small"
          onChange={(e) => setFieldValue('firstName', e.target.value)}
          value={values['firstName']}
          error={touched['firstName'] && Boolean(errors['firstName'])}
          helperText={touched['firstName'] && errors['firstName']}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          data-testid="lastName"
          variant="outlined"
          type="text"
          required
          placeholder="Last Name"
          label="Last Name"
          name="lastName"
          size="small"
          onChange={(e) => setFieldValue('lastName', e.target.value)}
          value={values['lastName']}
          error={touched['lastName'] && Boolean(errors['lastName'])}
          helperText={touched['lastName'] && errors['lastName']}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          data-testid="email"
          variant="outlined"
          type="email"
          required
          label="Email"
          placeholder="Email"
          name="email"
          size="small"
          onChange={(e) => setFieldValue('email', e.target.value)}
          value={values['email']}
          error={touched['email'] && Boolean(errors['email'])}
          helperText={touched['email'] && errors['email']}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          data-testid="password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          size="small"
          placeholder="Password"
          required
          label="Password"
          name="password"
          value={values['password']}
          error={touched['password'] && Boolean(errors['password'])}
          onChange={(e) => setFieldValue('password', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          helperText={touched['password'] && errors['password']}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          data-testid="confirmPassword"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          size="small"
          placeholder="Confirm Password"
          required
          label="Confirm Password"
          name="confirmPassword"
          value={values['confirmPassword']}
          error={touched['confirmPassword'] && Boolean(errors['confirmPassword'])}
          onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          helperText={touched['confirmPassword'] && errors['confirmPassword']}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPhoneInput
          defaultCountry={'us'}
          disableAreaCodes
          countryCodeEditable={true}
          enableLongNumbers={false}
          fullWidth
          variant="outlined"
          type="phone"
          required={siteName?.includes(siteNames.site1)}
          dropdownClass="phone_dropdown"
          label="Phone"
          name="phone"
          size="small"
          onChange={(val) => setFieldValue('phone', val.toString())}
          value={values['phone']}
          error={touched['phone'] && Boolean(errors['phone'])}
          helperText={touched['phone'] && errors['phone']}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          row
          aria-label="gender"
          name="row-radio-buttons-group"
          value={values.gender}
          onChange={(e) => setFieldValue('gender', e.target.value)}
        >
          <FormControlLabel value="Male" control={<Radio />} label="Male" />
          <FormControlLabel value="Female" control={<Radio />} label="Female" />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        <RadioGroup row aria-label="role" name="row-radio-buttons-group" value={values.role} onChange={(e) => setFieldValue('role', e.target.value)}>
          <FormControlLabel value="Student" control={<Radio />} label="Student" onClick={() => setRole('Student')} />
          <FormControlLabel value="Parent" control={<Radio />} label="Parent" onClick={() => setRole('Parent')} />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="right">
          <MuiLink component={Link} to="/login" className={styles.forgotPassword}>
            Already have an account? Login
          </MuiLink>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <YellowButton
          fullWidth
          disabled={isLoading}
          onClick={submitForm}
          type="submit"
          endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        >
          Register
        </YellowButton>
      </Grid>
    </>
  );
};

const RenderSite2Field = (props: any) => {
  const { submitForm, values, errors, touched, setFieldValue, isLoading, step, setStep, role, setRole, profileData, validateField } = props;

  const [stepError, setStepError] = useState([false, false, false, false]);
  const [showPassword, setShowPassword] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState({ label: 'Canada', isoCode: 'CA' });
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const verifyCallback = (response: any) => {
    if (response) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  };
  const onLoadCallback = () => {
    setIsSubmitButtonDisabled(true);
  };
  const onRecapExpire = () => {
    setIsSubmitButtonDisabled(true);
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: `${name}`
        }));
        setCountries(allCountries);
      } catch (error) {
        setCountries([]);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry.isoCode);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          label: name
        }));
        setCities([]);
        setSelectedCity('');
        setStates(allStates);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity('');
      }
    };
    setSelectedState({ label: '' });
    setSelectedCity({ label: '' });
    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          label: name
        }));
        setCities(allCities);
      } catch (error) {
        setCities([]);
      }
    };
    setSelectedCity({ label: '' });
    getCities();
  }, [selectedState]);

  React.useEffect(() => {
    const errorNames = Object.keys(errors);
    let errorObj = [false, false, false, false];
    errorNames.forEach((item) => {
      if (
        item === 'firstName' ||
        item === 'lastName' ||
        item === 'email' ||
        item === 'lastName' ||
        item === 'healthCareNumber' ||
        item === 'dateOfBirth' ||
        item === 'password' ||
        item === 'confirmPassword'
      ) {
        errorObj[1] = true;
      }
    });
    setStepError(errorObj);
  }, [errors]);

  const PrevStepButton = () => {
    return (
      <>
        <Button
          disabled={step === 1 || step === 3 || (step == 4 && profileData.role === 'Parent')}
          className={styles.prevButton}
          onClick={() => setStep((prev: number) => (prev !== 1 ? prev - 1 : prev))}
          startIcon={<FiArrowLeftCircle />}
        >
          Previous Step
        </Button>
      </>
    );
  };

  const SubmitButton = ({ text = 'Register', onClick = submitForm }: { text?: string; onClick?: any }) => {
    return (
      <Grid item xs={12}>
        <PrimaryButton
          fullWidth
          disabled={isLoading || isSubmitButtonDisabled}
          onClick={onClick}
          type="submit"
          endIcon={isLoading && <CircularProgress size={20} color="inherit" />}
          variant="rounded"
        >
          {text}
        </PrimaryButton>
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Typography className={styles.logoTextGv} mb={4}>
          Create a myGV account
        </Typography>
        <div className={styles.controlContainer}>
          {[1, 2, 3, 4].map((item) => (
            <Button
              key={item}
              disabled={step < item}
              className={`${step >= item ? styles.active : ''} ${styles.formControls} ${stepError[item - 1] && step >= item && styles.error}`}
              // onClick={() => {
              //   if (step >= item && step !== 3) setStep(item);
              // }}
            >
              {item}
            </Button>
          ))}
        </div>
      </Grid>
      {/* <PrevStepButton /> */}

      {step === 1 && siteName.includes(siteNames.site2) && (
        <>
          {/* Parent Student */}
          <Grid item xs={12}>
            <AnimationContainer direction="fade">
              <Box sx={{ width: '100%' }} className={styles.radioContainer}>
                <label className={styles.card} htmlFor="radio-student">
                  <input
                    name="role"
                    id="radio-student"
                    className={styles.radio}
                    type="radio"
                    value="Student"
                    onClick={() => {
                      setFieldValue('role', 'Student');
                      setRole('Student');
                    }}
                    checked={values.role === 'Student'}
                  />
                  <span className={styles.radioDetails}>
                    <span className={styles.radioName}>Student</span>
                    <span className={styles.radioDesc}>10+. This account is for any Students registering themselves.</span>
                  </span>
                </label>

                <label className={styles.card} htmlFor="radio-parent">
                  <input
                    name="role"
                    id="radio-parent"
                    className={styles.radio}
                    type="radio"
                    value="Parent"
                    onClick={() => {
                      setFieldValue('role', 'Parent');
                      setRole('Parent');
                    }}
                    checked={values.role === 'Parent'}
                  />
                  <span className={styles.radioDetails}>
                    <span className={styles.radioName}>Parent</span>
                    <span className={styles.radioDesc}>18+. This account is for all Parents registering their children.</span>
                  </span>
                </label>
              </Box>
            </AnimationContainer>
          </Grid>
          <StepButton setStep={setStep} />
          <Box style={{ width: '100%' }}>
            <Typography className={`${styles.forgotPassword} text-center`} style={{ marginTop: 5 }}>
              Already have an account?
              <MuiLink component={Link} to={routes.login.path}>
                {' '}
                Login
              </MuiLink>
            </Typography>
          </Box>
        </>
      )}
      {step === 2 && (
        <>
          <Grid item xs={12} sm={12}>
            <PrevStepButton />
          </Grid>
          <Grid item xs={12} sm={12}>
            <h6 className={styles.formSubHeader}>Personal Information</h6>
          </Grid>
          {/* FirstName */}
          <Grid item xs={12} sm={6}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="firstName"
                variant="outlined"
                type="text"
                required
                placeholder="First Name"
                label="First Name"
                name="firstName"
                size="small"
                onChange={(e) => setFieldValue('firstName', e.target.value)}
                value={values['firstName']}
                error={touched['firstName'] && Boolean(errors['firstName'])}
                helperText={touched['firstName'] && errors['firstName']}
              />
            </AnimationContainer>
          </Grid>
          {/* Middle Name */}
          {/* <Grid item xs={12} sm={12}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="middleName"
                variant="outlined"
                type="text"
                placeholder="Middle Name"
                label="Middle Name"
                name="middleName"
                size="small"
                onChange={(e) => setFieldValue('middleName', e.target.value)}
                value={values['middleName']}
              />
            </AnimationContainer>
          </Grid> */}
          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="lastName"
                variant="outlined"
                type="text"
                required
                placeholder="Last Name"
                label="Last Name"
                name="lastName"
                size="small"
                onChange={(e) => setFieldValue('lastName', e.target.value)}
                value={values['lastName']}
                error={touched['lastName'] && Boolean(errors['lastName'])}
                helperText={touched['lastName'] && errors['lastName']}
              />
            </AnimationContainer>
          </Grid>
          {/* Gender */}
          <Grid item xs={12}>
            <AnimationContainer direction="fade">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                value={values.gender}
                onChange={(e) => setFieldValue('gender', e.target.value)}
              >
                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                <FormControlLabel value="Female" control={<Radio />} label="Female" />
              </RadioGroup>
            </AnimationContainer>
          </Grid>
          {/* DOB */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <DesktopDatePicker
                label="Date of Birth"
                inputFormat="DD/MM/YYYY"
                onChange={(newValue: Dayjs | null) => setFieldValue('dateOfBirth', newValue)}
                value={values['dateOfBirth']}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    error={touched['dateOfBirth'] && Boolean(errors['dateOfBirth'])}
                    helperText={touched['dateOfBirth'] && errors['dateOfBirth']}
                    placeholder="Date of Birth"
                    name="dateOfBirth"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </AnimationContainer>
          </Grid>
          {/* Phone */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <MuiPhoneInput
                defaultCountry={'ca'}
                disableAreaCodes
                countryCodeEditable={false}
                enableLongNumbers={false}
                excludeCountries={['us']}
                fullWidth
                variant="outlined"
                type="phone"
                required={siteName?.includes(siteNames.site1)}
                dropdownClass="phone_dropdown"
                label="Phone"
                name="phone"
                size="small"
                onChange={(val) => setFieldValue('phone', val.toString())}
                value={values['phone']}
                error={touched['phone'] && Boolean(errors['phone'])}
                helperText={touched['phone'] && errors['phone']}
              />
            </AnimationContainer>
          </Grid>
          {/* Email */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="email"
                variant="outlined"
                type="email"
                required
                label="Email"
                placeholder="Email"
                name="email"
                size="small"
                onChange={(e) => setFieldValue('email', e.target.value)}
                value={values['email']}
                error={touched['email'] && Boolean(errors['email'])}
                helperText={touched['email'] && errors['email']}
              />
            </AnimationContainer>
          </Grid>
          {/* Confirm Email */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="email"
                variant="outlined"
                type="email"
                required
                label="Confirm Email"
                placeholder="Confirm Email"
                name="confirmEmail"
                size="small"
                onChange={(e) => {
                  setFieldValue('confirmEmail', e.target.value);
                  validateField('confirmEmail');
                }}
                value={values['confirmEmail']}
                error={Boolean(errors['confirmEmail'])}
                helperText={errors['confirmEmail']}
              />
            </AnimationContainer>
          </Grid>
          <>
            {role === 'Student' ? (
              <>
                {/* healthCare */}
                <Grid item xs={12}>
                  <AnimationContainer direction="fade">
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Please enter your 9 digit Alberta Healthcare Number" TransitionComponent={Zoom} placement="left">
                              <InfoOutlinedIcon sx={{ fontSize: '19px', cursor: 'pointer' }} />
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      data-testid="healthCareNumber"
                      variant="outlined"
                      type="text"
                      placeholder="Health Care Number"
                      label="Health Care Number"
                      required={siteName?.includes(siteNames.site2) && role === 'Student'}
                      name="healthCareNumber"
                      size="small"
                      onChange={(e) => setFieldValue('healthCareNumber', e.target.value)}
                      value={values['healthCareNumber']}
                      error={touched['healthCareNumber'] && Boolean(errors['healthCareNumber'])}
                      helperText={touched['healthCareNumber'] && errors['healthCareNumber']}
                    />
                  </AnimationContainer>
                </Grid>
                {/* Allergies */}
                <Grid item xs={12}>
                  <AnimationContainer direction="fade">
                    <TextField
                      fullWidth
                      data-testid="allergies"
                      variant="outlined"
                      type="text"
                      placeholder="Allergies"
                      label="Allergies"
                      name="allergies"
                      size="small"
                      multiline
                      minRows={3}
                      maxRows={4}
                      onChange={(e) => setFieldValue('allergies', e.target.value)}
                      value={values['allergies']}
                      error={touched['allergies'] && Boolean(errors['allergies'])}
                      helperText={touched['allergies'] && errors['allergies']}
                    />
                  </AnimationContainer>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </>
          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={12}>
            <h6 className={styles.formSubHeader}>Address</h6>
          </Grid>
          {/* Country */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <Autocomplete
                fullWidth
                data-testid="country"
                size="small"
                options={countries}
                value={selectedCountry}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setSelectedCountry({
                      label: newValue,
                      isoCode: ''
                    });
                    setFieldValue('country', newValue);
                  } else if (newValue && newValue.inputValue) {
                    setSelectedCountry({
                      label: newValue.inputValue,
                      isoCode: ''
                    });
                    setFieldValue('country', newValue.inputValue);
                  } else {
                    setSelectedCountry(newValue);
                    setFieldValue('country', newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    error={touched['country'] && Boolean(errors['country'])}
                    name="country"
                    required
                    type="text"
                    placeholder="Country"
                    label="Country"
                    helperText={touched['country'] && errors['country']}
                    {...params}
                  />
                )}
              />
            </AnimationContainer>
          </Grid>
          {/* state */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <Autocomplete
                fullWidth
                data-testid="state"
                size="small"
                options={states}
                value={selectedState}
                onChange={(event: any, newValue: any | null) => {
                  if (typeof newValue === 'string') {
                    setSelectedState({
                      label: newValue
                    });
                    setFieldValue('state', newValue);
                  } else if (newValue && newValue.inputValue) {
                    setSelectedState({
                      label: newValue.inputValue
                    });
                    setFieldValue('state', newValue.inputValue);
                  } else {
                    setSelectedState(newValue);
                    setFieldValue('state', newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    value={selectedState?.label}
                    error={touched['state'] && Boolean(errors['state'])}
                    name="state"
                    required
                    type="text"
                    placeholder="Province"
                    label="Province"
                    helperText={touched['state'] && errors['state']}
                    {...params}
                  />
                )}
              />
            </AnimationContainer>
          </Grid>
          {/* city */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <Autocomplete
                fullWidth
                data-testid="city"
                size="small"
                options={cities}
                value={selectedCity}
                onChange={(event: any, newValue: any | null) => {
                  if (typeof newValue === 'string') {
                    setSelectedCity({
                      label: newValue
                    });
                    setFieldValue('city', newValue);
                  } else if (newValue && newValue.inputValue) {
                    setSelectedCity({
                      label: newValue.inputValue
                    });
                    setFieldValue('city', newValue.inputValue);
                  } else {
                    setSelectedCity(newValue);
                    setFieldValue('city', newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    error={touched['city'] && Boolean(errors['city'])}
                    name="city"
                    value={selectedCity?.label}
                    required
                    type="text"
                    placeholder="City"
                    label="City"
                    helperText={touched['city'] && errors['city']}
                    {...params}
                  />
                )}
              />
            </AnimationContainer>
          </Grid>
          {/* ZIP */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <TextField
                required
                fullWidth
                data-testid="zipCode"
                variant="outlined"
                type="text"
                placeholder="Postal Code"
                label="Postal Code"
                name="zipCode"
                size="small"
                onChange={(e) => setFieldValue('zipCode', e.target.value)}
                value={values['zipCode']}
                error={touched['zipCode'] && Boolean(errors['zipCode'])}
                helperText={touched['zipCode'] && errors['zipCode']}
              />
            </AnimationContainer>
          </Grid>
          {/* address */}
          <Grid item xs={12} sm={12}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                multiline
                rows={3}
                required
                data-testid="address"
                variant="outlined"
                type="text"
                placeholder="Address"
                label="Address"
                name="address"
                size="small"
                onChange={(e) => setFieldValue('address', e.target.value)}
                value={values['address']}
                error={touched['address'] && Boolean(errors['address'])}
                helperText={touched['address'] && errors['address']}
              />
            </AnimationContainer>
          </Grid>
          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={12}>
            <h6 className={styles.formSubHeader}>Password</h6>
          </Grid>
          {/* Password */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                size="small"
                placeholder="Password"
                required
                label="Password"
                name="password"
                value={values['password']}
                error={Boolean(errors['password'])}
                onChange={(e) => {
                  setFieldValue('password', e.target.value);
                  validateField('password');
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                helperText={errors['password']}
              />
            </AnimationContainer>
          </Grid>
          {/* Confirm Password */}
          <Grid item xs={12} md={6}>
            <AnimationContainer direction="fade">
              <TextField
                fullWidth
                data-testid="confirmPassword"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                size="small"
                placeholder="Confirm Password"
                required
                label="Confirm Password"
                name="confirmPassword"
                value={values['confirmPassword']}
                error={Boolean(errors['confirmPassword'])}
                onChange={(e) => {
                  setFieldValue('confirmPassword', e.target.value);
                  validateField('confirmPassword');
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className="p-0" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                helperText={errors['confirmPassword']}
              />
            </AnimationContainer>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ marginInline: 'auto', maxWidth: 'max-content' }}>
              <Recaptcha
                sitekey={process?.env?.REACT_APP_SITE_KEY}
                render="explicit"
                verifyCallback={verifyCallback}
                onloadCallback={onLoadCallback}
                expiredCallback={onRecapExpire}
              />
            </Box>
          </Grid>
          <SubmitButton text="Continue" />
          <Box style={{ width: '100%' }}>
            <Typography className={`${styles.forgotPassword} text-center`} style={{ marginTop: 5 }}>
              Already have an account?
              <MuiLink component={Link} to={routes.login.path}>
                {' '}
                Login
              </MuiLink>
            </Typography>
          </Box>
          {/* <AnimationContainer direction="fade" style={{ width: '100%' }}>
            <Box textAlign="center">
              Already have an account?
              <MuiLink component={Link} to="/login" className={styles.forgotPassword}>
                Login
              </MuiLink>
            </Box>
          </AnimationContainer> */}
        </>
      )}
    </>
  );
};
