import { nanoid } from 'nanoid';
import Teacher1Image from 'src/components/Site1/assets/Teacher1.png';
import Teacher2Image from 'src/components/Site1/assets/Teacher2.png';
import Teacher3Image from 'src/components/Site1/assets/Teacher3.png';
import Teacher4Image from 'src/components/Site1/assets/Teacher4.png';
import Teacher5Image from 'src/components/Site1/assets/Teacher5.png';
import Teacher6Image from 'src/components/Site1/assets/Teacher6.png';

export const teachersData = {
  heading: 'Our Expert Teachers',
  teachers: [
    {
      _id: nanoid(),
      image: Teacher1Image,
      name: 'Bhai Khazan Singh'
    },
    {
      _id: nanoid(),
      image: Teacher2Image,
      name: 'Bhai Hardeep Singh'
    },
    {
      _id: nanoid(),
      image: Teacher3Image,
      name: 'Bhai Manjit Singh'
    },
    {
      _id: nanoid(),
      image: Teacher4Image,
      name: 'Bhai Deepak Singh'
    },
    {
      _id: nanoid(),
      image: Teacher5Image,
      name: 'Dr. Amrita Kaur Bains'
    },
    {
      _id: nanoid(),
      image: Teacher6Image,
      name: 'Sardarni Jasleen Kaur Saini'
    }
  ]
};
