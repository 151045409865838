import { Outlet } from 'react-router-dom';
import Layout from './Layout';
import React from 'react';
import { siteName, siteNames, getFaviconEl } from 'src/config';

import site1Favicon from 'src/assets/favicons/site1Favicon.ico';
import site2Favicon from 'src/assets/favicons/site2Favicon.ico';

const PublicRoute = () => {
  React.useEffect(() => {
    const favicon = getFaviconEl();
    if (siteName === siteNames.site1) {
      document.title = 'Sikh National Education Institute';
      favicon.href = site1Favicon;
    } else if (siteName === siteNames.site2) {
      document.title = 'Gurprasad Vidyala | Afterschool Gurmat Academy';
      favicon.href = site2Favicon;
    }
  }, [siteName]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PublicRoute;
