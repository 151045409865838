import React from 'react';
import Site1Teachers from 'src/components/Site1/Teachers';
import PageNotFound from 'src/pages/PageNotFound';
import { siteNames, siteName } from 'src/config';

const AboutUs = () => {
  if (siteName?.includes(siteNames.site1)) {
    return <Site1Teachers />;
  } else {
    return <PageNotFound />;
  }
};

export default AboutUs;
