import React, { useState, useEffect } from 'react';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { routes } from 'src/helpers/routes';
// import { Courses } from 'src/components/Site1/interface';
import { HeaderLinksInterface } from 'src/components/Site/interface';
import { nanoid } from 'nanoid';
import { donationLink } from 'src/config';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// GV resource icons
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';

import gatkaClassIcon from 'src/components/Site/assets/classIcons/gatkaClass.png';
import gurmatCamps from 'src/components/Site/assets/classIcons/gurmatCamps.png';
import historyClass from 'src/components/Site/assets/classIcons/History.png';
import kiddieSangat from 'src/components/Site/assets/classIcons/KiddieSangat.png';
import kidsPunjabiClass from 'src/components/Site/assets/classIcons/KidsPunjabiClass.svg';
import kirtanClass from 'src/components/Site/assets/classIcons/KirtanClass.png';
import santhyaClass from 'src/components/Site/assets/classIcons/Santhya.png';
import sportsProgram from 'src/components/Site/assets/classIcons/SportsProgram.png';
import tablaClass from 'src/components/Site/assets/classIcons/TablaClass.png';
import wellnessProgram from 'src/components/Site/assets/classIcons/WellnessProgram.png';
import fieldTrips from 'src/components/Site/assets/classIcons/FieldTrips.png';

import myGv from 'src/components/Site/assets/classIcons/myGv.svg';
import googleClassroom from 'src/components/Site/assets/classIcons/google-classroom.png';
import akaaliStore from 'src/components/Site/assets/classIcons/akaaliStore.png';
import pictures from 'src/components/Site/assets/classIcons/pictures.png';
import publicCalendar from 'src/components/Site/assets/classIcons/publicCalendar.png';
import sangatProgram from 'src/components/Site/assets/classIcons/sangatProgram.png';
import sikhiResources from 'src/components/Site/assets/classIcons/sikhiResources.png';
import youthDarbar from 'src/components/Site/assets/classIcons/youthDarbar.png';
import { SiGoogleclassroom } from 'react-icons/si';

// GET ICONS AND ASSIGN COLOR
export const getClassIcon = (name: string) => {
  let icon = <img src={historyClass} alt="" className="color4" style={{ maxWidth: '18px', marginInline: '3px' }} />;
  switch (name) {
    // CLASSES ICONS
    case 'Kids Keertan Class':
      icon = <img src={kirtanClass} alt="" className="color5" style={{ maxWidth: '25px' }} />;
      break;
    case 'Tabla Class':
      icon = <img src={tablaClass} alt="" className="color2" style={{ maxWidth: '24px' }} />;
      break;
    case 'Kids Punjabi Class':
      icon = <img src={kidsPunjabiClass} alt="" style={{ maxWidth: '24px' }} />;
      break;
    case 'History':
      icon = <img src={historyClass} alt="" className="color4" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Santheya':
      icon = <img src={santhyaClass} alt="" className="color5" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Gatka Class':
      icon = <img src={gatkaClassIcon} alt="" className="color2" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Kiddie Sangat':
      icon = <img src={kiddieSangat} alt="" className="color6" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;

    // CAMPS
    case 'Gurmat Camps':
      icon = <img src={gurmatCamps} alt="" className="color1" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Sports Programs':
      icon = <img src={sportsProgram} alt="" className="color2" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Wellness Programs':
      icon = <img src={wellnessProgram} alt="" className="color3" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Youth Darbars':
      icon = <img src={youthDarbar} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Field Trips':
      icon = <img src={fieldTrips} alt="" className="color5" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Sangat Programs':
      icon = <img src={sangatProgram} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;

    // GV RESOURCES

    case 'Public Calendar':
      icon = <img src={publicCalendar} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Pictures':
      icon = <img src={pictures} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'myGV':
      icon = <img src={myGv} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Sikhi Resources':
      icon = <img src={sikhiResources} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Akaali Store':
      icon = <img src={akaaliStore} alt="" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
    case 'Google Classroom':
      icon = <SiGoogleclassroom style={{ color: '#7869e6 ', maxWidth: '18px', marginInline: '3px' }} />;
      break;
    default:
      icon = <img src={historyClass} alt="" className="color4" style={{ maxWidth: '18px', marginInline: '3px' }} />;
      break;
  }
  return icon;
};

export const useHeaderLinks = () => {
  const toastConfig = React.useContext(CustomToastContext);
  const [headerLinks, setHeaderLinks] = useState<HeaderLinksInterface[]>(null);

  const otherLinks: HeaderLinksInterface[] = [
    {
      _id: nanoid(),
      linkText: routes.programs.title,
      linkTitle: 'PROGRAMS OVERVIEW',
      link: routes.programs.path,
      titleLink: '#',
      linkType: 'internal',
      items: programSubHeader,
      sideBar: false,
      headerBottom: programsHeaderBottom
    },
    {
      _id: nanoid(),
      linkText: routes.gvResources.title,
      linkTitle: 'GV RESOURCES OVERVIEW',
      titleLink: '#',
      link: routes.gvResources.path,
      sideBar: false,
      linkType: 'internal',
      items: gvResourcesSubheader
    },
    {
      _id: nanoid(),
      linkText: 'Volunteer',
      linkTitle: 'CLASSES OVERVIEW',
      link: routes.volunteer.path,
      linkType: 'internal',
      items: []
    },
    {
      _id: nanoid(),
      linkText: 'Contact Us',
      linkTitle: 'CLASSES OVERVIEW',
      link: routes.contactUs.path,
      linkType: 'internal',
      items: []
    },
    {
      _id: nanoid(),
      linkText: 'Donate',
      linkTitle: 'CLASSES OVERVIEW',
      link: donationLink,
      linkType: 'external',
      items: []
    }
  ];

  const fetchHeadLinks = () => {
    axiosInstance()
      .get('/courses')
      .then(({ data: { data } }) => {
        const allLinks: HeaderLinksInterface[] = [];
        const classesLink: HeaderLinksInterface = {
          _id: nanoid(),
          linkText: 'Classes',
          linkTitle: 'CLASSES OVERVIEW',
          link: '#',
          items: [],
          linkType: 'internal',
          sideBar: true,
          headerBottom
        };
        const classes = gurmatClasses(data);
        // const links = [classes, kiddleSangat, ielts, tutoring];
        const links = [classes, ielts, tutoring];

        classesLink.items = links;
        allLinks.push(classesLink);
        allLinks.push(...otherLinks);
        setHeaderLinks(allLinks);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };
  useEffect(() => {
    fetchHeadLinks();
  }, []);

  return headerLinks;
};

const gurmatClasses = (classes: any) => {
  const createListFromClasses = (classes: any) => {
    let list: any = [];
    classes.map((item: any, index: number) => {
      // if (item.courseName !== 'Kiddie Sangat') {
      let obj: any = {};
      obj.linkText = item.courseName;
      // obj.linkIcon = iconList[index];
      obj.linkIcon = getClassIcon(item.courseName);
      obj.dynamicIcon = item.icon;
      obj.linkTitle = item.courseInfo;
      obj.link = `${routes.classDetails.path}/${item._id}`;
      obj.linkType = 'internal';
      list.push(obj);
      // }
    });
    return list;
  };

  const gurmatObj = {
    _id: nanoid(),
    linkText: 'Gurmat Classes',
    linkTitle: 'Gurmat Classes',
    link: routes.classes.path,
    items: createListFromClasses(classes)
  };
  return gurmatObj;
};

// const kiddleSangat = {
//   _id: nanoid(),
//   linkText: 'Kiddle Sangat',
//   linkTitle: 'Kiddle Sangat',
//   link: routes.classes.path,
//   items: [
//     {
//       _id: nanoid(),
//       linkText: 'Classes',
//       linkTitle: 'Break down any size project into customized tesks.',
//       linkIcon: getClassIcon('Kiddie Sangat'),
//       link: `${routes.courses.path}?courseName=Kiddie Sangat`,
//       linkType: 'internal'
//     },
//     {
//       _id: nanoid(),
//       linkText: 'Purpose',
//       linkTitle: 'Bring Team Ideas to life with visual collaboration.',
//       linkIcon: <TextSnippetOutlinedIcon style={{ color: '#70c9f4' }} />,
//       link: '#',
//       linkType: 'internal'
//     }
//   ]
// };
const ielts = {
  _id: nanoid(),
  linkText: 'IELTS',
  linkTitle: 'IELTS',
  link: routes.classes.path,
  items: [{ _id: nanoid(), linkText: 'Cooming Soon!', link: '#', linkType: 'internal' }]
};
const tutoring = {
  _id: nanoid(),
  linkText: 'Tutoring',
  linkTitle: 'Tutoring',
  link: `#`,
  items: [{ _id: nanoid(), linkText: 'Cooming Soon!', link: '#', linkType: 'internal' }]
};

const programSubHeader = [
  {
    linkText: 'Gurmat Camps',
    linkIcon: getClassIcon('Gurmat Camps'),
    linkTitle: 'Break down any size project into customized tasks.',
    link: `${routes.programs.path}/Gurmat-Camps`,
    linkType: 'internal'
  },
  {
    linkText: 'Sports Programs',
    linkIcon: getClassIcon('Sports Programs'),
    linkTitle: 'Create Beautiful docs and connect them to tasks.',
    link: `${routes.programs.path}/Sports-Programs`,
    linkType: 'internal'
  },

  {
    linkText: 'Wellness Programs',
    linkIcon: getClassIcon('Wellness Programs'),
    linkTitle: 'Track work against strategy with measurable targets.',
    link: `${routes.programs.path}/Wellness-Programs`,
    linkType: 'internal'
  },
  {
    linkText: 'Youth Darbars',
    linkIcon: getClassIcon('Youth Darbars'),
    linkTitle: 'Bring Team ideas to life with visual collaboration.',
    link: `${routes.programs.path}/Youth-Darbars`,
    linkType: 'internal'
  },
  {
    linkText: 'Field Trips',
    linkIcon: getClassIcon('Field Trips'),
    linkTitle: 'Visualize progress with real time reporting.',
    link: `${routes.programs.path}/Field-Trips`,
    linkType: 'internal'
  },
  {
    linkText: 'Sangat Programs',
    linkIcon: getClassIcon('Sangat Programs'),
    linkTitle: 'Collaborate with your team in real-time',
    link: `${routes.programs.path}/Sangat-Programs`,
    linkType: 'internal'
  }
];

const gvResourcesSubheader = [
  {
    linkText: 'Public Calendar',
    linkIcon: getClassIcon('Public Calendar'),
    linkTitle: 'Break down any size project into customized tasks.',
    link: `${routes.gvResources.path}/public-calendar`,
    linkType: 'internal'
  },
  {
    linkText: 'Pictures',
    linkIcon: getClassIcon('Pictures'),
    linkTitle: 'Create beautiful docs and connect them to tasks.',
    link: `${routes.gvResources.path}/pictures`,
    linkType: 'internal'
  },

  {
    linkText: 'myGV',
    linkIcon: getClassIcon('myGV'),
    linkTitle: 'Student focused LMS coming soon',
    link: `${routes.myGv.path}`,
    linkType: 'internal',
    backgroundColor: '#f7f7f7'
  },
  {
    linkText: 'Sikhi Resources',
    linkIcon: getClassIcon('Sikhi Resources'),
    linkTitle: 'Bring team ideas to life with visual collaboration.',
    link: `${routes.gvResources.path}/sikhi-resources`,
    linkType: 'internal'
  },
  {
    linkText: 'Akaali Store',
    linkIcon: getClassIcon('Akaali Store'),
    linkTitle: 'Visualize progress with real time reporting.',
    link: `${routes.gvResources.path}/akaali-store`,
    linkType: 'internal'
  },
  {
    linkText: 'Google Classroom',
    linkIcon: getClassIcon('Google Classroom'),
    linkTitle: 'Material arts and self defence class',
    link: `${routes.gvResources.path}/google-classroom`,
    linkType: 'internal',
    backgroundColor: '#f7f7f7'
  }
];

// const iconList = [
//   <CheckBoxOutlinedIcon style={{ color: '#52b588' }} />,
//   <TextSnippetOutlinedIcon style={{ color: '#70c9f4' }} />,
//   <EmojiEventsOutlinedIcon style={{ color: '#fb71ac' }} />,
//   <GestureOutlinedIcon style={{ color: '#5c76f6' }} />,
//   <GridViewOutlinedIcon style={{ color: '#7869e6' }} />,
//   <TagOutlinedIcon style={{ color: '#fdc901' }} />
// ];

// #add8e6
// #ffa500
// #800080
// #5c76f6
// #7869e6
// #fdc901

export const iconList = [
  <CheckBoxOutlinedIcon style={{ color: 'lightblue' }} />,
  <TextSnippetOutlinedIcon style={{ color: 'orange' }} />,
  <EmojiEventsOutlinedIcon style={{ color: 'purple' }} />,
  <GestureOutlinedIcon style={{ color: 'rgb(255 177 0)' }} />,
  <GridViewOutlinedIcon style={{ color: '#7869e6' }} />,
  <TagOutlinedIcon style={{ color: '#fdc901' }} />,
  <TagOutlinedIcon style={{ color: '#fec80f' }} />
];

export const headerBottom = {
  title: 'Register to start your journey today. Limited spots available.',
  buttonText: 'Enroll',
  link: routes.courses.path
};

const programsHeaderBottom = {
  title: 'GV Gurmat Winter Camp 2023 is open for registration!',
  buttonText: 'Enroll',
  link: '#'
};
