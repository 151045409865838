import React from 'react';
import { Link } from 'react-router-dom';
import { buttonStyle } from '../../Buttons';
import styles from './index.module.scss';
import bannerDecoration from 'src/assets/svg/BannerDecoration.svg';

interface inputProps {
  bannerInfo: any;
  decoration?: boolean;
}

const Banner: React.FC<inputProps> = ({ bannerInfo, decoration = true }) => {
  return (
    <section className={styles.mainSection}>
      <div className="container pos-rel">
        {decoration && <img src={bannerDecoration} alt="" aria-hidden height={168} width={124.575} className={`${styles.decoration}`} />}
        <div className={`d-flex primary-text ${styles.bannerContainer}`}>
          <div className={`${styles.textContainer}`}>
            {bannerInfo?.heading && <h3>{bannerInfo?.heading}</h3>}
            {bannerInfo?.subHeading && <h1>{bannerInfo?.subHeading}</h1>}
            {bannerInfo?.paragraph !== '' && (
              <p>
                <big>{bannerInfo?.paragraph}</big>
              </p>
            )}
            {bannerInfo.buttons?.length > 0 &&
              bannerInfo.buttons.map((button: any, key: any) => {
                const Button = buttonStyle(button.type);
                return (
                  <Link key={button.id} to={button.link}>
                    <Button style={{ color: 'white' }}>{button.text}</Button>
                  </Link>
                );
              })}
          </div>
          <div className={`${styles.imageContainer}`} style={{ position: 'relative' }}>
            <div className="loader2"></div>
            {bannerInfo?.bannerImage && (
              <img
                alt=""
                src={bannerInfo?.bannerImage}
                loading="lazy"
                onLoad={(e) => {
                  e.currentTarget.previousElementSibling.remove();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
