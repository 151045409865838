import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import moment from 'moment-timezone';
import { nanoid } from 'nanoid';

const colors = ['#FFE6A6', '#FDD3E2', '#C4F9C9', '#E1D8FD', '#FFDFB9', '#E1EAFF', '#0BA2AC30'];
const WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const sortByTime = (arr: any) => {
  const sorterDate = (a: any, b: any) => {
    const aDateTime = `${moment().format('MMM DD YYYY')} ${moment(a.startTime).format('LTS')}`;
    const bDateTime = `${moment().format('MMM DD YYYY')} ${moment(b.startTime).format('LTS')}`;
    return new Date(aDateTime).getTime() - new Date(bDateTime).getTime();
  };
  arr.sort(sorterDate);
  return arr;
};

const createGridAreas = (dates: any, uniqueTimes: any) => {
  let gridArea: string[] = [];
  const topRow = `"time ${WeekDays.join().replaceAll(',', ' ')}"`;

  const rows = uniqueTimes.map((item: any) => {
    const timeCol = `${moment(item['start']).format('ahmm')}`;
    const row = WeekDays.map((item: any) => `${item}${timeCol}`)
      .join()
      .replaceAll(',', ' ');
    return `"${timeCol} ${row}"`;
  });
  const gridData = topRow + rows.join().replaceAll(',', ' ');

  return gridData;
};

const getUniqueEntries = (array: any, key: any) => {
  const arrayUniqueByKey = [...new Map(array?.map((item: any) => [item[key], item])).values()];
  return arrayUniqueByKey;
};

const getUniqueTimes = (array: any) => {
  const timesObj = [
    ...new Map(array?.map((item: any) => [moment(item['start']).format('h:mm a') && moment(item['end']).format('h:mm a'), item])).values()
  ];
  return sortByTime(timesObj);
};

const getTime = (date: any) => {
  return moment(date).format('h:mm A');
};
const getUniqueColor = (array: any) => {
  let colorIndex = 0;
  let colorObj: any = {};
  for (let i = 0; i < array.length; i++) {
    if (colorIndex > colors.length) {
      colorIndex = 0;
    }
    colorObj[array[i]] = colors[colorIndex];
    colorIndex++;
  }
  return colorObj;
};

const CalanderWithLegend = ({ calendarData }: any) => {
  const [thisWeekData, setThisWeekData] = useState(null);
  const [uniqueClasseNames, setUniqueClasseNames] = useState(null);
  const [gridArea, setGridArea] = useState(null);
  const [uniqueTimes, setUniqueTimes] = useState(null);
  const [classNameColor, setClassNameColor] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const getThisWeekData = (dates: any) => {
    const dayIndex = new Date().getDay();
    const leftIndex = dayIndex + 1;
    const rightIndex = 7 - (dayIndex + 1);
    let thisWeekData: any[] = [];
    dates?.forEach((item: any) => {
      if (
        moment(item.start).isSameOrAfter(moment(new Date()).subtract(leftIndex, 'days')) &&
        moment(item.start).isSameOrBefore(moment(new Date()).add(rightIndex, 'days'))
      ) {
        thisWeekData.push(item);
      }
    });
    return { thisWeekData, leftIndex, rightIndex };
  };

  useEffect(() => {
    const { thisWeekData, leftIndex, rightIndex } = getThisWeekData(calendarData?.dates);
    const uniqueTimes = getUniqueTimes(thisWeekData);
    const uniqueClassNames = getUniqueEntries(thisWeekData, 'title').map((item: any) => item.title);
    setThisWeekData({ thisWeekData, leftIndex, rightIndex });
    setUniqueClasseNames(() => getUniqueEntries(thisWeekData, 'title'));
    setClassNameColor(() => getUniqueColor(uniqueClassNames));
    setUniqueTimes(() => uniqueTimes);
    setGridArea(() => createGridAreas(thisWeekData, uniqueTimes));
  }, [calendarData]);

  return (
    <div className={`${styles.main}`}>
      <div className="container">
        <h2 className={styles.sectionHeading}>{calendarData?.sectionHeading}</h2>
        <div className={`${styles.underLine} `}></div>

        <div className={styles.legendContainer}>
          <RenderLegend titles={uniqueClasseNames} classNameColor={classNameColor} setHoveredItem={setHoveredItem} />
        </div>

        <div className={styles.calenderGrid} style={{ gridTemplateAreas: gridArea }}>
          <div className={`${styles.topRow}`} style={{ gridArea: 'time' }}>
            Time
          </div>
          {WeekDays?.map((item: any) => (
            <div key={item} className={styles.topRow} style={{ gridArea: item }}>
              {item.substr(0, 3)}
            </div>
          ))}
          {uniqueTimes?.map((item: any) => (
            <div key={item.start} className={styles.alignCenter} style={{ gridArea: `${moment(item['start']).format('ahmm')}` }}>
              <div className={styles.time}>
                <span>{getTime(item.start)}-</span>
                <span>{getTime(item.end)}</span>
              </div>
            </div>
          ))}
          {uniqueTimes?.map((unqTime: any) => {
            return WeekDays.map((days: any) => {
              const classes = thisWeekData?.thisWeekData?.filter((item: any) => {
                return (
                  moment(unqTime['start']).format('ahmm') === moment(item['start']).format('ahmm') &&
                  days === moment(item['start']).format('dddd') &&
                  moment(unqTime['end']).format('ahmm') === moment(item['end']).format('ahmm')
                );
              });
              return (
                <div style={{ gridArea: `${days}${moment(unqTime['start']).format('ahmm')}` }} key={nanoid()} className={styles.classes}>
                  {classes.map((item: any) => (
                    <p
                      style={{ ['--color' as any]: classNameColor[item.title] }}
                      className={`${styles.className} ${hoveredItem === item.title ? styles.active : 'hello'}`}
                    >
                      {item.title}
                    </p>
                  ))}
                </div>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default CalanderWithLegend;

const RenderLegend = ({ titles, classNameColor, setHoveredItem }: any) => {
  return (
    <div className={styles.legends}>
      {classNameColor &&
        titles?.map((item: any, index: any) => (
          <div
            className={styles.singleLegend}
            key={item.title}
            onMouseOver={() => setHoveredItem(item.title)}
            onMouseOut={() => setHoveredItem(null)}
          >
            <p style={{ ['--color' as any]: classNameColor[item.title] }}>{item.title}</p>
          </div>
        ))}
    </div>
  );
};
