import { routes } from 'src/helpers/routes';
import aboutUsBannerImage from 'src/components/Site/assets/images/AboutUsBanner.png';
import aboutUsCounterImage from 'src/components/Site/assets/images/AboutUsCounter.png';
import { BiUser } from 'react-icons/bi';
import ourValuesImage from 'src/components/Site/assets/images/OurValues.png';
import { buttonType } from 'src/components/Buttons';
import { nanoid } from 'nanoid';

// About us page data
export const AboutBannerData = {
  heading: 'Our Parent Organization',
  subHeading: 'Mai Bhago Charity',
  paragraph:
    'Mai Bhago Charity is a Sikh non-profit organization that orchestrates and executes community-based initiatives to uplift the Calgary Sikh community. We aim to motivate positive values and provide invaluable resources for future generations.',
  buttons: [
    {
      id: nanoid(),
      link: routes.contactUs.path,
      text: 'Contact Us',
      type: buttonType.primary
    }
  ],
  bannerImage: aboutUsBannerImage
};

export const counterSectionData = {
  image: aboutUsCounterImage,
  headline: 'Build better, launch faster',
  heading: 'We’re only just getting started on our journey',
  counters: [
    { id: nanoid(), end: 400, prefix: '', suffix: '+', text: 'Projects completed' },
    { id: nanoid(), end: 600, prefix: '', suffix: '%', text: 'Return on investment' },
    { id: nanoid(), end: 10, prefix: '', suffix: 'K', text: 'Global downloads' },
    { id: nanoid(), end: 200, prefix: '', suffix: '+', text: '5-star reviews' }
  ]
};

export const teamSectionData = {
  headline: 'We need Volunteers!',
  heading: 'Meet our Admin Team',
  text: 'Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you to do your best work.',
  cards: [
    { id: nanoid(), title: 'Olivia Rhye', description: 'Founder & CEO', icon: BiUser },
    { id: nanoid(), title: 'Phoenix Baker', description: 'Engineering Manager', icon: BiUser },
    { id: nanoid(), title: 'Lana Steiner', description: 'Product Manager', icon: BiUser },
    { id: nanoid(), title: 'Phoenix Baker', description: 'Engineering Manager', icon: BiUser },
    { id: nanoid(), title: 'Phoenix Baker', description: 'Engineering Manager', icon: BiUser },
    { id: nanoid(), title: 'Phoenix Baker', description: 'Engineering Manager', icon: BiUser },
    { id: nanoid(), title: 'Phoenix Baker', description: 'Engineering Manager', icon: BiUser },
    { id: nanoid(), title: 'Phoenix Baker', description: 'Engineering Manager', icon: BiUser }
  ]
};

export const ourValuesData = {
  headline: 'Our values',
  heading: 'Land Acknowledgement',
  text: `Mai Bhago Charity acknowledges the traditional territories of the people of the Treaty 7 region in Southern Alberta, which includes 
  the Blackfoot Confederacy (comprised of the Siksika, Piikani, and Kainai First Nations), as well as the Tsuut’ina First Nation, and the 
  Stoney Nakoda (including the Chiniki, Bearspaw and Wesley First Nations). The City of Calgary is also home to Metis Nation of Alberta, 
  Region 3. Mai Bhago Charity acknowledges the impact of colonization on Indigenous peoples in Canada and is committed to our collective 
  journey towards reconciliation to create a welcome and inclusive campus that encourages Indigenous ways of knowing, doing, connecting and being.`,
  image: ourValuesImage,
  bgColor: '#F9FAFB'
};
