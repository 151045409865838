import React from 'react';
import { FaFacebookF } from 'react-icons/fa';
import styles from './index.module.scss';

export const buttonType = {
  primary: 'Primary',
  secondary: 'Secondary',
  whiteButton: 'WhiteButton',
  yellowButton: 'YellowButton'
};

export const buttonStyle = (type: any) => {
  if (type === buttonType.primary) {
    return PrimaryButton;
  } else if (type === buttonType.whiteButton) {
    return WhiteButton;
  } else if (type === buttonType.yellowButton) {
    return YellowButton;
  } else {
    return SecondaryButton;
  }
};

const roundClass = (variant: 'pill' | 'square' | 'rounded') => {
  if (variant === 'pill') {
    return styles.pill;
  }
  if (variant === 'rounded') {
    return styles.rounded;
  }
  if (variant === 'square') {
    return styles.square;
  }
};

interface inputProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  text?: string;
  children?: any;
  style?: any;
  endIcon?: any;
  startIcon?: any;
  fullWidth?: boolean;
  className?: string;
  variant?: 'pill' | 'square' | 'rounded';
}

export const SecondaryButton = (props: inputProps) => {
  const {
    disabled = false,
    text = 'Secondary Button',
    children = 'Secondary Button',
    style = {},
    endIcon = null,
    startIcon = null,
    fullWidth = false,
    className = ''
  } = props;
  return (
    <div
      style={{ ...style, width: fullWidth ? '100%' : 'unset' }}
      className={`${styles.btn} ${styles.secondary} ${disabled && styles.disabled} ${className}`}
    >
      <span className={`${styles.mas}`} style={{ width: fullWidth ? '100%' : 'unset' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children || text}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </span>
      <button {...props} disabled={disabled} style={{ width: fullWidth ? '100%' : 'unset' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children || text}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </button>
    </div>
  );
};

export const PrimaryButton = (props: inputProps) => {
  const {
    disabled = false,
    text = 'Primary Button',
    children = <span>Primary Button</span> || 'Primary Button',
    style = {},
    endIcon = null,
    startIcon = null,
    fullWidth = false,
    className = '',
    variant = 'pill'
  } = props;

  return (
    <div
      style={{ ...style, width: fullWidth ? '100%' : '' }}
      className={`${styles.btn} ${styles.primary} ${disabled && styles.disabled} ${className} pos-rel ${roundClass(variant)}`}
    >
      <button {...props} disabled={disabled} style={{ width: fullWidth ? '100%' : '' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        <span className={styles.text}>{children || text}</span>
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </button>
    </div>
  );
};

export const WhiteButton = (props: inputProps) => {
  const {
    disabled = false,
    text = 'Primary Button',
    children = <span>White Button</span> || 'White Button',
    style = {},
    endIcon = null,
    startIcon = null,
    fullWidth = false,
    className = ''
  } = props;
  return (
    <div
      style={{ ...style, width: fullWidth ? '100%' : '' }}
      className={`${styles.btn} ${styles.white} ${disabled && styles.disabled} pos-rel ${className}`}
    >
      <span className={styles.mas} style={{ width: fullWidth ? '100%' : '' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children || text}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </span>
      <button {...props} disabled={disabled} style={{ width: fullWidth ? '100%' : '' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children || text}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </button>
    </div>
  );
};

export const SocialButton = ({ onClick = () => {}, disabled = false, icon = <FaFacebookF />, style = {}, ...otherProps }) => {
  return (
    <div style={style} className={`${styles.btn} ${styles.social} ${disabled && styles.disabled}`}>
      <button {...otherProps} onClick={onClick} disabled={disabled}>
        {icon}
      </button>
    </div>
  );
};

export const YellowButton = (props: inputProps) => {
  const {
    disabled = false,
    text = 'Yellow Button',
    children = <span>Yellow Button</span> || 'Yellow Button',
    style = {},
    endIcon = null,
    startIcon = null,
    fullWidth = false,
    className = ''
  } = props;
  return (
    <div
      style={{ ...style, width: fullWidth ? '100%' : '' }}
      className={`${styles.btn} ${styles.yellow} ${disabled && styles.disabled} ${className} pos-rel`}
    >
      <span className={styles.mas} style={{ width: fullWidth ? '100%' : '' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children || text}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </span>
      <button {...props} disabled={disabled} style={{ width: fullWidth ? '100%' : '' }}>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children || text}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </button>
    </div>
  );
};
