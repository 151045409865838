import React from 'react';
import styles from './index.module.scss';
import { buttonType, buttonStyle } from 'src/components/Buttons';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';
import { donationLink } from 'src/config';

interface inputProps {
  sectionData: {
    title?: string;
    background?: string;
  };
}

const Donation: React.FC<inputProps> = ({ sectionData }) => {
  const navigate = useNavigate();
  const Button = buttonStyle(buttonType.whiteButton);
  return (
    <div className={styles.main}>
      <div
        className={styles.bgContainer}
        style={{ backgroundImage: `url(${sectionData.background}), linear-gradient(180deg, #001971, #0036C5)`, marginBottom: -1 }}
      >
        <div className="container">
          <div className={styles.sectionData}>
            <h6>{sectionData.title}</h6>
            <a href={donationLink} target="_blank">
              <Button className={styles.button} endIcon={<FavoriteIcon />}>
                Donate
              </Button>
            </a>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 106" fill="none">
        <path d="M0 -560H720H1440V68C1440 68 1153.09 -73.441 715.5 55.392C251.5 192 0 4.53802 0 4.53802V-560Z" fill="#0036C5" />
        {/* <defs>
          <linearGradient id="paint0_linear_0_1" x1="33" y1="-464.648" x2="1470.44" y2="-357.531" gradientUnits="userSpaceOnUse">
            <stop stop-color="#0036C5" />
            <stop offset="1" stop-color="#001971" />
          </linearGradient>
        </defs> */}
      </svg>
    </div>
  );
};

export default Donation;
