import React from 'react';
import styles from './index.module.scss';

interface howWeWorkInterface {
  title?: string;
  decorationImage: string;
  columns?: Column[];
}
export interface Column {
  id?: string;
  title?: string;
  description?: string;
  icon?: string;
}

interface inputProps {
  sectionData: howWeWorkInterface;
}

const ThreeColWithHeader: React.FC<inputProps> = ({ sectionData }) => {
  return (
    <div className={`container ${styles.main}`}>
      <img className={styles.decorImg} src={sectionData.decorationImage} alt="" loading="lazy" aria-hidden />
      <h2 className={styles.sectionHeading}>{sectionData.title}</h2>
      <div className={styles.cardContainer}>
        {sectionData.columns.map((item) => {
          return (
            <div className={styles.singleCard} key={item.id}>
              <div className={styles.iconContainer}>
                <img src={item.icon} alt="" aria-hidden={true} />
              </div>
              <div className={styles.textContainer}>
                <h6>{item.title}</h6>
                <p>{item.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThreeColWithHeader;
