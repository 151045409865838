import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "./context/Provider";
import { CustomToastProvider } from "./context/CustomToastContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <CustomToastProvider>
      <Provider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </Provider>
    </CustomToastProvider>
  </Router>
);

reportWebVitals();
