import { Box } from '@mui/system';
import React, { useState } from 'react';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { useEffect } from 'react';
import { axiosInstance } from 'src/axios';
import { Formik, Form } from 'formik';
import { Button, Grid, TextField, Skeleton, Typography, InputAdornment, Tooltip, Zoom, CircularProgress } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { routes } from 'src/helpers/routes';
import MuiPhoneInput from 'material-ui-phone-number';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { siteName, siteNames } from 'src/config';
import { usePathname } from 'src/components/hooks';
import { PrimaryButton } from 'src/components/Buttons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import moment from 'moment';

interface StudentData {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: any;
  middleName: string;
  healthCareNumber: string;
  allergies: string;
  dateOfBirth: string;
}

const Students = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = React.useRef(null);
  const toastConfig = React.useContext(CustomToastContext);
  const pathName = usePathname();
  const registerPath = pathName === routes.register.path ? true : false;
  const isSite2 = siteName?.includes(siteNames.site2);

  const [studentData, setStudentData] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const [initialValues, setInitialValues] = React.useState({
    _id: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: new Date(),
    middleName: '',
    healthCareNumber: '',
    allergies: '',
    dateOfBirth: ''
  });

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    await axiosInstance()
      .get(routes.students.api)
      .then(({ data: { data } }) => {
        const students = data?.map((student: any) => {
          return {
            ...student,
            allergies: student?.allergies?.join(', ') || ''
          };
        });
        setStudentData(students || []);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    values.allergies = values?.allergies?.split(/[, \n]+/).filter(Boolean);
    values.healthCareNumber = values?.healthCareNumber ? Number(values?.healthCareNumber) : null;
    if (values._id) {
      await axiosInstance()
        .put(routes.students.api, values)
        .then(({ data }) => {
          fetchData();
          toastConfig.setToastConfig({
            open: true,
            message: data.message,
            type: 'success'
          });
          setOpen(false);
          setIsLoading(false);
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsLoading(false);
        });
    } else {
      await axiosInstance()
        .post(routes.students.api, values)
        .then(({ data }) => {
          fetchData();
          toastConfig.setToastConfig({
            open: true,
            message: data.message,
            type: 'success'
          });
          setOpen(false);
          setIsLoading(false);
        })
        .catch((error) => {
          toastConfig.setToastConfig(error);
          setIsLoading(false);
        });
    }
  };

  const handleDelete = async (ids: any) => {
    await axiosInstance()
      .put(`${routes.students.api}/remove`, { ids: ids })
      .then(({ data }) => {
        fetchData();
        toastConfig.setToastConfig({
          open: true,
          message: data.message,
          type: 'success'
        });
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  return (
    <Box className="container" pt={3}>
      {registerPath ? <p className="students">Students</p> : <h5>Students</h5>}
      <Box pt={3} pb={3}>
        {registerPath ? (
          <>
            {!open && (
              <PrimaryButton
                variant="square"
                onClick={() => {
                  setOpen(true);
                  setInitialValues({
                    _id: null,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    dob: new Date(),
                    middleName: '',
                    healthCareNumber: '',
                    allergies: '',
                    dateOfBirth: ''
                  });
                }}
                type="submit"
              >
                Add Student
              </PrimaryButton>
            )}
          </>
        ) : (
          <>
            {!open && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                  setInitialValues({
                    _id: null,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    dob: new Date(),
                    middleName: '',
                    healthCareNumber: '',
                    allergies: '',
                    dateOfBirth: ''
                  });
                }}
                type="submit"
                color="primary"
              >
                Add Student
              </Button>
            )}
          </>
        )}

        {open ? (
          <Box pt={3}>
            <RenderForm
              isLoading={isLoading}
              isSite2={isSite2}
              initialValues={initialValues}
              formikRef={formikRef}
              handleSubmit={handleSubmit}
              setInitialValues={setInitialValues}
              setOpen={setOpen}
            />
          </Box>
        ) : (
          <Box pt={3}>
            {studentData ? (
              <>
                {studentData?.length === 0 ? (
                  pathName === routes.register.path ? (
                    <p className="no_student">Student not added yet!</p>
                  ) : (
                    <h6>Student not added yet!</h6>
                  )
                ) : (
                  studentData?.map((item: any, key: any) => {
                    return (
                      <Box key={key} border={1} borderColor="grey.300" p={2} mb={2}>
                        <Grid container onClick={() => {}} alignItems={'center'} justifyContent="space-between" spacing={2}>
                          <Grid item sx={{ wordBreak: 'break-word' }}>
                            <span>{`${item?.firstName} ${item?.lastName}`}</span>
                            <Typography variant="body2">{item?.email}</Typography>
                          </Grid>
                          <Grid item display={'flex'}>
                            <Button
                              variant="contained"
                              color="primary"
                              style={isSite2 ? { background: 'linear-gradient(90deg, #ffc000, #ffa512)' } : {}}
                              size="small"
                              onClick={() => {
                                setInitialValues({
                                  _id: item._id,
                                  firstName: item.firstName,
                                  lastName: item.lastName,
                                  email: item.email,
                                  phone: item.phone,
                                  dob: item.dob,
                                  middleName: item.middleName,
                                  healthCareNumber: item.healthCareNumber,
                                  allergies: item.allergies,
                                  dateOfBirth: item.dateOfBirth
                                });
                                setOpen(true);
                              }}
                            >
                              Edit
                            </Button>
                            <Box marginX={1} />
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              startIcon={<DeleteOutlineOutlinedIcon />}
                              onClick={() => {
                                handleDelete([item._id]);
                              }}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                )}
              </>
            ) : (
              [1, 2].map((item) => (
                <Box key={item} border={1} borderColor="grey.300" p={2} mb={2}>
                  <Grid container onClick={() => {}} alignItems={'center'} justifyContent="space-between" spacing={2}>
                    <Grid item>
                      <Skeleton width={'149px'} />
                      <Skeleton width={'200px'} />
                    </Grid>
                    <Grid item display={'flex'}>
                      <Skeleton width={'64px'} height={'50px'} /> <Skeleton sx={{ marginLeft: '10px' }} height={'50px'} width={'64px'} />
                    </Grid>
                  </Grid>
                </Box>
              ))
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Students;

const customStyles = {
  fields: {
    width: '100%',
    maxWidth: '500px'
  }
};

const RenderForm = ({
  isSite2,
  initialValues,
  formikRef,
  handleSubmit,
  setInitialValues,
  setOpen,
  isLoading
}: {
  isSite2: boolean;
  initialValues: any;
  formikRef: any;
  handleSubmit: any;
  setInitialValues: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}) => {
  const isErroredPhoneNumber = (phone: string) => {
    if (isSite2) {
      return false;
    }
    let simpleStr = phone.replaceAll(' ', '');
    simpleStr = simpleStr.replaceAll('(', '');
    simpleStr = simpleStr.replaceAll(')', '');
    simpleStr = simpleStr.replaceAll('-', '');
    const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

    if (!isValid) {
      return 'Phone number is not valid';
    }
    return false;
  };

  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

  const validate = (values: StudentData) => {
    return sleep(1000).then(() => {
      const errors: any = {};
      if (!values.firstName) {
        errors.firstName = 'Please enter first name';
      }
      if (!values.lastName) {
        errors.lastName = 'Please enter last name';
      }
      if (isErroredPhoneNumber(values.phone)) errors.phone = isErroredPhoneNumber(values.phone);

      if (!values.healthCareNumber && isSite2) {
        errors.healthCareNumber = 'Health Care Number is required';
      } else if (isSite2) {
        let str = `${values.healthCareNumber}`;
        str = str?.replaceAll(' ', '');
        str = str?.replaceAll('-', '');
        str = str?.replaceAll('_', '');
        const valid = /^\d+$/.test(str);
        const numberLength = str.length;
        if (numberLength !== 9) errors.healthCareNumber = 'Only 9 characters allowed';
        if (!valid) errors.healthCareNumber = 'Health Care Number can only contain numbers';
      }

      if (!values.dob && siteName?.includes(siteNames.site2)) {
        errors.dob = 'Date of birth is required';
      }
      // else if (siteName?.includes(siteNames.site2)) {
      //   const dob = moment(values.dob);
      //   const today = moment(new Date());
      //   const diff = today.diff(dob, 'years');
      //   if (diff < 10) errors.dob = 'Have to be 10+ to have student account.';
      // }

      return errors;
    });
  };

  return (
    // <Formik initialValues={initialValues} innerRef={formikRef} validate={validate} onSubmit={handleSubmit} validateOnMount validateOnChange={false}>
    <Formik initialValues={initialValues} innerRef={formikRef} validate={validate} onSubmit={handleSubmit}>
      {({ submitForm, values, errors, touched, setFieldValue }) => {
        return (
          <Form>
            {/* {console.log(values)} */}
            <Grid container spacing={2}>
              <Grid item sx={customStyles.fields}>
                <TextField
                  fullWidth
                  required={isSite2}
                  data-testid="firstName"
                  variant="outlined"
                  type="firstName"
                  placeholder="First Name"
                  label="First Name"
                  name="firstName"
                  size="small"
                  onChange={(e) => setFieldValue('firstName', e.target.value)}
                  value={values['firstName']}
                  error={touched['firstName'] && Boolean(errors['firstName'])}
                  helperText={touched['firstName'] && Boolean(errors['firstName']) && `${errors['firstName']}`}
                />
              </Grid>

              <Grid item sx={customStyles.fields}>
                <TextField
                  fullWidth
                  required={isSite2}
                  data-testid="lastName"
                  variant="outlined"
                  type="text"
                  placeholder="Last Name"
                  label="Last Name"
                  name="lastName"
                  size="small"
                  onChange={(e) => setFieldValue('lastName', e.target.value)}
                  value={values['lastName']}
                  error={touched['lastName'] && Boolean(errors['lastName'])}
                  helperText={touched['lastName'] && Boolean(errors['lastName']) && `${errors['lastName']}`}
                />
              </Grid>
              {isSite2 || (
                <Grid item sx={customStyles.fields}>
                  <TextField
                    fullWidth
                    required={!isSite2}
                    data-testid="email"
                    variant="outlined"
                    type="email"
                    placeholder="Email"
                    label="Email"
                    name="email"
                    size="small"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    value={values['email']}
                    error={touched['email'] && Boolean(errors['email'])}
                    helperText={touched['email'] && Boolean(errors['email']) && `${errors['email']}`}
                  />
                </Grid>
              )}
              {isSite2 || (
                <Grid item sx={customStyles.fields}>
                  <MuiPhoneInput
                    defaultCountry={'us'}
                    disableAreaCodes
                    countryCodeEditable={true}
                    enableLongNumbers={false}
                    fullWidth
                    variant="outlined"
                    type="phone"
                    required={siteName?.includes(siteNames.site1)}
                    label="Phone"
                    name="phone"
                    size="small"
                    dropdownClass="phone_dropdown"
                    onChange={(val: any) => setFieldValue('phone', val.toString())}
                    value={values['phone']}
                    error={touched['phone'] && Boolean(errors['phone'])}
                    helperText={touched['phone'] && Boolean(errors['phone']) && `${errors['phone']}`}
                  />
                </Grid>
              )}
              <Grid item sx={customStyles.fields}>
                <DesktopDatePicker
                  label={'Date of Birth'}
                  value={values.dob}
                  onChange={(newValue) => {
                    setFieldValue('dob', newValue);
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="dob"
                      size="small"
                      label="Date of Birth"
                      variant="outlined"
                      fullWidth
                      error={touched['dob'] && Boolean(errors['dob'])}
                      helperText={`${touched['dob'] && errors['dob'] ? errors['dob'] : ''}`}
                    />
                  )}
                />
              </Grid>
              {isSite2 && (
                <>
                  <Grid item sx={customStyles.fields}>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Please enter your 9 digit Alberta Healthcare Number" TransitionComponent={Zoom} placement="left">
                              <InfoOutlinedIcon sx={{ fontSize: '19px', cursor: 'pointer' }} />
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      required={isSite2}
                      data-testid="healthCareNumber"
                      variant="outlined"
                      type="healthCareNumber"
                      placeholder="Healthcare Number"
                      label="Healthcare Number"
                      name="healthCareNumber"
                      size="small"
                      onChange={(e) => setFieldValue('healthCareNumber', e.target.value)}
                      value={values['healthCareNumber']}
                      error={touched['healthCareNumber'] && Boolean(errors['healthCareNumber'])}
                      helperText={touched['healthCareNumber'] && Boolean(errors['healthCareNumber']) && `${errors['healthCareNumber']}`}
                    />
                  </Grid>

                  <Grid item sx={customStyles.fields}>
                    <TextField
                      fullWidth
                      data-testid="allergies"
                      variant="outlined"
                      type="text"
                      placeholder="Allergies"
                      label="Allergies"
                      name="allergies"
                      size="small"
                      multiline
                      minRows={3}
                      maxRows={4}
                      onChange={(e) => setFieldValue('allergies', e.target.value)}
                      value={values['allergies']}
                      error={touched['allergies'] && Boolean(errors['allergies'])}
                      helperText={touched['allergies'] && Boolean(errors['allergies']) && `${errors['allergies']}`}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12} container display={'flex'} justifyContent={'flex-end'}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={isSite2 ? { color: '#ffc000', borderColor: '#ffc000' } : {}}
                  onClick={() => {
                    setInitialValues({
                      _id: null,
                      firstName: '',
                      lastName: '',
                      email: '',
                      phone: '',
                      dob: new Date(),
                      middleName: '',
                      healthCareNumber: '',
                      allergies: '',
                      dateOfBirth: ''
                    });
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Box marginX={1} />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    submitForm();
                  }}
                  style={isSite2 ? { background: 'linear-gradient(90deg, #ffc000, #ffa512)' } : {}}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={'15px'} sx={{ color: 'white' }} /> : initialValues._id ? `Update` : `Add`}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
