import React from 'react';
import Site1AboutUs from 'src/components/Site1/AboutUs';
import Site2AboutUs from 'src/components/Site/AboutUs';
import { siteNames, siteName } from 'src/config';

const AboutUs = () => {
  if (siteName?.includes(siteNames.site1)) {
    return <Site1AboutUs />;
  } else {
    return <Site2AboutUs />;
  }
};

export default AboutUs;
