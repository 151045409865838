import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const CustomToast = (props: any) => {
  const { open, close, message, type, hideDuration = 6000, anchorOrigin = null } = props;

  return (
    <>
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={hideDuration}
          onClose={close}
          anchorOrigin={
            anchorOrigin
              ? anchorOrigin
              : {
                  vertical: 'top',
                  horizontal: 'center'
                }
          }
        >
          <Alert onClose={close} variant="filled" icon={false} severity={type} sx={{ color: type === 'success' && 'white' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

CustomToast.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  message: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  anchorOrigin: PropTypes.object
};

export default CustomToast;
