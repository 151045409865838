import { SET_USER, SET_BRAND, USER_LOADING, SET_NOTIFICATION } from './ActionTypes';

interface initialState {
  user: any;
  userLoading: any;
  token: any;
  notifications?: any;
}

export const initialState: initialState = {
  user: null,
  userLoading: false,
  token: localStorage.getItem('token'),
  notifications: null
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_BRAND:
      return { ...state, brand: action.payload };
    case USER_LOADING:
      return { ...state, userLoading: action.payload };
    case SET_NOTIFICATION:
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};

export default reducer;
