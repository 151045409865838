import React, { useState, useEffect, useRef, RefObject } from 'react';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { routes } from 'src/helpers/routes';
import { Courses } from 'src/components/Site1/interface';
import { useLocation } from 'react-router-dom';

export interface CourseDetailsInterface {
  _id?: string;
  images?: string[];
  icon?: string;
  heading?: string;
  text?: string;
  buttonText?: string;
  buttonLink?: string;
  schedule?: scheduleList[];
}

export interface scheduleList {
  _id?: string;
  startTime?: Date;
  endTime?: Date;
  startDate?: Date;
  endDate?: Date;
  instructor?: Instructor;
  blockDayNames?: string[];
  label?: string;
  information?: string;
  status?: boolean;
  createdBy?: AtedBy;
  updatedBy?: AtedBy;
  rate?: number;
  inactiveReason?: string;
}

export interface AtedBy {
  user?: string;
  data?: Date;
}

export interface Instructor {
  optionValue?: string;
  optionLabel?: string;
}

export const useCourses = (id: null | string = null) => {
  const toastConfig = React.useContext(CustomToastContext);
  const [courses, setCourses] = useState<CourseDetailsInterface[]>(null);

  const apiUrl = '/courses';
  const fetchData = () => {
    axiosInstance()
      .get(apiUrl)
      .then(({ data: { data } }) => {
        const courseData: CourseDetailsInterface[] = [];
        data.map((item: Courses) => {
          // console.log(data);
          if (id && id === item._id) {
            const obj: CourseDetailsInterface = {};
            obj._id = item._id;
            obj.heading = item.courseName;
            obj.text = item.details;
            obj.schedule = item.schedule;
            obj.images = item.images;
            obj.icon = item.icon;
            obj.buttonLink = `${routes.courses.path}?courseName=${item.courseName}`;
            obj.buttonText = 'Book your classes';
            courseData.push(obj);
            return;
          }
          if (!id) {
            const obj: CourseDetailsInterface = {};
            obj._id = item._id;
            obj.heading = item.courseName;
            obj.text = item.details;
            obj.schedule = item.schedule;
            obj.images = item.images;
            obj.icon = item.icon;
            obj.buttonLink = `${routes.classDetails.path}/${item._id}`;
            obj.buttonText = 'LEARN MORE';
            courseData.push(obj);
          }
        });
        setCourses(courseData);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return courses;
};

export const useClickdOutside = (ref: any) => {
  const [clickedOutside, setClickedOutside] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return clickedOutside;
};

export const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

export const useScrollDirection = (threshold: number) => {
  const differenct = (a: number, b: number) => {
    return Math.abs(a - b);
  };
  const blocking = React.useRef(false);
  const prevScrollY = React.useRef(0);

  const isBrowser = typeof window !== `undefined`;
  const [scrollPosition, setScrollPosition] = useState({ position: 0, difference: 0, direction: 'up' });

  const handleScroll = () => {
    if (isBrowser) {
      const position = window.pageYOffset;
      const scrollY = window.pageYOffset;
      if (Math.abs(scrollY - prevScrollY.current) >= threshold) {
        const newScrollDirection = scrollY > prevScrollY.current ? 'down' : 'up';
        setScrollPosition({ position: position, difference: differenct(prevScrollY.current, scrollY), direction: newScrollDirection });
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }
      blocking.current = false;
    }
  };

  const onScroll = () => {
    if (!blocking.current) {
      blocking.current = true;
      window.requestAnimationFrame(handleScroll);
    }
  };

  useEffect(() => {
    prevScrollY.current = window.pageYOffset;
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return scrollPosition;
};

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;
    if (!hasIOSupport || frozen || !node) return;
    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);
    return () => observer.disconnect();
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
};
