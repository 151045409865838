import React from 'react';
import { Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { siteName, siteNames } from 'src/config';

function CommonSkeleton({ lenArray }: any) {
  const isSite2 = siteName?.includes(siteNames.site2);
  return (
    <Grid container spacing={2} style={{ backgroundColor: isSite2 && 'rgb(240,240,240)' }}>
      {lenArray?.map((i: any, index: any) => (
        <Grid item sm={6} md={6} key={index}>
          <Skeleton variant="text" width="100px" height="16px" />
          <Box marginY={1} />
          <Skeleton width="100%" height="50px" />
        </Grid>
      ))}
    </Grid>
  );
}
export default CommonSkeleton;
