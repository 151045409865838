import React from 'react';
import styles from './index.module.scss';
import { buttonType, buttonStyle } from 'src/components/Buttons';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/helpers/routes';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { Formik, Form } from 'formik';
import { TextField, CircularProgress } from '@mui/material';

interface inputProps {
  sectionData: {
    head?: string;
    subHeading?: string;
    image?: string;
    description?: string;
  };
}

interface submitData {
  email: string;
}

const StayTuned: React.FC<inputProps> = ({ sectionData }) => {
  const navigate = useNavigate();
  const Button = buttonStyle(buttonType.secondary);
  const [isLoading, setIsLoading] = React.useState(false);
  const toastConfig = React.useContext(CustomToastContext);

  const handleSubmit = async (values: submitData) => {
    setIsLoading(true);
    await axiosInstance()
      .get(`/subscription/email-subscribe?email=${values?.email || ''}`)
      .then((res) => {
        setIsLoading(false);

        toastConfig.setToastConfig({
          open: true,
          message: 'Subscribed Successfully',
          type: 'success'
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toastConfig.setToastConfig(err);
      });
  };

  const validateForm = (values: submitData) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  return (
    <div className={styles.main}>
      <div className="container">
        <div className={styles.mainContainer}>
          <div className={`${styles.col} ${styles.leftCol}`}>
            <img src={sectionData.image} alt="" aria-hidden="true" loading="lazy" />
          </div>
          <div className={`${styles.col} ${styles.rightCol}`}>
            <h6 className={styles.subheading}>{sectionData.subHeading}</h6>
            <h2 className={styles.heading}>{sectionData.head}</h2>
            <p className={styles.description}>{sectionData.description}</p>
            <Formik
              initialValues={{
                email: ''
              }}
              validate={validateForm}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  await handleSubmit(values);
                  resetForm();
                } catch (error) {
                  console.error(error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form>
                  <TextField
                    fullWidth
                    data-testid="email"
                    variant="outlined"
                    type="email"
                    placeholder="Your Email here"
                    name="email"
                    size="small"
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    value={values['email']}
                    error={touched['email'] && Boolean(errors['email'])}
                    className={styles.input}
                  />
                  {touched['email'] && errors['email'] && <div className={styles.healperText}>{errors['email']}</div>}

                  <Button type="submit" disabled={isLoading} onClick={submitForm} className={styles.button}>
                    {isLoading ? <CircularProgress size={19} color="inherit" /> : 'Subscribe'}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StayTuned;
