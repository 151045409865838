import React from 'react';
import styles from './index.module.scss';
import logo from '../assets/Logo.png';
import { social, links } from './data';
import { Link } from 'react-router-dom';
import star from '../assets/Star.png';
import { linkType } from './data';
import { routes } from 'src/helpers/routes';

const RenderLink = ({
  type = '',
  link = '',
  text = '',
  key = 1,
  className = ''
}: {
  type: string;
  link: string;
  text: any;
  key: any;
  className?: string;
}) => {
  if (type === linkType.external) {
    return (
      <a href={link} className={className} target="_blank" key={key}>
        {text}
      </a>
    );
  }
  if (type === linkType.internal) {
    return (
      <Link to={link} key={key} className={className}>
        {text}
      </Link>
    );
  }
  if (type === linkType.text) {
    return (
      <p key={key} className={className}>
        {text}
      </p>
    );
  }
};

const Footer = () => {
  return (
    <div className={styles.bg}>
      <div className="container2">
        <div className={styles.topSection}>
          <div className={styles.logoSection}>
            <Link to={routes.home.path} className={styles.logoContainer}>
              <img src={logo} alt="Sikh National Education Instutute logo" loading="lazy" />
              <div className={styles.brandName}>
                <h6 className="inter">Sikh National Education Institute</h6>
                <p className="inter">(SNEI)</p>
              </div>
            </Link>
            <div className={styles.socialContainer}>
              <p>Follow us</p>
              <div className={styles.linkSection}>
                {social.map((item: any, key: any) => (
                  <RenderLink key={key} link={item.link} text={item.icon} type={item.type} />
                ))}
              </div>
            </div>
          </div>
          <div className={styles.links}>
            <h6 className={styles.linkHead}>{links.institute.heading}</h6>
            <div className={styles.linkContainer}>
              {links.institute.links.map((item, key) => (
                <RenderLink className={styles.linkStyle} key={key} link={item.link} text={item.title} type={item.type} />
              ))}
            </div>
            <div className={styles.links}>
              <h6 className={styles.linkHead}>{links.contactUs.heading}</h6>
              <div className={styles.linkContainer}>
                {links.contactUs.links.map((item, key) => {
                  return (
                    <RenderLink
                      key={key}
                      link={item.link}
                      text={
                        <>
                          {item.icon} <span className={styles.linkStyle}> {item.title}</span>
                        </>
                      }
                      type={item.type}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottomSection}>
          <p>© 2021 All Rights Reserved</p>
        </div>
      </div>
      <div className={styles.badge}>
        <img src={star} alt="" aria-hidden="true" />
        <p>RESERVE YOUR TIME NOW BEFORE SPACES FILL UP!</p>
      </div>
    </div>
  );
};

export default Footer;
