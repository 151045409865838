import React from 'react';
import styles from './index.module.scss';

interface inputProps {
  cardData: any;
}

const ThreeColumnCard: React.FC<inputProps> = ({ cardData }) => {
  return (
    <div className={`${styles.background}`}>
      <div className={`container ${styles.cardContainer}`}>
        {cardData?.map((item: any, key: any) => {
          const Icon = item.icon || <></>;
          const style = {
            '--bg_color': item.iconBgColor,
            '--icon-color': item.iconColor
          } as React.CSSProperties;

          return (
            <div key={item.id} className={styles.singleCard}>
              <div className={styles.iconContainer} style={style}>
                <Icon />
              </div>
              <div className={styles.textContainer}>
                <h6>{item.cardHead}</h6>
                <p>
                  <small>{item.cardContent}</small>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThreeColumnCard;
