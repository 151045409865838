import React from 'react';
import { AnimationContainer } from 'src/components/Animation';
import ComingSoon from 'src/components/Site/ComingSoon';

const Volunteer = () => {
  return (
    <AnimationContainer direction="fade" className="container">
      <ComingSoon />
    </AnimationContainer>
  );
};

export default Volunteer;
