import Header from "./Site/Header";
import Footer from "./Site/Footer";
import { siteName, siteNames } from "src/config";
import { Fragment } from "react";
import Site1Header from "./Site1/Header";
import Site1Footer from "./Site1/Footer";

const Layout = ({ children }: { children: any }) => {
  return (
    <>
      {siteName?.includes(siteNames.site1) ? (
        <Fragment>
          <Site1Header />
          <div style={{ minHeight: "60vh" }}>{children}</div>
          <Site1Footer />
        </Fragment>
      ) : (
        <Fragment>
          <Header />
          <div style={{ minHeight: "60vh" }}>{children}</div>
          <Footer />
        </Fragment>
      )}
    </>
  );
};

export default Layout;
