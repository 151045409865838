import { Box, TextField, Typography, Button, CircularProgress, Link as MuiLink } from '@mui/material';
import { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { Link } from 'react-router-dom';
import { siteName, siteNames } from 'src/config';
import site1Logo from 'src/components/Site1/assets/Logo.png';
import siteLogo from 'src/components/Site/assets/logo/Logo.svg';
import { buttonStyle, buttonType } from 'src/components/Buttons';
import { routes } from 'src/helpers/routes';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { AnimationContainer } from 'src/components/Animation';

const logo = () => {
  if (siteName?.includes(siteNames.site1)) {
    return site1Logo;
  } else if (siteName?.includes(siteNames.site2)) {
    return siteLogo;
  } else {
    return siteLogo;
  }
};

const ResetButton = () => {
  if (siteName?.includes(siteNames.site1)) {
    return buttonStyle(buttonType.yellowButton);
  } else if (siteName?.includes(siteNames.site2)) {
    return buttonStyle(buttonType.primary);
  } else {
    return buttonStyle(buttonType.primary);
  }
};

interface ForgetPasswordData {
  email: string;
}

const ForgetPassword = ({ className = '' }: { className?: string }) => {
  const toastConfig = useContext(CustomToastContext);
  const ButtonToDisplay = ResetButton();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: ''
  });

  const handleSubmit = async (values: ForgetPasswordData) => {
    axiosInstance()
      .post('/users/forget-password', {
        email: values.email
      })
      .then(({ data }) => {
        toastConfig.setToastConfig({
          open: true,
          message: data?.message,
          type: 'success'
        });
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
      });
  };

  const validateForm = (values: ForgetPasswordData) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  return (
    <AnimationContainer direction="fade" className={`${siteName?.includes(siteNames.site2) ? styles.fullscreen_login : 'container2 '}`}>
      {siteName?.includes(siteNames.site2) && (
        <div className={`container `}>
          <Button
            className={styles.backButton}
            sx={{ color: 'white', textTransform: 'capitalize' }}
            onClick={() => navigate('/')}
            startIcon={<FiArrowLeftCircle />}
          >
            Return
          </Button>
        </div>
      )}
      <Box
        className={`${styles.container} ${className}`}
        style={{ minHeight: siteName?.includes(siteNames.site2) ? 'calc(100vh - 123px)' : '705px' }}
      >
        <Box className={`${siteName?.includes(siteNames.site2) ? styles.roundedLoginContainer : ''}`}>
          <Box className={` ${siteName?.includes(siteNames.site2) ? styles.loginInner : styles.loginContainer}`}>
            <img
              src={logo()}
              alt=""
              style={{
                maxWidth: '161px',
                margin: '0px auto 15px',
                maxHeight: '80px',
                objectFit: 'contain'
              }}
            />
            {siteName?.includes(siteNames.site2) ? (
              <Typography className={styles.logoTextGv} mb={4}>
                Forget Password
              </Typography>
            ) : (
              <Typography className={styles.logoText} mb={1}>
                Forget Password
              </Typography>
            )}

            <Box mt={'10px'}>
              <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
                {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                  <Form>
                    <Box mb={3} sx={{ textAlign: 'right' }}>
                      <TextField
                        fullWidth
                        label="Email"
                        data-testid="email"
                        variant="outlined"
                        type="email"
                        placeholder="example@gmail.com"
                        name="email"
                        size="small"
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        value={values['email']}
                        error={touched['email'] && Boolean(errors['email'])}
                        className={styles.fields}
                      />
                      <MuiLink component={Link} to={routes.login.path} className={styles.forgotPassword} mt={1} textAlign="right">
                        Back To Login Page
                      </MuiLink>
                      <Box mt={2}>
                        <ButtonToDisplay
                          fullWidth
                          type="submit"
                          variant="rounded"
                          disabled={isSubmitting}
                          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                        >
                          Submit
                        </ButtonToDisplay>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
      {siteName?.includes(siteNames.site2) && (
        <>
          <footer>
            <div className="container">
              <p className="fw-3 lh-1">Copyright @ 2022 Mai Bhago Charity. All Rights Reserved</p>
              <div>
                <Link to="#" className="fw-3 lh-1">
                  Terms of Use
                </Link>{' '}
                |{' '}
                <Link to="#" className="fw-3 lh-1">
                  Privacy Policy
                </Link>{' '}
                |{' '}
                <Link to="#" className="fw-3 lh-1">
                  Land Acknowledgment
                </Link>
              </div>
            </div>
          </footer>
        </>
      )}
    </AnimationContainer>
  );
};

export default ForgetPassword;
