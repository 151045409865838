import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState, useEffect, useContext, Fragment } from "react";
import { axiosInstance } from "src/axios";
import { CustomToastContext } from "src/context/CustomToastContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PAYMENT_TYPES } from "src/helpers/helper";
import { routes } from "src/helpers/routes";
import queryString from "query-string";

const PaymentStatus = () => {

    const toastConfig = React.useContext(CustomToastContext);
    const navigate = useNavigate();
    const { sessionId, status, paymentType }: any = queryString.parse(window.location.search);

    useEffect(() => {
        if (paymentType === "cart") {
            if (status === "success") {
                handlePlaceOrder();
            }
            else {
                axiosInstance().put(`${routes.session.api}/remove/${sessionId}`)
                    .then(({ data: data }) => {
                        navigate(`${routes.courses.path}`, { replace: true });
                    })
                    .catch((err) => {
                        toastConfig.setToastConfig(err);
                    });
            }
        }
        else {
            if (status === "success") {
                handlePlaceDonation();
            }
            else {
                navigate(`${routes.donation.path}`, { replace: true });
            }
        }
    }, [sessionId]);

    const handlePlaceOrder = () => {
        const data: any = {};
        data.sessionId = sessionId;
        data.paymentType = PAYMENT_TYPES.online;
        axiosInstance()
            .post("/orders", data)
            .then(({ data: data }) => {
                navigate(`${routes.orders.path}/${data?.data?.orderId}`, {
                    replace: true,
                });
            })
            .catch((err) => {
                toastConfig.setToastConfig(err);
            });
    };

    const handlePlaceDonation = () => {
        const data: any = {};
        data.sessionId = sessionId;
        axiosInstance().post("/donation/order", data)
            .then(({ data: data }) => {
                navigate(`${routes.donation.path}/details/${data?.data?.orderId}`, { replace: true });
            })
            .catch((err) => {
                toastConfig.setToastConfig(err);
            });
    };

    return (
        <Box className="container">
            <Box pt={3} sx={{ textAlign: "center" }}>
                <h6>Please Wait...</h6>
                <span>Don't close or refresh the page</span>
            </Box>
        </Box>
    );
};

export default PaymentStatus;
