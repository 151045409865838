import React from "react";
import styles from "./index.module.scss";
import { content } from "./data";

const index = ({ bg = "#FFC400", accent = "#3A3A3A" }) => {
  const style = { "--bg": bg, "--accent": accent } as React.CSSProperties;

  return (
    <div className={styles.bg} style={{ ...style }}>
      <div className="container2">
        <div className={styles.section}>
          <h2>GET IN TOUCH</h2>
          <div className={styles.columns}>
            {content.map((item, key) => {
              return (
                <div key={key} className={styles.singleCol}>
                  {item.icon}
                  <div className={styles.textContainer}>
                    <h5>{item.head}</h5>
                    <div className={styles.Links}>
                      {item.links.length > 0 &&
                        item.links.map((linkData: any, key: any) => {
                          return linkData.type === "link" ? (
                            <a
                              key={key}
                              href={linkData.link}
                              className={styles.link}
                            >
                              {linkData.text}
                            </a>
                          ) : (
                            <p key={key} className={styles.link}>
                              {linkData.text}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
