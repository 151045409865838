import React from 'react';
import styles from './index.module.scss';

import { PrimaryButton } from 'src/components/Buttons';
import { Formik, Form } from 'formik';
import { Grid, Box, TextField, CircularProgress, useMediaQuery } from '@mui/material';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { axiosInstance } from 'src/axios';
import MuiPhoneInput from 'material-ui-phone-number';
import { siteName, siteNames } from 'src/config';

const FORM_SPACING = { xs: 2, sm: 3 };

interface inputProps {
  data: dataType;
}
interface dataType {
  formHeading: string;
  text: string;
  submitButtonText: string;
  iframeSrc: string;
}

interface enquiryData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  inquiry: string;
}

const phoneInputProps = () => {
  if (siteName.includes(siteNames.site2)) {
    return {
      excludeCountries: ['us'],
      defaultCountry: 'ca'
    };
  } else {
    return {
      defaultCountry: 'us'
    };
  }
};

export const ContactFormAndMap = ({ data }: inputProps) => {
  const toastConfig = React.useContext(CustomToastContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const mobileDevices = useMediaQuery('(max-width: 767px)');

  const handleSubmit = async (values: enquiryData, resetForm: any) => {
    setIsLoading(true);
    axiosInstance()
      .post('/contactus', {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        inquiry: values.phone
      })
      .then(({ data: { data } }) => {
        toastConfig.setToastConfig({
          open: true,
          message: 'Submitted Successfully',
          type: 'info'
        });
        resetForm({ values: '' });
        setIsLoading(false);
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setIsLoading(false);
      });
  };

  const validateForm = (values: enquiryData) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = 'First Name is required';
    }
    if (!values.lastName) {
      errors.lastName = 'Last Name is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else {
      let simpleStr = values?.phone.replaceAll(' ', '');
      simpleStr = simpleStr.replaceAll('(', '');
      simpleStr = simpleStr.replaceAll(')', '');
      simpleStr = simpleStr.replaceAll('-', '');
      const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

      if (!isValid) {
        errors.phone = 'Phone number is not valid';
      }
    }
    if (!values.inquiry) {
      errors.inquiry = 'Inquiry is required';
    }
    return errors;
  };

  return (
    <section className={`${styles.main} ${mobileDevices && 'container2'}`}>
      <div className={styles.responsiveMap}>
        <iframe
          src={data.iframeSrc}
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen={false}
          aria-hidden={false}
          tabIndex={0}
          className={styles.iframe}
        />
      </div>
      <div className={styles.mainContainer}>
        <div className={`${!mobileDevices && 'container2'}`}>
          <div className={styles.formContainer}>
            <div className={styles.formHead}>
              <h2 className="inter">{data.formHeading}</h2>
              <p className="inter">{data.text}</p>
            </div>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                inquiry: ''
              }}
              validate={validateForm}
              onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
            >
              {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form className={styles.form}>
                  <Grid container spacing={FORM_SPACING}>
                    <Grid item xs={12}>
                      <Grid container spacing={FORM_SPACING}>
                        <Grid item flexGrow={1} minWidth={'250px'}>
                          <TextField
                            fullWidth
                            data-testid="firstName"
                            variant="outlined"
                            type="text"
                            required
                            placeholder="First Name"
                            label="First Name"
                            name="firstName"
                            size="small"
                            onChange={(e) => setFieldValue('firstName', e.target.value)}
                            value={values['firstName']}
                            error={touched['firstName'] && Boolean(errors['firstName'])}
                            helperText={touched['firstName'] && errors['firstName']}
                          />
                        </Grid>
                        <Grid item flexGrow={1} minWidth={'250px'}>
                          <TextField
                            fullWidth
                            data-testid="lastName"
                            variant="outlined"
                            type="text"
                            required
                            placeholder="Last Name"
                            label="Last Name"
                            name="lastName"
                            size="small"
                            onChange={(e) => setFieldValue('lastName', e.target.value)}
                            value={values['lastName']}
                            error={touched['lastName'] && Boolean(errors['lastName'])}
                            helperText={touched['lastName'] && errors['lastName']}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        data-testid="email"
                        variant="outlined"
                        type="email"
                        required
                        label="Email"
                        placeholder="Email"
                        name="email"
                        size="small"
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        value={values['email']}
                        error={touched['email'] && Boolean(errors['email'])}
                        helperText={touched['email'] && errors['email']}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPhoneInput
                        {...phoneInputProps()}
                        disableAreaCodes
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        fullWidth
                        variant="outlined"
                        type="phone"
                        required
                        label="Phone"
                        name="phone"
                        size="small"
                        onChange={(val) => setFieldValue('phone', val.toString())}
                        value={values['phone']}
                        error={touched['phone'] && Boolean(errors['phone'])}
                        helperText={touched['phone'] && errors['phone']}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        data-testid="inquiry"
                        variant="outlined"
                        type="inquiry"
                        required
                        label="Inquiry"
                        placeholder="Leave a message"
                        name="inquiry"
                        size="small"
                        onChange={(e) => setFieldValue('inquiry', e.target.value)}
                        value={values['inquiry']}
                        error={touched['inquiry'] && Boolean(errors['inquiry'])}
                        helperText={touched['inquiry'] && errors['inquiry']}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={FORM_SPACING}>
                    <PrimaryButton
                      fullWidth
                      type="submit"
                      disabled={isLoading}
                      endIcon={isLoading ? <CircularProgress size={20} color={'inherit'} /> : <></>}
                    >
                      {data.submitButtonText}
                    </PrimaryButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};
