import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Theme, Typography } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { FiMinimize2, FiMaximize2 } from 'react-icons/fi';
import { isMobile, isTablet } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
    backgroundColor: theme.palette.primary.main
    // borderBottom: `1px solid #daf5ff`
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    color: theme.palette.grey[500]
  },
  closeIcon: {
    color: theme.palette.primary.dark
  },
  dialogTitle: {
    fontSize: '1.2rem'
  }
}));

function CustomDialogHeader({
  title = '',
  onClose = () => {},
  showManimizeMaximize = false,
  showRequiredLabel = true,
  isMinimized = true,
  onMinimizeMaximize = () => {}
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <MuiDialogTitle className={classes.root}>
        <Typography sx={{ color: 'white' }} className={`${classes.dialogTitle} title-layout text-truncate`}>
          {title}
        </Typography>

        <div className={`${classes.closeButton} close`}>
          {showRequiredLabel && (
            <span
              style={{
                color: 'white',
                borderBottom: 'none',
                fontSize: 12,
                marginRight: '10px'
              }}
            >
              * Required Fields
            </span>
          )}
          {showManimizeMaximize && !(isMobile || isTablet) && (
            <IconButton sx={{ color: 'white' }} aria-label="close" onClick={onMinimizeMaximize} size="small" className="mr-2">
              {isMinimized ? <FiMaximize2 /> : <FiMinimize2 />}
            </IconButton>
          )}
          {onClose && (
            <IconButton sx={{ color: 'white' }} aria-label="close" onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </MuiDialogTitle>
    </React.Fragment>
  );
}

CustomDialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  isMinimized: PropTypes.bool,
  onMinimizeMaximize: PropTypes.func,
  showManimizeMaximize: PropTypes.bool
};

export default CustomDialogHeader;
