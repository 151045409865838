import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo.png';
import styles from './index.module.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileMenu from 'src/components/Site/Header/ProfileMenu';
import { routes } from 'src/helpers/routes';
import { useData } from 'src/context/Provider';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  SwipeableDrawer,
  IconButton,
  Button,
  Badge,
  Popover,
  Typography,
  Grid,
  Menu,
  Box,
  Divider
} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';
import { YellowButton } from 'src/components/Buttons';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

// import Notification from '../../Notification';

const DRAWER_WIDTH = 300;

const Header = () => {
  const {
    state: { user }
  }: any = useData();

  const LoginSignup = () => {
    return (
      <div className={styles.loginSignup}>
        {!user ? (
          <>
            <Link to={routes.login.path}>Login</Link>
            <Link to={routes.register.path}>Signup</Link>
          </>
        ) : (
          <>
            <ProfileMenu user={user} />
          </>
        )}
      </div>
    );
  };

  const links = [
    { path: routes.home.path, title: routes.home.title },
    { path: routes.aboutUs.path, title: routes.aboutUs.title },
    { path: routes.contactUs.path, title: routes.contactUs.title },
    { path: `${routes.donation.path}`, title: routes.donation.title },
    { path: `${routes.donation.path}/?donateTo=6458bac462edebaf48371d99`, title: 'Hemkunt Foundation' },
    user && { path: `${routes.payOnline.path}`, title: routes.payOnline.title }
  ];

  const Links = () => {
    return (
      <div className={styles.desktopLinksContainer}>
        {links.map((item) => item && <Link to={item.path} dangerouslySetInnerHTML={{ __html: item.title }}></Link>)}
      </div>
    );
  };

  const LinksFormMob = () => {
    const navigate = useNavigate();
    return (
      <>
        {links.map(
          (item) =>
            item && (
              <Link to={item.path} className={styles.mobileLinks}>
                <ListItem button>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            )
        )}

        <div className={styles.buttonContainer}>
          <YellowButton endIcon={<FiArrowRight />} onClick={() => navigate(routes.courses.path)} style={{ marginInline: 'auto', display: 'block' }}>
            Book your classes
          </YellowButton>
        </div>
      </>
    );
  };

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const mobileDisplay = useMediaQuery('(max-width: 998px)');

  return (
    <div className={styles.bg}>
      <div className="container2" style={{ maxWidth: '1351px' }}>
        <div className={styles.mainContainer}>
          <div className={styles.logoContainer}>
            <Link to={'/'} className={styles.logo}>
              <img src={logo} alt="Sikh National Education Instutute logo" loading="lazy" />
            </Link>
            <Link to={'/'} className={`${styles.textLogo}`}>
              <h6 className="inter">Sikh National Education Institute</h6>
              <p className="inter">(SNEI)</p>
            </Link>
          </div>

          {!mobileDisplay ? (
            <div className={`${styles.menu} `}>
              <Links />
              {/* <div className={styles.input}>
                <input type="text" placeholder="Search..." />
                <AiOutlineSearch className={styles.searchIcon} />
              </div> */}
              <div>{/* <Notification /> */}</div>
              <LoginSignup />
            </div>
          ) : (
            <>
              <div>
                <div>{/* <Notification /> */}</div>
                <LoginSignup />
                <Button onClick={() => setIsDrawerOpen(true)} sx={{ minWidth: '38px' }}>
                  <ReorderIcon sx={{ color: 'var(--primary)' }} />
                </Button>
              </div>
              <SwipeableDrawer
                anchor={'right'}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                onOpen={() => setIsDrawerOpen(true)}
                sx={{ zIndex: 999999999, width: `${DRAWER_WIDTH}px` }}
              >
                <div style={{ padding: '10px 0 20px' }} className={styles.bg}>
                  <IconButton aria-label="delete" size="large" onClick={() => setIsDrawerOpen(false)} sx={{ color: 'var(--primary)' }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </div>
                {/* <div className={`${styles.input} ${styles.inputDrawer}`}>
                  <input type="text" placeholder="Search..." />
                  <AiOutlineSearch className={styles.searchIcon} />
                </div> */}

                <LinksFormMob />
                <div style={{ width: `${DRAWER_WIDTH}px` }} className="header_client_sidebar"></div>
              </SwipeableDrawer>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
