import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import charityLogo from 'src/components/Site/assets/images/charityLogo.jpg';
import sitelogo from 'src/components/Site/assets/logo/Logo.jpg';
import { nanoid } from 'nanoid';
import { routes } from 'src/helpers/routes';

import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { TfiFacebook } from 'react-icons/tfi';

export const footerData = {
  siteLogo: sitelogo,
  charityLogo: charityLogo,
  columns: [
    {
      id: nanoid(),
      title: 'Classes',
      items: [
        { id: nanoid(), path: `${routes.classes.path}`, title: 'Gurmat Classes', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.courses.path}?courseName=Kiddie Sangat`, title: 'Kiddie Sangat', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/IELTS`, title: 'IELTS', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/Tutoring`, title: 'Tutoring', type: 'internal', icon: '' }
      ]
    },
    {
      id: nanoid(),
      title: 'Programs',
      items: [
        { id: nanoid(), path: `${routes.programs.path}/Gurmat-Camps`, title: 'Gurmat Camps', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/Field-Trips`, title: 'Field Trips', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/Youth-Darbars`, title: 'Youth Darbars', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/Sports-Programs`, title: 'Sports Programs', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/Wellness-Programs`, title: 'Wellness Programs', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.programs.path}/Sangat-Programs`, title: 'Sangat Programs', type: 'internal', icon: '' }
      ]
    },
    {
      id: nanoid(),
      title: 'Resources',
      items: [
        { id: nanoid(), path: `${routes.gvResources.path}/Public-Calendar`, title: 'Public Calendar', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.gvResources.path}/Pictures`, title: 'Pictures', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.gvResources.path}/Sikhi-Resources`, title: 'Sikhi Resources', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.gvResources.path}/Akaali-Store`, title: 'Akaali Store', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.gvResources.path}/myGV`, title: 'myGV', type: 'internal', icon: '' },
        { id: nanoid(), path: `${routes.gvResources.path}/Google-Classroom`, title: 'Google Classroom', type: 'internal', icon: '' }
      ]
    },
    {
      id: nanoid(),
      title: 'Contact Us',
      items: [
        {
          id: nanoid(),
          path: 'https://goo.gl/maps/g1CKPNG5p7pC35Ug8',
          title: 'Unit #4166, 3406 52 Ave NE Calgary, AB T3G 4M9',
          icon: <LocationOnIcon />,
          type: 'external'
        },
        { id: nanoid(), path: 'tel:+14034212000', title: '+1 (403) 421-2000', icon: <CallIcon />, type: 'external' },
        { id: nanoid(), path: 'mailto:info@guruprasadvidyala.com', title: 'info@guruprasadvidyala.com', icon: <EmailIcon />, type: 'external' },
        { id: nanoid(), path: '#', title: 'Donate', icon: '', type: 'button' }
      ]
    }
  ],
  socialMedia: [
    {
      id: nanoid(),
      icon: <InstagramIcon />,
      path: 'https://www.instagram.com/gurprasadvidyala/'
    },
    { id: nanoid(), icon: <TfiFacebook />, path: 'https://www.facebook.com/gurprasadvidyala' },
    { id: nanoid(), icon: <WhatsAppIcon />, path: 'https://wa.me/14034212000' }
  ],
  copyright: {
    text: 'Copyright @ Mai Bhago Charity',
    links: [
      { id: nanoid(), path: '#', title: 'Terms of Use', type: 'internal', icon: '' },
      { id: nanoid(), path: '#', title: 'Privacy Policy', type: 'internal', icon: '' },
      { id: nanoid(), path: '#', title: 'Land Acknowledgment', type: 'internal', icon: '' }
    ]
  }
};
