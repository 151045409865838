import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PrimaryButton } from '../../Buttons';
import { HeaderLinksInterface, HeaderLinkChildInterface } from 'src/components/Site/interface';
import { useData } from 'src/context/Provider';
import { useClickdOutside, usePathname } from 'src/components/hooks';

const animationSpeed = 500; //in ms

interface inpurPropInterFace {
  linkDetails: HeaderLinksInterface;
}

const HeaderLink: React.FC<inpurPropInterFace> = ({ linkDetails }) => {
  const pathName = usePathname();

  const menuRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLLIElement>(null);
  const {
    state: { user }
  }: any = useData();

  const [isClicked, setIsClicked] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [currentCourse, setCurrenCourse] = React.useState(linkDetails?.items[0]?.linkText);
  const clickedOutside = useClickdOutside(listRef);

  useEffect(() => {
    closeMenu();
  }, [pathName]);

  const closeMenu = () => {
    if (isClicked) {
      if (menuRef?.current) {
        const menu = menuRef?.current;
        menu.style.height = '0px';
        const timer = setTimeout(() => {
          setIsClicked(false);
        }, animationSpeed);
      }
    }
  };

  useEffect(() => {
    if (isClicked) {
      if (menuRef?.current) {
        const menu = menuRef?.current;
        menu.style.zIndex = '5';
        menu.style.height = '0px';
        const timer = setTimeout(() => {
          setIsClicked((prev) => !prev);
        }, animationSpeed);
        return () => clearTimeout(timer);
      }
    }
  }, [clickedOutside]);

  useEffect(() => {
    if (menuRef?.current) {
      const menu = menuRef?.current;
      const menuHight = menuRef?.current?.clientHeight;
      menu.style.height = '0px';
      menu.style.opacity = '1';
      menu.style.transition = `height ${animationSpeed}ms ease-in-out`;
      menu.style.overflow = 'hidden';
      menu.style.zIndex = '999999';
      const timer = setTimeout(() => {
        menu.style.height = `${menuHight}px`;
      }, 10);
      return () => clearTimeout(timer);
    }
    setCurrentIndex(0);
  }, [menuRef, isClicked]);

  return (
    <li
      onClick={() => {
        if (linkDetails.items?.length) {
          setIsClicked(true);
        }
      }}
      ref={listRef}
      className={`${isClicked ? 'active_item' : ''} ${
        linkDetails.link.split('/')[1] == pathName.split('/')[1] && pathName.split('/')[1] !== '' ? 'active_link' : null
      }`}
    >
      {linkDetails.linkType === 'internal' ? (
        <>
          <Link
            to={linkDetails.items.length > 0 ? '#' : linkDetails.link}
            className="main_link"
            onClick={() => closeMenu()}
            style={{ justifyContent: linkDetails.items?.length > 0 ? '' : 'center' }}
          >
            {linkDetails.linkText}
            {linkDetails.items?.length > 0 ? (
              !isClicked ? (
                <KeyboardArrowDownIcon style={{ textAlign: 'right' }} />
              ) : (
                <KeyboardArrowUpIcon style={{ textAlign: 'right' }} />
              )
            ) : null}
          </Link>
        </>
      ) : (
        <>
          <a
            href={linkDetails.items.length > 0 ? '#' : linkDetails.link}
            className="main_link"
            target="_blank"
            rel="noreferrer"
            style={{ justifyContent: linkDetails.items?.length > 0 ? '' : 'center' }}
          >
            {linkDetails.linkText}
            {linkDetails.items?.length > 0 ? (
              !isClicked ? (
                <KeyboardArrowDownIcon style={{ textAlign: 'right' }} />
              ) : (
                <KeyboardArrowUpIcon style={{ textAlign: 'right' }} />
              )
            ) : null}
          </a>
        </>
      )}
      {isClicked && (
        <div ref={menuRef} className={`menu_container ${isClicked ? 'active_menu' : ''}`}>
          <h6 className="menu_title">{linkDetails.linkTitle}</h6>

          {linkDetails.sideBar ? (
            <div className="menu">
              <>
                <ul className="parent_list" no-style="true">
                  {linkDetails.items?.map((item, index: any) => (
                    <>
                      <li
                        key={item._id}
                        onClick={() => {
                          setCurrentIndex(index);
                          setCurrenCourse(item.linkText);
                        }}
                        className={`parent_links ${currentIndex === index ? 'active' : ''}`}
                      >
                        <Link to={item.items.length > 0 ? '#' : item.link}>
                          {item.linkText}
                          <KeyboardArrowRightIcon />
                        </Link>
                      </li>
                    </>
                  ))}
                </ul>
                <div className="menu_content_container">
                  <Link to={linkDetails.items[currentIndex].link} className="content_title">
                    <span className=" text-upper ">{linkDetails.items[currentIndex].linkTitle}</span>
                  </Link>
                  {Array.isArray(linkDetails.items[currentIndex]?.items) && linkDetails.items[currentIndex]?.items?.length > 0 && (
                    <RenderLinksWithIcon items={linkDetails.items[currentIndex].items} />
                  )}
                </div>
              </>
            </div>
          ) : (
            <div className="menu-without_link" style={{ paddingBottom: linkDetails?.headerBottom ? '59px' : 0 }}>
              <RenderLinksWithIcon items={linkDetails.items} />
            </div>
          )}
          {linkDetails?.headerBottom && (
            <div className="menu_bottom d-flex align-center justify-center" style={{ padding: '8px 0', height: '59px' }}>
              <>
                <p style={{ marginRight: '44px' }}>{linkDetails?.headerBottom?.title}</p>
                <Link to={`${linkDetails?.headerBottom?.link}?courseName=${currentCourse}`}>
                  <PrimaryButton style={{ display: 'block', color: 'white', borderWidth: '1px' }}>
                    {linkDetails?.headerBottom?.buttonText}
                  </PrimaryButton>
                </Link>
              </>
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export default HeaderLink;

const RenderLinksWithIcon = ({ items }: { items: HeaderLinkChildInterface[] }) => {
  return (
    <ul className="d-flex flex-wrap content_container" no-style="true">
      {items?.map((item, index: any) => (
        <li
          key={item._id}
          className={`single_link_container ${item.backgroundColor ? 'background' : ''}`}
          style={{ '--after_bg': item.backgroundColor } as React.CSSProperties}
        >
          <div className="link_container">
            <Link to={item.link} className="single_link">
              <span className="link_icon fw-5">
                {item.dynamicIcon ? <img src={item.dynamicIcon} alt="" style={{ maxWidth: '25px' }} /> : item.linkIcon}
              </span>
              <span className="link_link ">{item.linkText}</span>
            </Link>
            <p className="lh-sm" style={{ paddingLeft: 30, fontSize: 11, color: '#020100' }}>
              {item.linkTitle}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};
