import React, { useEffect } from 'react';
import { Box, Typography, ToggleButton, ToggleButtonGroup, Grid } from '@mui/material/';
import { Reorder, CalendarToday } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { MdOutlineClass } from 'react-icons/md';
import { Calendar as MyCalendar, momentLocalizer, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { CustomToastContext } from 'src/context/CustomToastContext';
import { axiosInstance } from 'src/axios';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadCrumbs';
import { useData } from 'src/context/Provider';

const Orders = () => {
  const {
    state: { brand }
  } = useData();
  const toastConfig = React.useContext(CustomToastContext);
  const [ordersData, setOrdersData] = React.useState(null);
  const [calendarData, setCalendarData] = React.useState([]);
  const [toggleValue, setToggleValue] = React.useState('list');
  const [view, setView] = React.useState<View>('week');
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance()
      .get(`/orders`)
      .then(({ data: { data } }) => {
        setOrdersData(data);

        let newEvents: any[] = [];

        data?.forEach((order: any) => {
          order?.bookDates.forEach((d: any) => {
            const startDate = moment(d.startDate);
            const endDate = moment(d.endDate);
            const daysDiff = endDate.diff(startDate, 'days');

            if (daysDiff === 0) {
              const newStartDate = new Date(d.startDate);
              const newEndDate = new Date(d.startDate);
              const newStartTime = new Date(d.startTime);
              const newEndTime = new Date(d.endTime);

              newStartDate.setHours(newStartTime.getHours());
              newStartDate.setMinutes(newStartTime.getMinutes());
              newStartDate.setSeconds(0);

              newEndDate.setHours(newEndTime.getHours());
              newEndDate.setMinutes(newEndTime.getMinutes());
              newEndDate.setSeconds(0);

              let event: any = {
                ...d,
                title: order.course?.optionLabel,
                start: newStartDate,
                end: newEndDate
              };
              newEvents.push(event);
            } else {
              for (let i = 0; i <= daysDiff + 1; i++) {
                const newDate: any = moment(d.startDate).add(i, 'days');
                if (d?.blockDayNames?.includes(moment(newDate).format('dddd'))) {
                } else {
                  const newStartDate = new Date(newDate);
                  const newEndDate = new Date(newDate);
                  const newStartTime = new Date(d.startTime);
                  const newEndTime = new Date(d.endTime);

                  newStartDate.setHours(newStartTime.getHours());
                  newStartDate.setMinutes(newStartTime.getMinutes());
                  newStartDate.setSeconds(0);

                  newEndDate.setHours(newEndTime.getHours());
                  newEndDate.setMinutes(newEndTime.getMinutes());
                  newEndDate.setSeconds(0);

                  let event: any = {
                    ...d,
                    title: order.course?.optionLabel,
                    start: newStartDate,
                    end: newEndDate
                  };
                  newEvents.push(event);
                }
              }
            }
          });
        });
        console.log(newEvents);
        setCalendarData(newEvents);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <Box className="container">
      <Box my={2}>
        <CustomBreadcrumbs routes={[{ title: 'Orders', path: '' }]} />
      </Box>
      <Box mt={{ xs: 1, sm: 1, md: 2, lg: 3 }}>
        <h5>Orders</h5>
        <Box pt={3} pb={3}>
          <Box mb={3}>
            <ToggleButtonGroup size="small" value={toggleValue} exclusive onChange={(_, value) => setToggleValue(value)}>
              <ToggleButton value="list">
                <Reorder />
              </ToggleButton>
              <ToggleButton value="calendar">
                <CalendarToday />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {ordersData ? (
            ordersData.length > 0 ? (
              <React.Fragment>
                {toggleValue === 'list' ? (
                  ordersData?.map((item: any) => (
                    <Grid
                      container
                      onClick={() => {
                        navigate(`/orders/${item?._id}`);
                      }}
                      sx={{ cursor: 'pointer' }}
                      border={1}
                      borderColor="grey.300"
                      mb={2}
                      p={2}
                      key={item.orderNumber}
                    >
                      <Box sx={{ maxWidth: '80px', flexBasis: '80px' }} display={{ xs: 'none', sm: 'block' }}>
                        <MdOutlineClass style={{ width: '100%', height: 'auto', color: 'var(--primary)' }} />
                      </Box>
                      <Box
                        sx={{
                          maxWidth: { xs: '100%', sm: 'calc(100% - 80px)' },
                          flexBasis: { xs: '100%', sm: 'calc(100% - 80px)' },
                          paddingLeft: { xs: '0', sm: '15px' }
                        }}
                      >
                        <h6>{item?.course?.optionLabel}</h6>
                        <p style={{ marginTop: '5px', lineHeight: '1.2' }}>
                          <span style={{ color: 'var(--secondary)' }}>Order Number : </span>
                          <span>{item?.orderNumber}</span>
                        </p>
                        <p style={{ marginTop: '5px', lineHeight: '1.2' }}>
                          <span style={{ color: 'var(--secondary)' }}>Class : </span>
                          {item?.bookDates?.map((item: any, key: any) => {
                            const lengthGreaterThanOne = item?.bookDates?.length > 1;
                            return (
                              <>
                                <span>{moment(item.startDate).format('dddd, Do MMM')}</span> <span>{moment(item.startTime).format('h:mm A')}</span>{' '}
                                {lengthGreaterThanOne && ','}
                              </>
                            );
                          })}
                        </p>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ position: 'relative', height: 'calc(75vh - 20px)', mt: 1 }}>
                    <div></div>
                    <MyCalendar
                      events={calendarData}
                      popup={true}
                      view={view}
                      onView={(newView) => setView(newView)}
                      localizer={momentLocalizer(moment)}
                      views={['month', 'week', 'day']}
                      // eventPropGetter={(data: any) => {
                      //   return {
                      //     style: {
                      //       backgroundColor: data.isBooked ? '#90EE90' : data.isBlocked ? '#D3D3D3' : 'white',
                      //       color: data.isBooked || data.isBlocked ? 'black' : 'black'
                      //     }
                      //   };
                      // }}
                      eventPropGetter={eventStyleGetter}
                      // onSelectEvent={onEventClick}
                    />
                  </Box>
                )}
              </React.Fragment>
            ) : (
              <Typography variant={'h6'}>No recent orders</Typography>
            )
          ) : (
            <Box height={500} bgcolor="white">
              <CommonSkeleton lenArray={[...Array(10).keys()]} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Orders;

const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
  const backgroundColor = 'green';
  const style = {
    backgroundColor: backgroundColor,
    opacity: 0.8,
    color: 'white',
    border: '0px',
    display: 'block'
  };
  return {
    style: style
  };
};
