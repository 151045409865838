import { BsFillPhoneFill } from 'react-icons/bs';
import { MdLocationPin, MdEmail } from 'react-icons/md';

export const content = [
  {
    icon: <MdLocationPin />,
    head: 'Address',
    links: [
      {
        link: '',
        text: '7500 N. Sam Houston Pkwy W. Houston TX 77064',
        type: 'text'
      }
    ]
  },

  {
    icon: <MdEmail />,
    head: 'Email',
    links: [
      {
        link: 'mailto: SneiKirtanSchool@gmail.com',
        text: 'SneiKirtanSchool@gmail.com',
        type: 'link'
      }
    ]
  }
];
