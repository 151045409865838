import React from 'react';
import ClassDetail from 'src/components/Site/ClassDetail';
import { siteNames, siteName } from 'src/config';
import PageNotFound from 'src/pages/PageNotFound';

const ClassDetails = () => {
  if (siteName?.includes(siteNames.site1)) {
    return <PageNotFound />;
  } else {
    return <ClassDetail />;
  }
};

export default ClassDetails;
