import { SnackbarOrigin } from "@mui/material";
import { createContext, useState, ReactNode } from "react";

type ToastDataType = {
  open: boolean;
  type: "error" | "info" | "warning" | "success";
  message: string;
  anchorOrigin?: SnackbarOrigin;
};

type ToastContextType = {
  toastConfig: ToastDataType;
  setToastConfig: React.Dispatch<React.SetStateAction<ToastDataType>>;
};

export const CustomToastContext = createContext<ToastContextType>(null);
interface Props {
  children?: ReactNode;
}

export const CustomToastProvider = ({ children }: Props) => {
  const [toastConfig, setToastConfig] = useState({
    open: false,
    type: null,
    message: null,
  });
  return (
    <CustomToastContext.Provider
      value={{
        toastConfig,
        setToastConfig,
      }}
    >
      {children}
    </CustomToastContext.Provider>
  );
};
