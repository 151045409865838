import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Layout from './Layout';
import { useData } from 'src/context/Provider';
import React, { useState } from 'react';
import { siteName, siteNames, getFaviconEl } from 'src/config';
import { routes } from 'src/helpers/routes';

import site1Favicon from 'src/assets/favicons/site1Favicon.ico';
import site2Favicon from 'src/assets/favicons/site2Favicon.ico';

const PrivateRoute = () => {
  React.useEffect(() => {
    const favicon = getFaviconEl();
    if (siteName?.includes(siteNames.site1)) {
      document.title = 'Sikh National Education Institute';
      favicon.href = site1Favicon;
    } else if (siteName?.includes(siteNames.site2)) {
      document.title = 'Gurprasad Vidyala | Afterschool Gurmat Academy';
      favicon.href = site2Favicon;
    }
  }, [siteName]);

  const {
    state: { user, userLoading }
  }: any = useData();
  const token = localStorage.getItem('token');
  const location = useLocation();

  return user || token ? (
    userLoading ? (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          padding: '1rem'
        }}
      >
        <p>Checking Credentials...</p>
      </div>
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    )
  ) : siteName?.includes(siteNames.site1) ? (
    <Navigate to={routes.login.path} state={{ from: location }} replace />
  ) : (
    <Navigate to={routes.register.path} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
