import React from 'react';
import img from 'src/components/Site1/assets/aboutUs.png';
import styles from './index.module.scss';

const AboutUs = () => {
  return (
    <div className={`container2 `}>
      <div className={styles.main}>
        <h2 className={styles.heading}>About Us</h2>
        <div className={styles.imageContainer}>
          <img src={img} alt="" aria-hidden loading="lazy" />
        </div>
        <p>
          SIKH NATIONAL EDUCATION INSTITUTE ("SNEI") is an affiliate organization of Sikh National Center ("SNC"). SNEI is tasked to be SNC's Sikh
          Education & Resource Center.
        </p>
        <p>
          As a professional Institution, SEI develops its own Management Structure, Curricula, Regulations, Personnel, Programs, and Facilities. SNEI
          is authorized to receive both public and private outside funding and support.
        </p>
        <p>SNEI's current Programs & Curricula include:</p>
        <ul>
          <li>Teaching of Guru Granth Sahib & Sikh Philosophy, History and Raihit Maryada</li>
          <li>Teaching of Punjabi to Sikh Children, Youth, and other interested persons in a School setting</li>
          <li>Teaching of English to interested Sikh Sangat Members</li>
          <li>Seminars on Sikhism and outreach to American Schools, Colleges, Universities, etc.</li>
          <li>
            Community social work, counseling, and outreach to social welfare organizations such as DAYA Interfaith Conferences, Seminars, Programs,
            and Parliaments, etc.
          </li>
          <li>Keertan and Katha Training and any other need-based Professional Programs.</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
