import React, { useEffect, useState } from 'react';

import './styles.scss';
import logo from 'src/components/Site/assets/logo/Logo.jpg';

import { Link } from 'react-router-dom';
import HeaderLink from './HeaderLink';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileMenu from './MobileMenu';
import { headerBottom } from './headerData';
import { routes } from 'src/helpers/routes';
import ProfileMenu from './ProfileMenu';
import { useData } from 'src/context/Provider';
import { useHeaderLinks } from 'src/components/Site/Header/headerData';
import { Skeleton, Typography } from '@mui/material';
import { HeaderLinksInterface } from 'src/components/Site/interface';
import { useScrollDirection } from 'src/components/hooks';
import { buttonType, buttonStyle, SecondaryButton } from 'src/components/Buttons';
import { useNavigate } from 'react-router-dom';

import SearchInput from './SearchInput';
import Notification from 'src/components/Notification';
import { axiosInstance } from 'src/axios';
import { isEmpty } from 'lodash';

// FOR STICKY HEADER SETTINGS
const STICKY_HEADER_START_AT = 135; //in px
const MOBILE_HEADER_START_AT = 101;
const SCROLL_EFFECT_START_AT = 1;

const Header = () => {
  const {
    state: { user, brand }
  }: any = useData();

  const headerLinksData = useHeaderLinks();
  const classData = headerLinksData ? headerLinksData[0]?.items[0]?.items : [];
  const mobileDisplay = useMediaQuery('(max-width:768px)');
  const { direction, position, difference } = useScrollDirection(SCROLL_EFFECT_START_AT);
  const [headerDataSet, setHeaderDataSet] = useState(null);

  const isTopHeaderPresent =
    (brand?.subheaderTitle && brand?.subheaderTitle !== '') || (headerDataSet?.subheaderTitle && headerDataSet?.subheaderTitle !== '');

  useEffect(() => {
    !brand && headerData();
  }, []);

  const LoginSignup = () => {
    const navigate = useNavigate();
    const Button = buttonStyle(buttonType.primary);
    return !user ? (
      <>
        {!mobileDisplay && (
          <Notification
            sx={{
              marginRight: { xs: '15px', md: '20px', lg: '34px' },
              color: 'var(--primary)',
              flexShrink: '0',
              widht: '40px',
              height: '40px'
            }}
          />
        )}
        {mobileDisplay ? (
          <>
            <Button
              variant="rounded"
              className={`login_link login_button mobile_signup_buttons`}
              fullWidth
              onClick={() => navigate(`${routes.login.path}`)}
            >
              Login
            </Button>
            <Button variant="rounded" className="mobile_signup_buttons" fullWidth onClick={() => navigate(`${routes.register.path}`)}>
              Signup
            </Button>
          </>
        ) : (
          <>
            <Link className={`login_link`} to={`${routes.login.path}`}>
              Login
            </Link>
            <Button variant="rounded" className="signup_desktop_button" onClick={() => navigate(`${routes.register.path}`)}>
              Signup
            </Button>
          </>
        )}
      </>
    ) : (
      <>
        {!mobileDisplay && (
          <Notification
            sx={{
              marginRight: !mobileDisplay ? { xs: '15px', md: '20px', lg: '34px' } : 0,
              marginLeft: mobileDisplay && '15px',
              color: 'var(--primary)',
              flexShrink: '0',
              widht: '40px',
              height: '40px'
            }}
          />
        )}
        <ProfileMenu user={user} />
      </>
    );
  };

  const RightSideContent = () => {
    const navigate = useNavigate();
    return !mobileDisplay ? (
      <>
        <SearchInput classData={classData} />
        <div className="links_container align-center" style={{ justifyContent: user ? 'flex-end' : 'space-around' }}>
          <LoginSignup />
        </div>
      </>
    ) : (
      <>
        {/* <LoginSignup /> */}
        <SearchInput classData={classData} isTopHeaderPresent={isTopHeaderPresent} />
        <Notification
          sx={{
            color: 'var(--primary)',
            flexShrink: '0',
            widht: '40px',
            height: '40px'
          }}
        />
        <MobileMenu linkObj={headerLinksData} headerBottom={headerBottom} user={user} loginSignup={<LoginSignup />} />
      </>
    );
  };

  const height = mobileDisplay ? MOBILE_HEADER_START_AT : STICKY_HEADER_START_AT;
  const headerData = async () => {
    const data = await axiosInstance().get('/brands/sub-header');
    console.log(data.data.data);
    if (!isEmpty(data.data.data)) {
      setHeaderDataSet(data.data.data);
    }
  };

  return (
    <>
      {position > height && <div className={`filler`} style={{ height: `${height}px` }}></div>}
      <header
        className={`${
          !mobileDisplay
            ? position > STICKY_HEADER_START_AT && direction === 'up' && 'sticky_header_desktop'
            : position > MOBILE_HEADER_START_AT && direction === 'up' && 'sticky_header_mobile'
        } header 
        ${!mobileDisplay ? position > STICKY_HEADER_START_AT && 'sticky_header' : position > MOBILE_HEADER_START_AT && 'sticky_header'}
        `}
      >
        {isTopHeaderPresent && (
          <div className="top-header align-center">
            <Typography>
              {brand?.subheaderTitle || headerDataSet?.subheaderTitle}
              <Typography href={brand?.subheaderButtonLink || headerDataSet?.subheaderButtonLink} component={'a'} target="_blank">
                {brand?.subheaderButtonLabel || headerDataSet?.subheaderButtonLabel}
              </Typography>
            </Typography>
          </div>
        )}
        <div className="container">
          <div className="header_top d-flex ">
            <div className="logo_container">
              <Link to={routes.home.path}>
                <img src={logo} alt={'Gurprasad Vidyalaya Logo'} />
              </Link>
            </div>
            <div className="header_top_right_content d-flex align-center  ">
              <RightSideContent />
            </div>
          </div>
          {!mobileDisplay ? (
            <div className="header_bottom d-flex  flex-wrap">
              {headerLinksData
                ? headerLinksData.map((item: HeaderLinksInterface, key: any) => {
                    return (
                      <>
                        <ul key={item._id} no-style="true">
                          {/* <hr style={{ margin: 0 }} /> */}
                          <HeaderLink linkDetails={item} />
                        </ul>
                      </>
                    );
                  })
                : [1, 2, 3, 4, 5, 6].map((item) => <Skeleton key={item} width={`${Math.random() * (110 - 55) + 55}px`} height={'34px'} />)}
            </div>
          ) : null}
        </div>
        <hr style={{ margin: mobileDisplay ? '8px 0 0px 0' : '0' }} />
      </header>
    </>
  );
};

export default Header;
