import React from 'react';
import GetInTouch from 'src/components/GetInTouch';
import ContactForm from 'src/components/ContactForm';

const ContactUs = () => {
  return (
    <>
      <GetInTouch />
      <ContactForm />
    </>
  );
};

export default ContactUs;
