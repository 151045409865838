import React from 'react';
import styles from './index.module.scss';
import columnDecoration1 from 'src/assets/svg/columnDecoration1.svg';
import columnDecoration2 from 'src/assets/svg/columnDecoration2.svg';
import columnDecoration3 from 'src/assets/svg/columnDecoration3.svg';

interface inputProps {
  columnsData: any;
}

const TwoColumnSections: React.FC<inputProps> = ({ columnsData }) => {
  return (
    <div className="p-rel">
      <img src={columnDecoration1} alt="" aria-hidden="true" className={`${styles.decoration1} ${styles.decoration}`} />
      <img src={columnDecoration2} alt="" aria-hidden="true" className={`${styles.decoration2} ${styles.decoration}`} />

      <div className="container p-rel">
        <img src={columnDecoration3} alt="" aria-hidden="true" className={`${styles.decoration3} ${styles.decoration}`} />
        <div className={styles.content}>
          {columnsData.map((column: any, key: any) => {
            return (
              <div key={column.id} className={styles.singleRow}>
                <div className={styles.imageContainer} style={{ position: 'relative' }}>
                  <div className="loader2"></div>
                  <img
                    src={column.image}
                    alt=""
                    aria-hidden
                    loading="lazy"
                    width={364}
                    onLoad={(e) => {
                      e.currentTarget.previousElementSibling.remove();
                    }}
                  />
                </div>
                <div className={styles.contentContainer}>
                  <h3>{column.heading}</h3>
                  {column.content?.length > 1 ? (
                    <ul>
                      {column.content?.map((item: string, key: any) => (
                        <li key={`${key}${item}`}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{column.content[0]}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TwoColumnSections;
