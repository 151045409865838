import React from 'react';
import styles from './index.module.scss';

interface inputPorps {
  data: propType;
}
interface propType {
  headline: string;
  heading: string;
  text: string;
  image: string;
  bgColor: string;
}

const OurValues = ({ data }: inputPorps) => {
  return (
    <section className={`${styles.main}`} style={{ backgroundColor: data.bgColor }}>
      <div className="container2">
        <div className={styles.headingSection}>
          <h6 className={`${styles.headline} inter`}>{data.headline}</h6>
          <h3 className={`${styles.heading} inter`}>{data.heading}</h3>
        </div>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <img src={data.image} alt="" loading="lazy" />
          </div>
          <div className={styles.textContainer}>
            <p className={`inter`}>{data.text}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurValues;
