import React from 'react';
import styles from './index.module.scss';

interface inputProps {
  data: Pokedex;
}

export interface Pokedex {
  headline?: string;
  heading?: string;
  text?: string;
  image?: string;
  cards?: Card[];
}

export interface Card {
  icon?: any;
  heading?: string;
  description?: string;
  linkText?: string;
  id: string;
  link?: string;
}

export const ContactDetailsWithHero = ({ data }: inputProps) => {
  return (
    <section className={styles.main}>
      <div className="container2">
        <div className={styles.headingContainer}>
          <h6 className="inter">{data.headline}</h6>
          <h3 className="inter">{data.heading}</h3>
          <p className="inter">{data.text}</p>
        </div>
        <div className={styles.imageContainer}>
          <img src={data.image} alt="" loading="lazy" />
        </div>
        <div className={styles.cardContainer}>
          {data.cards.map((card) => {
            const Icon = card.icon;
            return (
              <div className={styles.singleCard} key={card.id}>
                <div className={styles.iconContainer}>
                  <Icon color="white" />
                </div>
                <div className={styles.textContainer}>
                  <h6 className={`${styles.head} inter`}>{card.heading}</h6>
                  <p className={`${styles.desc} inter`}>{card.description}</p>
                  <p className={`${styles.hightlighted} inter`}>
                    <a href={card.link} target="_blank">
                      {card.linkText}
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
