import React, { useState, useEffect } from 'react';
import { axiosInstance } from 'src/axios';
import moment from 'moment-timezone';
import { CustomToastContext } from 'src/context/CustomToastContext';
import myGvBannerImage from 'src/components/Site/assets/images/myGvBanner.png';
import { CourseDetailsInterface } from 'src/components/hooks';

export const myGvbannerData = {
  heading: 'Your Weekly Calendar for',
  subHeading: 'Viewing your Schedule',
  paragraph: 'Choose from 6 different categories of courses catered online to meet your needs and availability.',
  bannerImage: myGvBannerImage
};

export const useCalendarData = (courses: CourseDetailsInterface[]) => {
  const toastConfig = React.useContext(CustomToastContext);
  const [calendarData, setCalendarData] = useState(null);
  const [blockDatesWithId, setBlockDatesWithId] = useState(null);

  const mainData = {
    sectionHeading: 'Color Codes for Subjects',
    dates: [] as any
  };

  const fetchData = () => {
    axiosInstance()
      .get(`/orders`)
      .then(({ data: { data } }) => {
        let newEvents: any[] = [];
        data?.forEach((order: any) => {
          order?.bookDates.forEach((bookDate: any) => {
            const startDate = moment(bookDate.startDate);
            const endDate = moment(bookDate.endDate);
            const daysDiff = endDate.diff(startDate, 'days');
            const blockDates = blockDatesWithId?.find((item: any) => item.scheduleId === bookDate.scheduleId);
            for (let i = 0; i <= daysDiff; i++) {
              const newDate: any = moment(bookDate.startDate).add(i + 1, 'days');
              if (!blockDates?.blockDayNames?.includes(moment(newDate).format('dddd'))) {
                const startDateString = new Date(newDate._d).toISOString();
                const start = `${startDateString.split('T')[0]}T${bookDate.startTime.split('T')[1]}`;
                const end = `${startDateString.split('T')[0]}T${bookDate.endTime.split('T')[1]}`;
                let event: any = {
                  ...bookDate,
                  title: order.course?.optionLabel,
                  start: new Date(start),
                  end: new Date(end)
                };
                newEvents.push(event);
              }
            }
          });
        });
        mainData.dates = newEvents;
        setCalendarData(mainData);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };
  useEffect(() => {
    let MyList: any = [];
    courses?.forEach((item) => {
      item?.schedule?.forEach((item) => MyList.push({ scheduleId: item._id, blockDayNames: item.blockDayNames }));
    });
    setBlockDatesWithId(MyList);
  }, [courses]);

  useEffect(() => {
    if (blockDatesWithId) fetchData();
  }, [blockDatesWithId]);

  return calendarData;
};
