import React from 'react';
import styles from './index.module.scss';
import { teachersData } from 'src/components/Site1/Teachers/data';

const Teachers = () => {
  return (
    <div className="container2">
      <div className={styles.main}>
        <div className={styles.sectionHeading}>
          <h2>{teachersData.heading}</h2>
          <div className={styles.underLine}></div>
        </div>
        <div className={styles.teachersContainer}>
          {teachersData.teachers.map((item) => {
            return (
              <div key={item._id} className={styles.singleTeacher}>
                <img src={item.image} alt="" loading="lazy" />
                <h6>{item.name}</h6>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Teachers;
