import React from 'react';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { footerData } from './footerData';
import { PrimaryButton } from 'src/components/Buttons';
import { Button, useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';

interface inputProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  iconClassname?: string;
  className?: string;
  item: link;
  isUnderline?: boolean;
}
interface link {
  id?: string;
  path?: string;
  title?: string;
  type?: string;
  icon?: any;
}

const RenderLink = (props: inputProps) => {
  const { className = '', iconClassname = '', item, isUnderline = true } = props;
  if (item.type === 'internal') {
    return (
      <Link {...props} className={`${className} ${isUnderline ? 'underline' : ''}`} to={item.path}>
        {item.icon !== '' && <span className={iconClassname}>{item.icon}</span>}
        {item.title}
      </Link>
    );
  }
  if (item.type === 'external') {
    return (
      <a target={'_blank'} {...props} className={`${className} ${isUnderline ? 'underline' : ''}`} href={item.path}>
        {item.icon !== '' && <span className={iconClassname}>{item.icon}</span>}
        {item.title}
      </a>
    );
  }
  if (item.type === 'button') {
    return (
      <a href={item.path} target="_blank" rel="noreferrer">
        <PrimaryButton variant="rounded" startIcon={item.icon}>
          {item.title}
        </PrimaryButton>
      </a>
    );
  }
};

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const toastConfig = React.useContext(CustomToastContext);

  const [email, setEmail] = React.useState(null);

  const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const handleSubscription = async () => {
    if (email === '') {
      toastConfig.setToastConfig({
        open: true,
        message: 'Please enter email address',
        type: 'info'
      });
      return;
    } else if (!validateEmail(email)) {
      toastConfig.setToastConfig({
        open: true,
        message: 'Please enter valid email address',
        type: 'info'
      });
      return;
    } else {
      await axiosInstance()
        .get(`/subscription/email-subscribe?email=${email}`)
        .then((res) => {
          setEmail('');
          toastConfig.setToastConfig({
            open: true,
            message: 'Subscribed Successfully',
            type: 'success'
          });
        })
        .catch((err) => {
          toastConfig.setToastConfig(err);
        });
    }
  };

  return (
    <footer className={` ${styles.footer}`}>
      <div className="container">
        <div className={styles.siteLogoContainer}>
          <img src={footerData.siteLogo} alt="gurprasad vidyala logo" />
        </div>
        {!isMobile ? (
          <div className={styles.row}>
            {footerData.columns.map((col) => (
              <div className={styles.col} key={col.id}>
                <h6 className={`fs-5 fw-6 lh-1 ${styles.colTitle}`}>{col.title}</h6>
                {col.items.map((item) => (
                  <RenderLink key={item.id} item={item} className={styles.colLink} />
                ))}
              </div>
            ))}
            <div className={`${styles.col} ${styles.charityCol}`}>
              <h6 className={`fs-5 fw-6 lh-1 ${styles.colTitle}`}>Subscribe Now!​</h6>
              <div className={`${styles.formContainer} ${styles.fomContainer}`}>
                <input type="text" name="email" id="" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Type Email Here" />
                <Button className={styles.submitButton} disabled={email === null || email === ''} onClick={() => handleSubscription()}>
                  Go
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {footerData.columns.map((col) => (
              <Accordion key={col.id} sx={{ boxShadow: 'unset', '&::before': { content: 'unset' }, borderBottom: '1px solid lightgray' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ paddingBlock: '10px', paddingLeft: '0' }}
                  aria-controls={`${col.title}-content`}
                  id={`${col.title}-header`}
                >
                  <h6 className={`fs-5 fw-6 lh-1 ${styles.colTitle} mb-0`}>{col.title}</h6>
                </AccordionSummary>
                <AccordionDetails id={`${col.title}-content`} sx={{ padding: '0px 0px 16px' }}>
                  {col.items.map((item) => (
                    <RenderLink isUnderline={false} key={item.id} item={item} className={styles.colLink} />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
            <div className={`${styles.col} ${styles.charityCol}`} style={{ marginTop: '40px' }}>
              <h6 className={`fs-5 fw-6 lh-1 ${styles.colTitle}`}>Subscribe Now!​</h6>
              <div className={`${styles.formContainer} ${styles.fomContainer}`}>
                <input type="text" name="email" id="" placeholder="Type Email Here" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Button className={styles.submitButton} onClick={() => handleSubscription()}>
                  Go
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className={styles.socialMedia}>
          {footerData.socialMedia.map((item) => (
            <a href={item.path} target="_blank" className={styles.socialIcon} key={item.id} rel="noreferrer">
              {item.icon}
            </a>
          ))}
        </div>
        <div className={styles.footerBottom}>
          <p className="fw-3 lh-1">{footerData.copyright.text}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
