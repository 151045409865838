import React from 'react';
import { Formik, Form } from 'formik';
import { Box, TextField, Typography, CircularProgress, InputAdornment, Grid, Paper, Autocomplete, Button } from '@mui/material';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { loadStripe } from '@stripe/stripe-js';
import MuiPhoneInput from 'material-ui-phone-number';
import { useData } from 'src/context/Provider';
import { routes } from 'src/helpers/routes';
import { currencySymbol } from 'src/helpers/currency';

interface formData {
  contributeTo: string;
  name: string;
  email: string;
  phone: string;
  amount: string;
}

var stripePromise: any = null;

const PayOnline = ({ centerAlign = true, leftAlign = false, rightAlign = false }: any) => {
  const [contributeTo, setContributeTo] = React.useState([]);
  const [disableAmount, setDisableAmount] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [students, setStudents] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState(null);
  const toastConfig = React.useContext(CustomToastContext);
  const {
    state: { user, brand }
  }: any = useData();

  React.useEffect(() => {
    if (user && user?.role === 'Student') {
      setInitialValues({
        contributeTo: contributeTo?.length === 1 ? { ...contributeTo[0] } : null,
        name: user?.firstName + ' ' + user?.lastName,
        email: user?.email,
        phone: user?.phone,
        amount: ''
      });
    } else {
      setInitialValues({
        contributeTo: contributeTo?.length === 1 ? { ...contributeTo[0] } : null,
        name: '',
        email: '',
        phone: '',
        amount: ''
      });
    }
    if (user?.role === 'Parent') {
      fetchStudents();
    }
  }, [user, contributeTo]);

  React.useEffect(() => {
    fetchContribute();
    fetchPolicy();
  }, []);

  const fetchStudents = async () => {
    await axiosInstance()
      .get(routes.students.api)
      .then(({ data: { data } }) => {
        setStudents(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const fetchContribute = () => {
    axiosInstance()
      .get(`/contribute-master/contribute-type?private=1`)
      .then(({ data: { data } }) => {
        setContributeTo(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const fetchPolicy = () => {
    axiosInstance()
      .get(`/policy/public`)
      .then(({ data: { data } }) => {
        stripePromise = loadStripe(data?.apiKey);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const alignment = () => {
    if (centerAlign) {
      return { marginLeft: 'auto', marginRight: 'auto' };
    }
    if (rightAlign) {
      return { marginLeft: 'auto', marginRight: '0' };
    }
    return { margin: '' };
  };

  const position = alignment();

  const handleSubmit = (values: any) => {
    setLoading(true);
    axiosInstance()
      .post('/donation/initiate-payment', {
        contributeTo: values?.contributeTo?._id,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        amount: values?.amount,
        user: user ? user?._id : null
      })
      .then(async ({ data: { data } }) => {
        const stripe = await stripePromise;
        await stripe
          .redirectToCheckout({ sessionId: data })
          .then(() => {
            setLoading(false);
          })
          .catch((error: any) => {});
      })
      .catch((error) => {
        toastConfig.setToastConfig(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = (values: formData) => {
    const errors: any = {};
    if (!values.contributeTo) {
      errors.contributeTo = 'Please Select a Contribution';
    }
    if (!values.name) {
      errors.name = 'Please Enter a name';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && values.email) {
      errors.email = 'Invalid email address';
    }
    if (!values.phone) {
      errors.phone = 'Phone is required';
    } else {
      let simpleStr = values?.phone.replaceAll(' ', '');
      simpleStr = simpleStr.replaceAll('(', '');
      simpleStr = simpleStr.replaceAll(')', '');
      simpleStr = simpleStr.replaceAll('-', '');
      const isValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(simpleStr);

      if (!isValid) {
        errors.phone = 'Phone number is not valid';
      }
    }
    if (!values.amount) {
      errors.amount = 'Please Enter an amount';
    } else if (parseFloat(values.amount) < 0) {
      errors.amount = 'Please Enter a positive number';
    }
    return errors;
  };

  const handleAutocomplete = (values: any, selectedContributeType: any) => {
    setValue(values);
    if (values) {
      setInitialValues({
        contributeTo: selectedContributeType,
        name: values?.firstName + ' ' + values?.lastName,
        email: values?.email,
        phone: values?.phone,
        amount: selectedContributeType?.amount
      });
    }
  };

  return (
    <div className="container">
      <Box sx={{ padding: '50px 0' }}>
        <Paper elevation={2} sx={{ maxWidth: '450px', borderRadius: '25px', ...position }}>
          <Box p={{ xs: 3, md: 4 }}>
            <Typography variant="h6" component="h2" sx={{ fontSize: '1.1rem', fontWeight: 700, mb: '8px' }}>
              Make a Payment
            </Typography>
            {contributeTo && initialValues && (
              <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ submitForm, values, errors, touched, setFieldValue, isSubmitting }) => (
                  <Form>
                    <Autocomplete
                      multiple={false}
                      value={values['contributeTo']}
                      id="multiple-limit-tags"
                      onChange={(event, newValue) => {
                        setFieldValue('contributeTo', newValue);
                        if (newValue?.amount) {
                          setFieldValue('amount', newValue?.amount);
                          setDisableAmount(true);
                        } else {
                          setFieldValue('amount', 0);
                          setDisableAmount(false);
                        }
                      }}
                      getOptionLabel={(option: any) => option?.contributeType}
                      options={contributeTo}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={touched['contributeTo'] && Boolean(errors['contributeTo'])}
                          helperText={(touched['contributeTo'] && errors['contributeTo'])?.toString()}
                          label="Contribute To"
                          placeholder="Contribute To"
                        />
                      )}
                      sx={{ mt: 2, mb: 1 }}
                    />
                    {user?.role === 'Parent' && students && (
                      <Autocomplete
                        multiple={false}
                        value={value}
                        id="multiple-limit-tags"
                        onChange={(event, newValue) => {
                          handleAutocomplete(newValue, values['contributeTo']);
                        }}
                        getOptionLabel={(option: any) => option?.firstName + ' ' + option?.lastName || ''}
                        options={students}
                        renderInput={(params) => <TextField {...params} label="Select Student" placeholder="Student" />}
                        sx={{ mt: 2, mb: 1 }}
                      />
                    )}
                    <TextField
                      fullWidth
                      name="name"
                      value={values['name']}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      error={touched['name'] && Boolean(errors['name'])}
                      helperText={(touched['name'] && errors['name'])?.toString()}
                      type="text"
                      id="donate-name"
                      label="Name"
                      variant="outlined"
                      sx={{ mt: { xs: 1, md: 2 } }}
                    />
                    <TextField
                      fullWidth
                      name="email"
                      value={values['email']}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      error={touched['email'] && Boolean(errors['email'])}
                      helperText={(touched['email'] && errors['email'])?.toString()}
                      type="email"
                      id="donate-email"
                      label="Email"
                      variant="outlined"
                      sx={{ mt: { xs: 1, md: 2 } }}
                    />
                    <Grid item xs={12} md={6}>
                      <MuiPhoneInput
                        defaultCountry={'us'}
                        disableAreaCodes
                        countryCodeEditable={true}
                        enableLongNumbers={false}
                        fullWidth
                        variant="outlined"
                        type="phone"
                        required
                        label="Phone"
                        name="phone"
                        dropdownClass="phone_dropdown"
                        onChange={(val: any) => setFieldValue('phone', val.toString())}
                        value={values['phone']}
                        error={touched['phone'] && Boolean(errors['phone'])}
                        helperText={touched['phone'] && Boolean(errors['phone']) && `${errors['phone']}`}
                        sx={{ mt: { xs: 1, md: 2 } }}
                      />
                    </Grid>
                    <TextField
                      fullWidth
                      name="amount"
                      value={values['amount']}
                      onChange={(e) => setFieldValue('amount', e.target.value)}
                      error={touched['amount'] && Boolean(errors['amount'])}
                      helperText={(touched['amount'] && errors['amount'])?.toString()}
                      type="number"
                      id="donate-amount"
                      label="Amount"
                      disabled={disableAmount}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{currencySymbol(brand?.currency)}</InputAdornment>
                      }}
                      variant="outlined"
                      sx={{ mt: { xs: 1, md: 2 } }}
                    />
                    <Box sx={{ mt: { xs: 1, md: 2 } }}>
                      <Button
                        disabled={loading}
                        variant="contained"
                        size="large"
                        type="submit"
                        fullWidth
                        endIcon={loading && <CircularProgress size={20} color="inherit" />}
                      >
                        Proceed
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default PayOnline;
