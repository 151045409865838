import React from 'react';
import { mapAndFormData, contactDetailsData } from './ContactUsData';
import { ContactFormAndMap } from '../ContactFormAndMap';
import { ContactDetailsWithHero } from '../ContactDetailsWithHero';
import { AnimationContainer } from 'src/components/Animation';

const ContactUs = () => {
  return (
    <AnimationContainer direction="fade">
      <ContactFormAndMap data={mapAndFormData} />
      <ContactDetailsWithHero data={contactDetailsData} />
    </AnimationContainer>
  );
};

export default ContactUs;
