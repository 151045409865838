import React from 'react';
import { Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, IconButton, Tooltip, Button, Typography, useMediaQuery } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useData } from '../../../context/Provider';
import { SET_USER, SET_BRAND } from 'src/context/ActionTypes';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'src/helpers/routes';
import Person2Icon from '@mui/icons-material/Person2';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { siteName, siteNames } from 'src/config';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import { MdOutlineNoteAlt } from 'react-icons/md';

const ProfileMenu = ({ user }: { user: any }) => {
  const mobileDisplay = useMediaQuery('(max-width:768px)');
  const { dispatch }: any = useData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem('token');
    dispatch({ type: SET_USER, payload: null });
    dispatch({ type: SET_BRAND, payload: null });
    if (siteName?.includes(siteNames.site2)) {
      navigate(routes.login.path, { replace: true });
    }
  };

  return (
    <>
      <Box sx={{ display: 'inline-block', textAlign: 'center', positoin: 'relative' }}>
        <Tooltip title="Account settings">
          {siteName?.includes(siteNames.site1) ? (
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ mx: 1 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  textTransform: 'uppercase',
                  background: '#ababab'
                }}
              >
                {user?.firstName?.trim().slice(0, 1)}
              </Avatar>
            </IconButton>
          ) : (
            <Button
              onClick={handleClick}
              size="small"
              sx={{ padding: '0 !important', paddingRight: '30px !important', borderRadius: '23px 0 0 23px' }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  textTransform: 'uppercase',
                  background: '#d9d9d9',
                  fontSize: 17,
                  color: 'black'
                }}
              >
                {user?.firstName?.trim().slice(0, 1)}
                {user?.lastName?.trim().slice(0, 1)}
              </Avatar>
              <Typography
                component={'span'}
                ml={1}
                sx={{
                  textTransform: 'capitalize',
                  color: '#000',
                  fontSize: 16,
                  maxWidth: 157,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {user?.firstName} {user?.lastName}{' '}
              </Typography>
              <Typography
                component={'span'}
                sx={{ position: 'absolute', top: 'calc(50% + 4px)', right: 0, transform: 'translateY(-50%)', color: 'black' }}
              >
                {Boolean(anchorEl) ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownIcon />}
              </Typography>
            </Button>
          )}
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: { ...customStyles.paperSx }
        }}
        transformOrigin={{ horizontal: 'right', vertical: siteName?.includes(siteNames.site2) && mobileDisplay ? 'bottom' : 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: siteName?.includes(siteNames.site2) && mobileDisplay ? 'top' : 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            navigate('/profile');
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        {siteName?.includes(siteNames.site2) && user?.role !== 'Parent' && (
          <MenuItem
            onClick={() => {
              navigate(routes.myGv.path);
            }}
          >
            <ListItemIcon>
              <EventOutlinedIcon fontSize="small" />
            </ListItemIcon>
            MY-GV
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            navigate('/orders');
          }}
        >
          <ListItemIcon>
            <ListAltIcon fontSize="small" />
          </ListItemIcon>
          Enrolled Classes
        </MenuItem>
        {user?.role === 'Parent' && (
          <MenuItem
            onClick={() => {
              navigate(routes.students.path);
            }}
          >
            <ListItemIcon>
              <Person2Icon fontSize="small" />
            </ListItemIcon>
            Students
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            navigate(`${routes.donation.path}/orders`);
          }}
        >
          <ListItemIcon>
            <VolunteerActivismIcon fontSize="small" />
          </ListItemIcon>
          Donation History
        </MenuItem>
        {siteName?.includes(siteNames.site2) && (
          <MenuItem
            onClick={() => {
              navigate(routes.courses.path);
            }}
          >
            <ListItemIcon>
              <MdOutlineNoteAlt style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            Enroll
          </MenuItem>
        )}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;

const customStyles = {
  paperSx: {
    minWidth: '200px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
};
