import React, { useState, useEffect } from 'react';
import Banner from 'src/components/Site/Banner';
import { myGvbannerData, useCalendarData } from 'src/components/Site/MyGv/myGvData';
import CalanderWithLegend from '../CalanderWithLegend';
import { useCourses, CourseDetailsInterface } from 'src/components/hooks';
import { AnimationContainer } from 'src/components/Animation';

const MyGv = () => {
  const courses = useCourses();
  const calendarData = useCalendarData(courses);

  return (
    <AnimationContainer direction="fade">
      <Banner bannerInfo={myGvbannerData} decoration={false} />
      <CalanderWithLegend calendarData={calendarData} />
    </AnimationContainer>
  );
};

export default MyGv;
