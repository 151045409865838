import { AiFillSafetyCertificate, AiFillAppstore, AiFillEyeInvisible } from 'react-icons/ai';

import HomePageBannerImage from 'src/assets/svg/BannerImage1.svg';
import { buttonType } from 'src/components/Buttons';
import detailsSectionImage1 from 'src/assets/images/detailsSectionImage1.png';
import detailsSectionImage2 from 'src/assets/images/detailsSectionImage2.png';
import detailsSectionImage3 from 'src/assets/images/detailsSectionImage3.png';

import WhatWeDo1 from 'src/components/Site/assets/images/whatWeDo1.png';
import WhatWeDo2 from 'src/components/Site/assets/images/whatWeDo2.png';
import WhatWeDo3 from 'src/components/Site/assets/images/whatWeDo3.png';
import whatWeDoDecor from 'src/components/Site/assets/images/whatWeWorkDecor.png';

import testimonialHero from 'src/components/Site/assets/images/testimonialHero.png';
import testimonialUser from 'src/components/Site/assets/images/testimonialUser.png';
import testimonialQuote from 'src/components/Site/assets/images/testimonialQuote.png';
import testiDecor from 'src/components/Site/assets/images/testiDecor.png';

import donationBg from 'src/components/Site/assets/images/donationBg.png';

import stayTunedImage from 'src/components/Site/assets/images/stayTuned.png';

import { nanoid } from 'nanoid';

import heroImage from 'src/components/Site/assets/images/hero.jpg';
import { routes } from 'src/helpers/routes';

// homepage data
export const homeBannerData = {
  heading: 'Into Learning Sikhi',
  // subHeading: 'Turn Your Ambitions',
  paragraph:
    'Gurprasad Vidyala is a Sikh learning center, we aim to help preserve and spread Sikh teachings. Our institution strives to provide quality education in a safe, inclusive, and welcoming environment. We offer a variety of classes for all ages and make it our mission to provide that education regardless of barriers and challenges in place. The ultimate goal is to assist in fostering a lifelong passion and love for Sikhi for many generations to come.',
  buttons: [
    {
      id: nanoid(),
      link: '#',
      text: 'Register',
      type: buttonType.primary
    }
  ],
  bannerImage: HomePageBannerImage
};

export const heroData = {
  heroImage: heroImage,
  heading: `Inspiring our Panths Leaders of Tomorrow`,
  subHeading: `One step at a time`,
  ctaText: `ENROLL NOW`,
  ctaLink: routes.courses.path
};

export const homecardData = [
  {
    id: nanoid(),
    icon: AiFillSafetyCertificate,
    iconColor: '#FD6003',
    iconBgColor: '#F9E9D6',
    cardHead: 'Small Class Sizes',
    cardContent:
      'Our specially curated classes maximize interaction and engagement for students. The one-on-one instruction ensures students get the most value from each class.'
  },
  {
    id: nanoid(),
    icon: AiFillAppstore,
    iconColor: '#1283FF',
    iconBgColor: '#E5EFFB',
    cardHead: 'Independent Youth Run Academy',
    cardContent:
      'Our agenda is simple  - to educate and raise the new generation of Sikhs with firm roots in various facets of Gurmat tradition. The academy is run by the youth for the youth, with the Sangat as our support.'
  },
  {
    id: nanoid(),
    icon: AiFillEyeInvisible,
    iconColor: '#FD036C',
    iconBgColor: '#FFE5F0',
    cardHead: 'Safe Environment',
    cardContent:
      'Our volunteers and teachers are well-trusted and thoroughly vetted by our team members. We are dedicated to developing and maintaining a safe and welcoming environment for all our students.'
  }
];

export const homecolumnsData = [
  {
    id: nanoid(),
    heading: 'Extensive Curriculums',
    content: [
      'Our team has accessed a variety of resources to compile comprehensive curriculums for our various courses. Vidyala students are tested upon registration and organized into levels. Classes and curriculums are curated based on knowledge/skill level, thus each student receives the education that matches their individual needs. '
    ],
    image: detailsSectionImage1
  },
  {
    id: nanoid(),
    heading: 'Variety of Courses',
    content: [
      'At Gurprasad Vidyala we offer a wide array of courses to appeal to students’ diverse interests. Punjabi, Keertan, Tabla, Santheya and History are classes that are currently offered on a weekly basis. IELTS courses will soon be offered at Gurprasad Vidyala to further support and invest in the Sikh community.'
    ],
    image: detailsSectionImage2
  },
  {
    id: nanoid(),
    heading: 'Experienced Teachers',
    content: [
      'Our teachers come from a wide variety of backgrounds, with each instructor reviewed by our admin team. Many of our youth volunteers were raised among our local Calgary Sangat, giving them the unique opportunity to personally connect with our students. All our teachers are extremely passionate about Sikhi and it is their share that love with the future generations.'
    ],
    image: detailsSectionImage3
  }
];

export const howWeWorkData = {
  title: 'How the Vidyala works',
  decorationImage: whatWeDoDecor,
  columns: [
    { id: nanoid(), title: 'Register', description: 'Through the website or in person with our friendly staff', icon: WhatWeDo1 },
    { id: nanoid(), title: 'Show Up', description: 'Attend the classes you signed up for', icon: WhatWeDo2 },
    { id: nanoid(), title: 'Keep Learning', description: 'Reap the benefits!', icon: WhatWeDo3 }
  ]
};

export const testimonialData = {
  title: 'What the community says about us',
  heroImage: testimonialHero,
  quoteIcon: testimonialQuote,
  decorationImage: testiDecor,
  slides: [
    {
      id: nanoid(),
      author: 'Sammy Parker',
      designation: 'Technology Lead',
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. A nulla mattis in amet quis at at sed malesuada. Ultricies ut sit integer urna. 
      Augue ut sed aliquet urna est. Et eget phasellus commodo sit rhoncus. Mi bibendum venenatis, morbi tincidunt duis et, et mi. `,
      stars: 4.5,
      authorImage: testimonialUser
    },
    {
      id: nanoid(),
      author: 'Sammy Parker',
      designation: 'Technology Lead',
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. A nulla mattis in amet quis at at sed malesuada. Ultricies ut sit integer urna. 
      Augue ut sed aliquet urna est. Et eget phasellus commodo sit rhoncus. Mi bibendum venenatis, morbi tincidunt duis et, et mi. `,
      stars: 4.5,
      authorImage: testimonialUser
    },
    {
      id: nanoid(),
      author: 'Sammy Parker',
      designation: 'Technology Lead',
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. A nulla mattis in amet quis at at sed malesuada. Ultricies ut sit integer urna. 
      Augue ut sed aliquet urna est. Et eget phasellus commodo sit rhoncus. Mi bibendum venenatis, morbi tincidunt duis et, et mi. `,
      stars: 4.5,
      authorImage: testimonialUser
    }
  ]
};

export const donationData = {
  title: `Gurprasad Vidyala runs solely on Sangat support. All dasvandh goes towards operational costs such as rent, class supplies, food, and staff/administration.
    If you would like to donate items such as food, or supplies please do not hesitate to contact us.`,
  background: donationBg
};

export const stayTunedData = {
  head: 'Stay Connected with Us',
  subHeading: 'Newsletter',
  description:
    'Please submit your email to subscribe to our email list and get all the pertinent updates on Gurprasad Vidyala updates and related programs.',
  image: stayTunedImage
};
