import React from 'react';
import Site1ContactUs from 'src/components/Site1/ContactUs';
import Site2ContactUs from 'src/components/Site/ContactUs';
import { siteNames, siteName } from 'src/config';

const Contactus = () => {
  if (siteName?.includes(siteNames.site1)) {
    return <Site1ContactUs />;
  } else {
    return <Site2ContactUs />;
  }
};

export default Contactus;
