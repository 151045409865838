import { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@mui/material/';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { axiosInstance } from 'src/axios';
import { useNavigate } from 'react-router-dom';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import { routes } from '../../helpers/routes';
import DescriptionIcon from '@mui/icons-material/Description';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadCrumbs';
import { Typography } from '@mui/material';
import { useData } from 'src/context/Provider';
import { currencyConverter } from 'src/helpers/currency';

const UserwiseDonation = () => {
  const {
    state: { brand }
  }: any = useData();

  const toastConfig = useContext(CustomToastContext);
  const [donation, setDonationData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance()
      .get(`${routes.donation.api}/users`)
      .then(({ data: { data } }) => {
        setDonationData(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <Box className="container">
      <Box my={2}>
        <CustomBreadcrumbs routes={[{ title: 'Donation-Pay', path: '' }]} />
      </Box>
      <Box mt={4}>
        <h5>Donation/Pay</h5>
        <Box mt={3} mb={3}>
          {donation ? (
            donation?.length > 0 ? (
              donation?.map((item: any, key: any) => {
                return (
                  <Grid
                    container
                    onClick={() => {
                      navigate(`/donation/details/${item?._id}`);
                    }}
                    sx={{ cursor: 'pointer' }}
                    border={1}
                    borderColor="grey.300"
                    key={key}
                    mb={2}
                    p={2}
                  >
                    <Box sx={{ maxWidth: '80px', flexBasis: '80px' }} display={{ xs: 'none', sm: 'block' }}>
                      <DescriptionIcon style={{ width: '100%', height: 'auto', color: 'var(--primary)' }} />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: { xs: '100%', sm: 'calc(100% - 80px)' },
                        flexBasis: { xs: '100%', sm: 'calc(100% - 80px)' },
                        paddingLeft: { xs: '0', sm: '15px' }
                      }}
                    >
                      <h6>{item?.contributeTo?.contributeType}</h6>
                      <p style={{ marginTop: '5px', lineHeight: '1.2' }}>
                        <span style={{ color: 'var(--secondary)' }}>Name : </span>
                        <span>{item?.name}</span>
                      </p>
                      <p style={{ marginTop: '5px', lineHeight: '1.2' }}>
                        <span style={{ color: 'var(--secondary)' }}>Amount : </span>
                        <span>{currencyConverter(item?.amount, brand?.currency || 'USD')}</span>
                      </p>
                    </Box>
                  </Grid>
                );
              })
            ) : (
              <Typography variant={'h6'}>No recent donations</Typography>
            )
          ) : (
            <Box height={500} bgcolor="white">
              <CommonSkeleton lenArray={[...Array(10).keys()]} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserwiseDonation;
