import React from 'react';

import { List, ListItem, ListItemText, Collapse, SwipeableDrawer, IconButton, Button, ListItemIcon, Divider, useMediaQuery } from '@mui/material';
import logo from 'src/components/Site/assets/logo/Logo.jpg';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import ReorderIcon from '@mui/icons-material/Reorder';
import { Link } from 'react-router-dom';
import { hasChildren } from './utils';
import { SecondaryButton } from '../../Buttons';
import { HeaderLinksInterface, HeaderLinkChildInterface } from 'src/components/Site/interface';
import { routes } from 'src/helpers/routes';

import { HiMenuAlt3 } from 'react-icons/hi';

interface inpurPropInterFace {
  linkObj: HeaderLinksInterface[];
  headerBottom: any;
  user: any;
  loginSignup?: any;
}

let DRAWER_WIDTH = 414;

const MobileMenu: React.FC<inpurPropInterFace> = ({ linkObj, headerBottom, user, loginSignup = <></> }) => {
  const fullDrawerWidth = useMediaQuery('(max-width:600px)');

  if (fullDrawerWidth) {
    DRAWER_WIDTH = window.innerWidth;
  } else {
    DRAWER_WIDTH = 414;
  }

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <>
      <Button onClick={() => setIsDrawerOpen(true)} sx={{ minWidth: '30px', marginRight: '-10px', flexShrink: '0' }}>
        <span className="visually-hidden ">Open Menu</span>
        <HiMenuAlt3 style={{ color: 'var(--primary)', fontSize: '28px' }} />
      </Button>
      <SwipeableDrawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
        sx={{ zIndex: 6, width: `min(${DRAWER_WIDTH}px, 100vw)`, '& .MuiPaper-root': { height: 'unset' } }}
      >
        <div className="mobile-drawer-head">
          <Link to={routes.home.path}>
            <img src={logo} alt={'Gurprasad Vidyalaya Logo'} />
          </Link>
          <IconButton aria-label="delete" size="large" onClick={() => setIsDrawerOpen(false)} sx={{ color: 'var(--primary)' }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>

        <div style={{ width: `min(${DRAWER_WIDTH}px, 100vw)` }} className="header_client_sidebar mobile-menu-sidebar-scroll">
          {linkObj ? linkObj?.map((item, key: any) => <MenuItem key={item._id} item={item} spacing={false} />) : <></>}
        </div>

        <div className="mobile-drawer-profile-fixed-bottom">{loginSignup}</div>
      </SwipeableDrawer>
    </>
  );
};

interface inputItemPropInterFace {
  item: HeaderLinkChildInterface;
  spacing: boolean;
}

const MenuItem: React.FC<inputItemPropInterFace> = ({ item, spacing }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component spacing={spacing} item={item} />;
};

const SingleLevel: React.FC<inputItemPropInterFace> = ({ item, spacing }) => {
  return (
    <>
      {item.link ? (
        item?.linkType === 'internal' ? (
          <Link to={item.link}>
            <ListItem button sx={spacing && { paddingLeft: '30px' }} style={{ paddingBlock: '12px' }}>
              {item.dynamicIcon ? (
                <ListItemIcon>
                  <img style={{ maxWidth: '24px' }} src={item.dynamicIcon} alt={`${item.linkText} icon`} aria-hidden />
                </ListItemIcon>
              ) : item.linkIcon ? (
                <ListItemIcon>{item.linkIcon}</ListItemIcon>
              ) : null}
              <ListItemText primary={item.linkText} />
            </ListItem>
          </Link>
        ) : (
          <a href={item.link} target="_blank">
            <ListItem button sx={spacing && { paddingLeft: '30px' }} style={{ paddingBlock: '12px' }}>
              {item.dynamicIcon ? (
                <ListItemIcon>
                  <img style={{ maxWidth: '24px' }} src={item.dynamicIcon} alt={`${item.linkText} icon`} aria-hidden />
                </ListItemIcon>
              ) : item.linkIcon ? (
                <ListItemIcon>{item.linkIcon}</ListItemIcon>
              ) : null}
              <ListItemText primary={item.linkText} />
            </ListItem>
          </a>
        )
      ) : (
        <ListItemText primary={item.linkText} />
      )}

      {spacing && <Divider sx={{ borderColor: 'lightgray', marginInline: '24px' }} />}
    </>
  );
};

const MultiLevel: React.FC<inputItemPropInterFace> = ({ item, spacing }) => {
  const { items: children } = item;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick} sx={spacing && { paddingLeft: '30px' }}>
        <ListItemText primary={item.linkText} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="ul" disablePadding>
          {children.map((child: HeaderLinksInterface, key: any) => (
            <MenuItem spacing={true} key={child._id} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default MobileMenu;
