import ComingSoon from 'src/components/Site/ComingSoon';
import { AnimationContainer } from 'src/components/Animation';
const GvResources = () => {
  return (
    <AnimationContainer direction="fade" className="container">
      <ComingSoon />
    </AnimationContainer>
  );
};

export default GvResources;
