export const PAYMENT_TYPES = {
  online: 'Online',
  offline: 'Offline',
  inPerson: 'In-Person',
  eTransfar: 'e-Transfer'
};

export const COURSE_TYPES = {
  individual: 'Individual',
  group: 'Group',
  batch: 'Batch'
};

export const sessionLocalStorageKey = 'user_session';
