import { Box } from '@mui/system';
import React from 'react';
import { useParams } from 'react-router-dom';
import { axiosInstance } from 'src/axios';
import { CustomToastContext } from 'src/context/CustomToastContext';
import { useState, useEffect, useContext, Fragment } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import moment from 'moment-timezone';
import { COURSE_TYPES, PAYMENT_TYPES } from 'src/helpers/helper';
import CommonSkeleton from 'src/components/Helpers/CommonSkeleton';
import CustomBreadcrumbs from 'src/components/Helpers/CustomBreadCrumbs';
import { currencyConverter } from 'src/helpers/currency';
import { useData } from 'src/context/Provider';

const OrderDetails = () => {
  const {
    state: { brand }
  }: any = useData();
  const { id } = useParams();
  const toastConfig = React.useContext(CustomToastContext);
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [downloading, setDownloading] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    axiosInstance()
      .get(`/orders/${id}`)
      .then(({ data: { data } }) => {
        setOrderDetail(data);
      })
      .catch((err) => {
        toastConfig.setToastConfig(err);
      });
  };

  const downloadHandler = () => {
    setDownloading(true);
    axiosInstance()
      .get(`/orders/${id}/pdf`)
      .then(({ data: { data } }) => {
        var a = document.createElement('a');
        a.setAttribute('download', data.fileName);
        a.setAttribute('href', data.fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownloading(false);
        toastConfig.setToastConfig({
          open: true,
          message: 'Downloaded Successfully',
          type: 'info'
        });
      })
      .catch((err) => {
        setDownloading(false);
        toastConfig.setToastConfig(err);
      });
  };
  const resendHandler = () => {
    setSending(true);
    axiosInstance()
      .get(`/orders/${id}/resend-email`)
      .then(({ data: { data } }) => {
        setSending(false);
        toastConfig.setToastConfig({
          open: true,
          message: data?.message,
          type: 'info'
        });
      })
      .catch((err) => {
        setSending(false);
        toastConfig.setToastConfig(err);
      });
  };

  return (
    <Box className="container">
      <Box my={2}>
        <CustomBreadcrumbs
          routes={[
            { title: 'Orders', path: '/orders' },
            { title: orderDetail ? orderDetail.orderNumber : '', path: '' }
          ]}
        />
      </Box>
      {orderDetail ? (
        <Box pt={2} pb={3}>
          <h6>Details : {orderDetail?.orderNumber}</h6>
          <Box pt={2}>
            <Box border={1} borderColor="grey.300" p={2}>
              <h6>{orderDetail?.course?.courseName}</h6>
            </Box>
          </Box>
          <Box pt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <span>Dates</span>
                <Box pt={1}>
                  {orderDetail?.bookDates?.map((item: any, key: any) => {
                    return (
                      <Box border={1} borderColor="grey.300" key={key} mb={2} p={2}>
                        {orderDetail?.course?.type === COURSE_TYPES.batch ? (
                          <Fragment>
                            <span>{`${moment(item.startDate).format('Do MMM YY')} - ${moment(item.endDate).format('Do MMM YY')}`}</span>
                            <br />
                            <span>{`${moment(item.startTime).format('h:mm A')} - ${moment(item.endTime).format('h:mm A')}`}</span>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <span>{moment(item.startDate).format('dddd, Do MMM')}</span>
                            <span> </span>
                            <span>{moment(item.startTime).format('h:mm A')}</span>
                          </Fragment>
                        )}
                        <br />
                        <span>Instructor : {item?.instructor?.optionLabel}</span>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <span>Students</span>
                <Box pt={1}>
                  {orderDetail?.students?.map((item: any, key: any) => {
                    return (
                      <Box border={1} borderColor="grey.300" key={key} mb={2} p={2}>
                        <span>{item?.firstName + ' ' + item.lastName}</span>
                        <br />
                        <span>{item?.phone}</span>
                        <br />
                        <span>{item?.email}</span>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box pt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box border={1} borderColor="grey.300" p={2}>
                  {orderDetail?.discount ? (
                    <>
                      <span>Total : {currencyConverter(orderDetail?.total, brand?.currency || 'USD')}</span>
                      <br />
                      <br />
                      <span>Discount : {currencyConverter(orderDetail?.discount, brand?.currency || 'USD')}</span>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <span>Amount : {currencyConverter(orderDetail?.amount, brand?.currency || 'USD')}</span>
                  <br />
                  <br />
                  {orderDetail?.charge ? (
                    <>
                      <span>Processing Fee : {currencyConverter(orderDetail?.charge, brand?.currency || 'USD')}</span>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <span>Net Amount : {currencyConverter(orderDetail?.netAmount, brand?.currency || 'USD')}</span>
                  <br />
                  <br />
                  <span>Payment Type : {orderDetail?.paymentType}</span>
                  <br />
                  <br />
                  <span>Payment Status : {orderDetail?.paymentStatus}</span>
                  <br />
                  <br />
                  {orderDetail?.paymentType === PAYMENT_TYPES.online ? (
                    <>
                      <span>Payment Id : {orderDetail?.paymentId}</span>
                      <br />
                      <br />
                    </>
                  ) : null}
                  <span>Booking Date : {moment(orderDetail?.registrationDate).format('dddd, Do MMM h:mm A')}</span>
                  <br />
                  <br />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {orderDetail?.address && orderDetail?.address?.address && (
                  <Box border={1} borderColor="grey.300" p={2}>
                    <span>Address</span>
                    <br />
                    <br />
                    <span>{orderDetail?.address?.address}</span>
                    <br />
                    <span>{`${orderDetail?.address?.city}-${orderDetail?.address?.zipCode}`}</span>
                    <br />
                    <span>{`${orderDetail?.address?.state}-${orderDetail?.address?.country}`}</span>
                    <br />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  {/* <Button onClick={downloadHandler} style={{ marginRight: '1rem' }} variant="contained">
                    Download
                    {downloading && <CircularProgress color="inherit" size={22} />}
                  </Button> */}
                  <Button onClick={resendHandler} variant="outlined">
                    Resend Mail
                    {sending && <CircularProgress color="inherit" size={22} />}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box height={500} bgcolor="white">
          <CommonSkeleton lenArray={[...Array(10).keys()]} />
        </Box>
      )}
    </Box>
  );
};

export default OrderDetails;
