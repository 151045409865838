import React from 'react';
import { Rating } from '@mui/material';
import styles from './index.module.scss';
import { buttonType, buttonStyle } from 'src/components/Buttons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectCreative } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

interface inputProps {
  sectionData: Props;
}
export interface Props {
  title?: string;
  decorationImage?: string;
  heroImage?: string;
  slides?: Slide[];
  quoteIcon?: string;
}
export interface Slide {
  id?: string;
  author?: string;
  designation?: string;
  text?: string;
  stars?: number;
  authorImage?: string;
}

const Testimonial: React.FC<inputProps> = ({ sectionData }) => {
  // const Button = buttonStyle(buttonType.primary);
  return (
    <div className={styles.main}>
      <div className="container" style={{ maxWidth: 'calc(1191px + 30px)' }}>
        <h2 className={styles.sectionHeading}>{sectionData.title}</h2>
      </div>
      <div className={styles.bg}>
        <div className={`${styles.contentContainer} container`}>
          <img className={styles.decor} src={sectionData.decorationImage} alt="" aria-hidden loading="lazy" />
          <div className={styles.heroImage} style={{ position: 'relative' }}>
            <img src={sectionData.heroImage} alt="" aria-hidden loading="lazy" />
          </div>
          <div className={styles.testimoniContainer}>
            <div className={styles.sliderContainer}>
              <Swiper
                // spaceBetween={50}
                // slidesPerView={1}
                modules={[Pagination, Autoplay, EffectCreative]}
                pagination={{ clickable: true }}
                grabCursor={true}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false
                }}
                className={styles.swiper}
                effect={'creative'}
                creativeEffect={{
                  prev: {
                    shadow: false,
                    translate: [0, 0, -500]
                  },
                  next: {
                    translate: ['100%', 0, 0]
                  }
                }}
              >
                {sectionData.slides?.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className={styles.singleSlide}>
                      <div className={styles.quoteIcon}>
                        <img src={sectionData.quoteIcon} alt="" aria-hidden loading="lazy" />
                      </div>
                      <div className={styles.author}>
                        <img src={item.authorImage} alt="" loading="lazy" />
                      </div>
                      <div className={styles.textContainer}>
                        <h6 className={styles.authorName}>{item.author}</h6>
                        <p className={styles.authorDesignation}>{item.designation}</p>
                        <div className={styles.stars}>
                          <Rating name="read-only" value={item.stars} precision={0.5} readOnly size="small" />
                        </div>
                        <p className={styles.description}>{item.text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <Button className={styles.button}>Ask Someone</Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
