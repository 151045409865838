import { useCourses } from 'src/components/hooks';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/helpers/routes';
import { Skeleton } from '@mui/material';
import styles from './index.module.scss';
import { siteNames, siteName } from 'src/config';
import { Link } from 'react-router-dom';
import noImage from 'src/assets/images/no-image.png';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Typography } from '@mui/material';

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const CoursesDetails = ({ mobileDisplay = null }: { mobileDisplay?: boolean | null }) => {
  const navigate = useNavigate();

  const handleClick = (id: any) => {
    navigate(`${routes.classDetails.path}/${id}`);
  };

  const courseDetails = useCourses();

  return (
    <div className={styles.main}>
      <div className="container">
        <div className={styles.sectionHeading}>
          <h2>Start your journey with us today.</h2>
          <div className={`${styles.underLine} ${siteName?.includes(siteNames.site1) ? styles.primary : styles.secondary}`}></div>
        </div>
        <div className={styles.sections}>
          {courseDetails ? (
            <>
              {courseDetails?.map((course, index) => {
                let classDays: any = [];

                let fee = 0;
                const feeList: number[] = [];

                course?.schedule?.forEach((item) => {
                  feeList.push(item.rate);
                  const dayFound = weekDays.filter((days) => !item.blockDayNames.includes(days));
                  classDays.push(...dayFound);
                });
                classDays = [...new Set(classDays)];
                fee = Math.min(...feeList);

                const sortedDate = classDays
                  .sort(function (a: string, b: string) {
                    return weekDays.indexOf(a) > weekDays.indexOf(b);
                  })
                  .map((day: string) => `${day}s`);

                return (
                  <div className={styles.singleCard} key={course._id}>
                    <div className={styles.imageContainer} onClick={() => handleClick(course._id)}>
                      {course.images[0] ? (
                        <>
                          <div className="loader2"></div>
                          <img
                            src={course.images[0]}
                            alt={course.heading}
                            loading="lazy"
                            className={styles.courseImage}
                            onLoad={(e) => {
                              e.currentTarget.previousElementSibling.remove();
                            }}
                          />
                        </>
                      ) : (
                        <div className={styles.placeholder}>
                          <img src={noImage} alt="" aria-hidden />
                        </div>
                      )}
                    </div>
                    <div className={styles.textContainer}>
                      <h6 className={styles.courseHead} onClick={() => handleClick(course._id)}>
                        <span className={styles.courseIcon}>{<img src={course.icon} alt="" style={{ maxWidth: '25px' }} />}</span>
                        <span className={styles.courseName}>{course.heading}</span>
                      </h6>
                      <Typography className={styles.courseDetails}>{course.text}</Typography>
                      <Link className={styles.learnmore} to={`${routes.classDetails.path}/${course._id}`}>
                        LEARN MORE
                        <AiOutlineArrowRight />
                      </Link>
                      <Typography className={styles.fee}>
                        <AttachMoneyIcon sx={{ fontSize: '17px' }} />
                        <span>Starts at ${fee}/semester</span>
                      </Typography>
                      {sortedDate.length > 0 && (
                        <Typography className={styles.courseDays}>
                          <span>
                            <CalendarMonthIcon sx={{ fontSize: '17px' }} />
                          </span>
                          <span>{sortedDate.join(', ')}</span>
                        </Typography>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <ReanderCoursesSkeleton mobileDisplay={mobileDisplay} count={6} />
          )}
        </div>
      </div>
    </div>
  );
};

const ReanderCoursesSkeleton = ({ mobileDisplay, count }: { mobileDisplay?: boolean; count: number }) => {
  let list = [];
  for (let i = 0; i < count; i++) {
    list.push(i);
  }

  return (
    <>
      {list.map((item) => {
        return (
          <div key={item} className={styles.singleCard} style={{ maxHeight: '240px' }}>
            <div className={styles.imageContainer}>
              <Skeleton height="418px" style={{ position: 'relative', top: '-50%' }} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.rowHead}>
                <h5>
                  <Skeleton width={'100px'} />
                </h5>
                <div className={styles.calander}>
                  <p>
                    <Skeleton width="110px" />
                  </p>
                </div>
              </div>
              <p>
                <Skeleton />
                <Skeleton />
              </p>
              <Skeleton width="190px" height={'65px'} />
            </div>
          </div>
        );
      })}
    </>
  );
};
