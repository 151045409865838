import React from "react";
import {
  SecondaryButton,
  PrimaryButton,
  SocialButton,
  WhiteButton,
} from "../components/Buttons";
import { FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";

const Typography = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ textAlign: "center" }}>Typography</h2>
      <hr />
      <p>
        <small>This is a p small</small>
      </p>
      <p>This is a p</p>
      <p>
        <big>This is a p big</big>
      </p>
      <h6>
        <small>This is a h6 small</small>
      </h6>
      <h6>This is a h6</h6>
      <h6>
        <big>This is a h6 big</big>
      </h6>
      <h5>This is h5</h5>
      <h4>This is h4</h4>
      <h3>This is h3</h3>
      <h2>This is h2</h2>
      <h2>
        <big> This is h2 Big</big>
      </h2>
      <h1>This is h1</h1>
      <div className="primary-text">
        <h3>Our Parent Organization</h3>
        <h1>Mai Bhago Charity</h1>
      </div>
      <hr />
      <h2 style={{ textAlign: "center" }}>Buttons</h2>
      <hr />
      <div>
        <PrimaryButton />
      </div>
      <div>
        <SecondaryButton />
      </div>
      <div style={{ background: "var(--secondary)", padding: "15px" }}>
        <WhiteButton endIcon={<FaLinkedin />} />
      </div>
      <div>
        <SocialButton style={{ marginRight: "5px" }} icon={<FaFacebookF />} />
        <SocialButton style={{ marginRight: "5px" }} icon={<FaTwitter />} />
        <SocialButton icon={<FaLinkedin />} />
      </div>
      <hr />
      <h2 style={{ textAlign: "center" }}>List</h2>
      <hr />
      <div style={{ maxWidth: "540px", margin: "0 auto" }}>
        <ul style={{ textAlign: "left" }}>
          <li>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci
            rhoncus duis at mi, ipsum, vulputate rhoncus. Non ac vivamus
            pharetra lectus.
          </li>
          <li>
            Et quis tristique felis massa purus sit rhoncus rhoncus. Sagittis,
            turpis varius elementum, aenean nunc, tincidunt lacus.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Typography;
