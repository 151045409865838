export const currencyConverter = (price: string | number, currency: string) => {
  if (price === null || price === undefined) {
    return 0;
  }
  return price.toLocaleString(undefined, {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0
  });
};

export const currencySymbol = (currency: string) => {
  currency = currency || 'USD';

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency
  });

  let symbol;
  formatter.formatToParts(0).forEach(({ type, value }) => {
    if (type === 'currency') {
      symbol = value;
    }
  });

  return symbol;
};
